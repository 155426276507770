import { useEffect, useState } from "react";
import { CardContent } from "components/shadcn/ui/card";
import { Input } from "components/shadcn/ui/input";
import { Label } from "components/shadcn/ui/label";
import lang from "lang";
import { domainRegex } from "components/campaign/validationSchema";
import { formatDomain } from "helpers";
import { HelpCircle } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/shadcn/ui/tooltip";

interface SetUpMailboxProps {
    setIsFormValid: (isValid: boolean) => void;
    onDataSubmit: (data: any) => void;
}

const SetUpMailbox: React.FC<SetUpMailboxProps> = ({ setIsFormValid, onDataSubmit }) => {
    const userData = JSON.parse(localStorage.getItem("user")!);
    const { campaign: campaignCopy } = lang;
    const [domain, setDomain] = useState<string>('');
    const [domainError, setDomainError] = useState<string | null>(null);

    const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        setDomain(value);

        if (value === "" || !domainRegex.test(value)) {
            setDomainError(campaignCopy.domainError);
            setIsFormValid(false);
        } else {
            setDomainError(null);
            setIsFormValid(true);
            handleBlur(value);
        }
    };

    useEffect(() => {
        if (!domain.trim()) {
            setIsFormValid(false);
        }
    }, [domain, setIsFormValid]);

    const handleWebsiteBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const formattedValue = formatDomain(e.target.value);
        setDomain(formattedValue);
    };

    const handleBlur = (value: string) => {
        const formattedData = {
            replyTo: userData?.email,
            preferredDomain: value,
        };
        onDataSubmit(formattedData);
    };

    return (
        <div className="w-full h-full min-h-[310px]">
            <CardContent className="flex flex-col gap-6">
                <div className="w-[100%]">
                    <div className="flex justify-between items-start flex-row gap-6">
                        <div className="text-left flex flex-col gap-2 w-[100%]">
                            <Label htmlFor="domain">{campaignCopy.labelPreferredDomain}</Label>
                            <div className="flex flex-row gap-6">
                                <Input
                                    id="domain"
                                    placeholder="Type your domain"
                                    value={domain}
                                    onChange={handleDomainChange}
                                    onBlur={handleWebsiteBlur}
                                    autoComplete="off"
                                />
                                {domainError && (
                                    <div className="text-sm text-red-500 mt-2">{domainError}</div>
                                )}
                                <div className="w-[280px] h-[100px] p-[24px] gap-2 rounded-t-[16px] bg-[#EAECF0] flex flex-col justify-center font-inter text-sm font-medium text-[14px] leading-[20px] text-left  text-gray-700">
                                    {campaignCopy.websiteDomain}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex items-center gap-2 mt-[-45px] mb-[50px]">
                        <Checkbox id="save-domains" />
                        <Label htmlFor="save-domains" className="text-sm font-medium text-gray-700">
                            {campaignCopy.doYouOwnTheDomain}
                        </Label>
                    </div> */}
                </div>
                <TooltipProvider>
                    <div className="flex items-left gap-2 flex-col">
                        <div className="flex flex-row gap-2">
                            <Label className="text-sm text-left">{campaignCopy.repliesToEmail}</Label>
                            <Tooltip >
                                <TooltipTrigger asChild>
                                    <HelpCircle size={16} className="text-gray-400 cursor-pointer" />
                                </TooltipTrigger>
                                <TooltipContent side="top">
                                    {campaignCopy.repliesToEmailSubtextTooltip}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                        <Input disabled className="text-sm" value={userData?.email} />
                    </div>
                </TooltipProvider>

            </CardContent>
        </div>
    );
};

export default SetUpMailbox;
