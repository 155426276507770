
import { SVGProps } from 'react';
const SvgDomainIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2.85938C14.5013 5.59773 15.9228 9.15141 16 12.8594C15.9228 16.5673 14.5013 20.121 12 22.8594M12 2.85938C9.49872 5.59773 8.07725 9.15141 8 12.8594C8.07725 16.5673 9.49872 20.121 12 22.8594M12 2.85938C6.47715 2.85938 2 7.33653 2 12.8594C2 18.3822 6.47715 22.8594 12 22.8594M12 2.85938C17.5228 2.85938 22 7.33653 22 12.8594C22 18.3822 17.5228 22.8594 12 22.8594M2.50002 9.85938H21.5M2.5 15.8594H21.5" stroke="#F63D68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

);
export default SvgDomainIcon;

