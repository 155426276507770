const campaign = {
  campaigns: "Campaigns",
  noCampaignsHeading: "No campaigns created yet",
  campaignOptionText: "Start your campaign by choosing campaign type:",
  campaignOptionType: [
    {
      id: 1,
      label: "E-mail campaign ",
      disabled: false,
      subText: "Most Likely to Receive a Reply",
    },
    {
      id: 2,
      label: "Coming Soon : Letter campaign",
      disabled: true,
      subText: "Moderate Chance of a Reply",
    },
    {
      id: 3,
      label: "Coming Soon : Email & Letter campaign",
      disabled: true,
      subText: "Least Likely to Receive a Reply",
    },
  ],
  newCampaign: "New Campaign",
  noCollectionsYet: "No Collections yet",
  noCollectionsSubText:
    "To run a campaign you need to save companies to a collection. ",
  goToSearch: "Go to Search",
  startCampaign: "Start Campaign",
  next: "Next",
  back: "Back",
  createCampaignSubtext: "Create new campaign template",
  edit: "Edit",
  reviewAndRefine: "Review & Refine",
  selectCollection: "Select Collections for Campaign",
  labelForCompanyNAme:
    "What's the name of your Hold Co, Fund or Acquisition Vehicle?",
  phoneNumber: "Phone Number",
  website: "Your Current Main Website",
  headsUp: "Heads up!",
  mailboxAlertDescription:
    "To prevent your mailbox from being flagged as spam, we limit sending to 30 emails per day per email address.",
  mailboxes: "Mailboxes",
  labelPreferredDomain: "What's your preferred domain identity?",
  websiteDomain:
    "Enter your website domain or preferred domain identity e.g. www.mywebsite.com",
  burnerDomain:
    "We have detected the following burner domains for your outreach:",
  outreachMaiboxesHeading:
    "We have detected the following mailboxes for your outreach:",
  mailboxBurnerDomainHeading:
    "New mailboxes with burner domains help isolate outreach campaigns and manage spam risks effectively",
  repliesToEmail: "Forward email",
  repliesToEmailSubtextTooltip:
    "Replies from your campaigns will be forwarded to email address in your BizCrunch profile.",
  campaignSummary: "Campaign summary",
  campaignSummarySubtext: "Flexible pricing that grows with you.",
  aboutCredits: "About credits",
  warmingUp: "Warming Up",
  warmingUpSubtext:
    "It will take few days to warmup your campaign inbox. Campaign will be sent automatically out as soon as it's ready.",
  emailsToSend: "Emails to send",
  creditsBalance: "Credits balance",
  editNameHeader: "Name your campaign",
  cancel: "Cancel",
  save: "Save",
  doYouOwnTheDomain: "Do you own this domain?",
  domainError: "Please enter a valid domain (e.g. bizcrunch.com)",
  discardModalHeader: "Are you sure you want to leave?",
  discradModalSubtext: "Campaign progress will be saved as draft",
  reviewEmail: "Review Email",
  copy: "Copy",
  close: "Close",
  parameters: "Parameters",
  variables: [
    "{{firstName}} ",
    "{{lastName}} ",
    "{{companyName}} ",
    "{{sender.firstName}} ",
    "{{sender.lastName}} ",
    "{{sender.company}} ",
    "{{sender.email}} ",
    "{{sender.website}} ",
    "{{sender.phoneNumber}} ",
  ],
  errorMsgCampaignNameExists:
    "Campaign with this name already exists. Please try another name.",
  trialUserBannerText:
    "Unlock the full potential of your campaigns! Your trial account lets you explore and create, but with limited quota. Click 'End Trial' to activate your subscription, boost your quota, and take your campaigns to the next level!",
  upgrade: "Upgrade",
  upgradeAccount: "Upgrade Account",
  campaignSuccessBannerHeader: "We have sucessfully created campaign",
  campaignSuccessBannerSubtext:
    "You can check the created campaign in the campaign list.",
  campaignErrorBannerHeader:
    "We have encountered an error while creating Campaign",
  campaignErrorBannerSubtext:
    "But don't worry, your campaign have been saved as draft.",
  notifyViaEmail: "We will notify via email on the status change",
  toPreventSpam: "To prevent your mailbox from being flagged as spam",
  companiesSelected: "Companies selected",
  totalNoOfRecipents: "Total no. recipients",
  quotaUsage: "Quota usage",
  emailsPerWorkflow: "Emails per workflow",
  recipients: "Recipients",
  totalEmailSent: "Total E-mails Sent",
  warmingUpCampaign: "Warming Up!",
  warmingUpSubText:
    "It will take few days to warmup your campaign inbox. We will notify you via email of progress",
  endTrial: "End Trial",
  noOfRecipients: "No. of recipients",
  recipientQuota: "Recipient quota",
};

const admin = {
  tabOptions: [
    { value: "profile", label: "User Profile" },
    { value: "domains", label: "Burner Domains" },
  ],
  burnerDomains: "Burner Domains",
  profile: "profile",
  dkim: "DKIM",
  cancel: "Cancel",
  save: "Save",
  enterKey: "Enter Key :",
  enterValue: "Enter value :",
  gotoWorkSpaceText:
    "Go to your google worskpace and copy the key and  the value",
  gotoWorkspace: "Go to Google Workspace",
};

const collection = {
  collectionName: "Collection Name",
  enterNameofCollection: "Enter new collection name",
  save: "Save",
  cancel: "Cancel",
  downloadCollection: "Download Collection",
  newCampaign: "New Campaign",
};

const dealTracker = {
  dealTracker: "Deal Tracker",
  dealTrackerSubtext: "Track your deals here",
};

const payment = {
  pricingPlans: "Pricing plans",
  pricingPlanSubtext1: "Befrore you continue please select your plan.",
  pricingPlansSubtext2:
    "All plans are 12-month. Either billed Annually in advance, or billed in 12 Monthly Instalments",
  startTrial: "Start 14 day trial",
  trialHelpIconTooltip:
    "During your trial period you will be only allowed to save 25 companies to collection",
  tabOptions: [
    { value: "monthly", label: "Monthly billing" },
    { value: "yearly", label: "Annual billing" },
  ],
  monthly: "monthly",
  annualy: "annualy",
  subscribe: "Subscribe",
  services: "SERVICES",
  yourCurrentPlan: "Your current plan",
  upgrade: "Upgrade",
  trialEndsIn: "Trial ends in ",
  days: " days",
  description: "Our most popular plan for small teams.",
  managePlan: "Manage Plan",
  increaseQuotaHeading: "Increase your quota with top-up:",
  monthlyBilling: "Monthly billing",
  annualBilling: "Annual billing (save 20%)",
  currentPlan: "Current Plan",
  endTrialNow: "End Trial Now",
  cancelPlan: "Cancel Plan",
  singleTopUp: "Single Top up",
  topUp: "Top up",
  summary: "Summary",
  errorForUpgrading:
    " We have encountered an error while upgrading your plan. Please try again later.",
  errorForEndingTrial:
    "We have encountered an error while ending your trial. Please try again later.",
  errorForCancellingPlan:
    "We have encountered an error while cancelling your plan. Please try again later.",
  errorTryAgainLater: "Please try again later.",
  exceedMonthlyQuota: "Your selection exceeds your monthly quota limit",
  increaseEmailQuota: "Increase Email Quota",
  moveRecipients: "Move Recipients",
  moveRecipientSubtext:
    "You can move remaining recipients to a new collection, ready for your next campaign.",
  welcome: "Welcome!",
};

export const lang = {
  campaign,
  admin,
  collection,
  dealTracker,
  payment,
};

export default lang;
