import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "components/shadcn/ui/dialog";
import { Button } from "components/shadcn/ui/button";
import SvgCloseIcon from "components/popUps/CloseIcon";
import lang from "lang";

interface ExceedEmailQuotaModalProps {
    open: boolean;
    onClose?: () => void;
    totalEmailQuota?: number;
    selectedRecipientsCount?: number;
    emailOutreachQuota?: number;
    showUpgradeQuota?: () => void;
}

const ExceedEmailQuotaModal: React.FC<ExceedEmailQuotaModalProps> = ({
    open,
    totalEmailQuota,
    selectedRecipientsCount,
    emailOutreachQuota,
    onClose,
    showUpgradeQuota,
}) => {
    const { payment: paymentCopy } = lang;

    return (
        <Dialog open={open} onOpenChange={onClose}>

            <DialogContent className="max-w-lg p-6 rounded-xl bg-gray-50">
                <button className="w-full flex justify-end" onClick={onClose}>
                    <SvgCloseIcon />
                </button>
                <DialogHeader>
                    <DialogTitle className="text-center text-lg font-semibold">
                        {paymentCopy.exceedMonthlyQuota}
                    </DialogTitle>
                </DialogHeader>

                <div className="border rounded-[24px] p-4 space-y-2 flex flex-row">
                    <div className="flex flex-col align-middle gap-3">
                        <h3 className="font-semibold text-lg text-gray-600 mt-0">{paymentCopy.increaseEmailQuota}</h3>
                        <p className="text-sm text-gray-600">
                            Your current plan only allows for {totalEmailQuota} emails per month.
                            Upgrade the plan or use a one-time top-up.
                        </p>

                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-xl font-bold text-pink-600">
                            {selectedRecipientsCount}/{emailOutreachQuota}
                        </span>
                    </div>
                    <Button variant="primary" className="text-white" onClick={showUpgradeQuota}>
                        {paymentCopy.upgrade}
                    </Button>
                </div>

                <div className="border rounded-[24px] p-4 space-y-2 flex flex-row">
                    <div className="flex flex-col align-middle gap-3">
                        <h3 className="font-semibold text-lg text-gray-600 mt-0">{paymentCopy.moveRecipients}</h3>
                        <p className="text-sm text-gray-600">
                            {paymentCopy.moveRecipientSubtext}
                        </p>
                    </div>
                    <Button variant="primary" className="text-white">
                        {paymentCopy.moveRecipients}
                    </Button>
                </div>
            </DialogContent>
        </Dialog >
    );
};

export default ExceedEmailQuotaModal;
