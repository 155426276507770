import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "components/lib/utils";
import { ParentContext } from "components/constants/ParentContext";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-[32px] text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        primary: "px-[16px] py-[10px] gap-[8px] border-[1px] opacity-100 rounded-[32px]",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "px-4 py-2 gap-2 border border-gray-200  bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.04)] font-inter text-sm font-semibold  text-[#344054]  !rounded-[32px]",
        secondary: "h-[40px] p-[10px] px-[16px] gap-[8px] border border-[#FECCD6] bg-[#FFF1F3] shadow-[0px_1px_2px_0px_#1018280D] !rounded-[32px]",
        ghost: "bg-[#FFF1F3]",
        link: "text-primary underline-offset-4 hover:underline",
        custom: "",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  usePrimary700?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, usePrimary700 = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const context = React.useContext<any>(ParentContext);
    const buttonClass = React.useMemo(() => {
      let baseClass = buttonVariants({ variant, size, className });

      if (variant === 'primary') {
        baseClass = cn(
          baseClass,
          context.isHarbour
            ? 'bg-gradient-to-r from-[#3DAEDF] to-[#182059] border-none' // Custom gradient for Harbour
            : 'bg-gradient-to-r from-[#B8055E] to-[#FF3A64] border-[#E31B53]' // Default primary gradient
        );
      }

      return baseClass;
    }, [variant, size, className, context]);

    return <Comp className={buttonClass} ref={ref} {...props} />;
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };

