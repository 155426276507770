export const getFinancialStr = (
  number: any,
  estimated?: any,
  hideTilde?: any
) => {
  var str = "";

  if (number !== null && number !== undefined && number !== "-") {
    if ((number + "").includes(" - ")) {
      let split = (number + "").split(" - ");
      str +=
        getFinancialStr(split[0], false, true) +
        " - " +
        getFinancialStr(split[1], false, true);
    } else if (number >= 1000000 || number <= -1000000) {
      str += "£";
      var millions: any = (number / 1000000).toFixed(2);
      if (number % 1000000 === 0) {
        millions = number / 1000000;
      } else if (number % 100000 === 0 || estimated) {
        millions = (number / 1000000).toFixed(1);
      }
      str += millions + "M";
    } else if (number >= 1000 || number <= -1000) {
      str += "£";
      var thousands: any = number / 1000;
      if (thousands < 10 && number % 1000 !== 0) {
        thousands = thousands.toFixed(1);
      } else {
        thousands = Math.floor(thousands);
      }
      str += thousands + "K";
    } else {
      str += "£";
      str += number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (estimated && !hideTilde) {
      str = "~" + str;
    }

    return str;
  } else {
    return "-";
  }
};

export const getFinancialStrInThousand = (number: any) => {
  if (number !== null && number !== undefined && number !== "-") {
    if ((number + "").includes(" - ")) {
      let split = (number + "").split(" - ");
      return getFinancialStr(split[0]) + " - " + getFinancialStr(split[1]);
    } else if (number >= 1000 || number <= -1000) {
      let thousands = number / 1000;
      if (thousands < 10 && number % 1000 !== 0) {
        return thousands.toFixed(1);
      } else {
        return Math.floor(thousands).toString();
      }
    } else {
      return number.toString();
    }
  } else {
    return "-";
  }
};

export const formatPercentage = (percentage: any) => {
  var newPercentage: any = +percentage;
  if (Math.abs(newPercentage) >= 1) {
    newPercentage = newPercentage.toFixed(1);
  } else {
    newPercentage = newPercentage.toFixed(2);
  }
  return newPercentage;
};

export const formatDate = (dateString: string): string => {
  if (!dateString) return "";
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const generateCampaignName = (existingNames: string[]) => {
  const baseName = `Campaign - ${new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })}`;
  let newName = baseName;
  let counter = 2;

  while (existingNames.includes(newName)) {
    newName = `${baseName} (${counter})`;
    counter++;
  }

  return newName;
};

export const formatPhoneNumber = (value: string) => {
  let cleaned = value.replace(/[^\d+]/g, "");

  if (!cleaned.startsWith("+")) {
    cleaned = `+44${cleaned}`; // Default to UK +44
  }

  const match = cleaned.match(/^(\+\d{1,3})(\d{4})(\d{3})(\d{3})$/);
  if (match) {
    return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  }

  return cleaned;
};

export const formatWebsite = (website: String) => {
  let value = website.trim();
  if (value && !/^www\./.test(value)) {
    value = "www." + value.replace(/^(https?:\/\/)?(www\.)?/, "");
  }
  return value;
};

export const formatDomain = (value: string): string => {
  let trimmedValue = value.trim().toLowerCase();

  trimmedValue = trimmedValue.replace(/^(https?:\/\/)/, "");

  trimmedValue = trimmedValue.replace(/^www\./, "");

  return trimmedValue;
};

export const formatService = (name: string, quota: number | null) => {
  if (quota === null) return name;

  const mapping: { [key: string]: string } = {
    "Number of Inboxes": "inbox",
    "Number of Domains": "domain",
  };

  if (mapping[name]) {
    const suffix = name === "Number of Inboxes" ? "es" : "s";
    return `${quota} ${mapping[name]}${quota > 1 ? suffix : ""}`;
  }

  return `${quota} ${name.toLowerCase()}`;
};

export const calculateDaysRemaining = (endDate: string) => {
  const end = new Date(endDate);
  const now = new Date();
  const timeDiff = end.getTime() - now.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysDiff;
};

export const getCardWidthClass = (totalPlans: number) => {
  let widthClass = "w-full ";

  if (totalPlans === 1) {
    widthClass += "sm:w-4/5 md:w-2/3 lg:w-1/2";
  } else if (totalPlans === 2) {
    widthClass += "sm:w-[calc(50%-0.5rem)] md:w-[calc(50%-0.75rem)]";
  } else if (totalPlans === 3) {
    widthClass += "sm:w-[calc(50%-0.5rem)] lg:w-[calc(33.333%-0.67rem)]";
  } else if (totalPlans === 4) {
    widthClass += "sm:w-[calc(50%-0.5rem)] lg:w-[calc(25%-0.75rem)]";
  } else {
    widthClass +=
      "sm:w-[calc(50%-0.5rem)] lg:w-[calc(33.333%-0.67rem)] xl:w-[calc(25%-0.75rem)]";
  }

  return widthClass;
};
