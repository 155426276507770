import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface collection {
  totalCompanies: number;
  description: string;
  exportedOn: string;
  name: string;
  id: string;
  totalRecipients: number;
  createdAt: string;
  isAddedToCampaign: boolean;
}

interface CollectionState {
  collections: collection[];
  loading: boolean;
  error: string | null;
  alreadyCollectedComapnies: string[];
}

const initialState: CollectionState = {
  collections: [],
  loading: false,
  error: null,
  alreadyCollectedComapnies: [],
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setCollections: (state, action: PayloadAction<collection[]>) => {
      state.collections = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setAlreadyCollectedComapnies: (state, action: PayloadAction<string[]>) => {
      state.alreadyCollectedComapnies = action.payload;
    },
  },
});

export const selectCollections = (state: { collection: CollectionState }) =>
  state.collection.collections;

export const selectAlreadyCollectedCompanies = (state: {
  collection: CollectionState;
}) => state.collection.alreadyCollectedComapnies;

export const {
  setCollections,
  setLoading,
  setError,
  setAlreadyCollectedComapnies,
} = collectionSlice.actions;

export default collectionSlice.reducer;
