import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/shadcn/ui/dialog";
import { Button } from "components/shadcn/ui/button";
import lang from "lang";
import SvgCloseIcon from "./CloseIcon";
import { toast, ToastContainer } from "react-toastify";

const VariableSelectionDialog = ({ showModal, setShowModal, onClose }: { showModal: boolean; setShowModal: (val: boolean) => void; onClose: () => void }) => {
    const { campaign: campaignCopy } = lang;

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success(`${text} copied to clipboard. You can now paste it into editor`);
            })
            .catch(() => {
                toast.error("Failed to copy to clipboard");
            });
    };

    return (
        <div>
            <Dialog open={showModal} onOpenChange={setShowModal}>

                <DialogContent className="w-full max-w-md h-[70vh] max-h-screen !bg-[#F2F4F7] flex flex-col">
                    <ToastContainer style={{ zIndex: 99999 }} position="top-left" />
                    <button className="flex justify-end" onClick={onClose}><SvgCloseIcon /></button>
                    <DialogHeader>
                        <DialogTitle className="text-center text-lg font-semibold">
                            Copy and add the parameter
                        </DialogTitle>
                    </DialogHeader>

                    <div className="flex-1 overflow-auto !bg-white !rounded-[32px] max-h-[60vh]">
                        {campaignCopy.variables.map((variable) => (
                            <div
                                key={variable}
                                className="flex justify-between items-center px-4 py-3 border-b border-[#EAECF0] w-full"
                            >
                                <span className="text-primary-700 font-semibold">{variable}</span>
                                <Button size="sm" variant="outline" onClick={() => handleCopy(variable)}>
                                    {campaignCopy.copy}
                                </Button>
                            </div>
                        ))}
                    </div>

                    <div className="flex justify-center mt-4">
                        <Button variant="outline" size="sm" onClick={() => setShowModal(false)}>
                            {campaignCopy.close}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default VariableSelectionDialog;
