import { selectAlreadyCollectedCompanies } from 'components/collection/collectionSlice';
import '../../styling/saved.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface Business {
  premium: boolean;
  emailCount: number;
  validEmailCount: number;
  number: string;
}

interface CreditBreakdownPopUpProps {
  selectedBusinesses: Business[];
}

const CreditBreakdownPopUp: React.FC<CreditBreakdownPopUpProps> = ({ selectedBusinesses }) => {

  const [standards, setStandards] = useState<number>(0);
  const [premiums, setPremiums] = useState<number>(0);
  const [emails, setEmails] = useState<number>(0);
  const [overallCredits, setOverallCredits] = useState<number>(0);
  const alreadyCollectedCompanies = useSelector(selectAlreadyCollectedCompanies);

  useEffect(() => {
    let standards = 0;
    let premiums = 0;
    let credits = 0;
    let emails = 0;

    selectedBusinesses?.forEach((element) => {
      const isAlreadyCollected = alreadyCollectedCompanies.includes(element.number);

      if (element.premium) {
        premiums += 1;
        if (!isAlreadyCollected) {
          credits += 10;
        }
      } else {
        standards += 1;
        if (!isAlreadyCollected) {
          credits += 1;
        }
      }
      if (element.validEmailCount > 0) {
        emails++;
        if (!isAlreadyCollected) {
          credits += 3;
        }
      }
    });

    setStandards(standards);
    setPremiums(premiums);
    setEmails(emails);
    setOverallCredits(credits);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinesses, alreadyCollectedCompanies]);

  return (
    <div className='popUp creditBreakdown text-sm regular gray-600'>
      <div className='width100 row jc-between'>
        <span>{standards} Standard companies</span>
        <span>{standards}</span>
      </div>
      <div className='width100 row jc-between'>
        <span>{premiums} Premium companies</span>
        <span>{premiums * 10}</span>
      </div>
      <div className='width100 row jc-between'>
        <span>{emails} Emails</span>
        <span>{emails * 3}</span>
      </div>

      <div className='greyLine' />

      <div className='width100 row jc-between gray-700 semibold'>
        <span>Credit costs</span>
        <span>{overallCredits}</span>
      </div>
    </div>
  );
}

export default CreditBreakdownPopUp;
