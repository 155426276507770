import {
  selectCampaignId,
  selectedCollection,
} from "components/campaign/campaignSlice";
import { getSummaryForCamapign } from "components/campaign/services";
import { CampaignSummaryDto } from "components/campaign/types";
import { selectCurrentSubscription } from "components/subscription/subscriptionSlice";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useCampaignSummary = () => {
  const campaignId = useSelector(selectCampaignId);
  const selectedCollectionIds = useSelector(selectedCollection);
  const subscriptionDetails = useSelector(selectCurrentSubscription);

  const emailOutreachQuota =
    subscriptionDetails.userFeatureQuotas.find(
      (feature: any) => feature.featureName === "Email Outreach"
    )?.remainingQuota || 0;

  const [summaryResponse, setSummaryResponse] = useState<CampaignSummaryDto>(
    {} as CampaignSummaryDto
  );

  useEffect(() => {
    const fetchCampaignData = async () => {
      const campaignData = await getSummaryForCamapign(campaignId || "");
      setSummaryResponse(campaignData);
    };

    fetchCampaignData();
  }, [campaignId]);

  return {
    summaryResponse,
    selectedCollectionIds,
    emailOutreachQuota,
    subscriptionDetails,
  };
};

export default useCampaignSummary;
