import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';

import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentComponent from '../paymentComponent';
import Lottie from 'lottie-react';
import { lottieAnimationUrl } from 'components/utils/network/endpoints';
import axiosWithToken from 'axiosWithToken';

interface UpdatePaymentPopUpProps {
    close: () => void;
    setCard: (cardData: any) => void;
    cancel: any;
}

const UpdatePaymentPopUp: React.FC<UpdatePaymentPopUpProps> = ({ close, setCard, cancel }) => {

    const taxIDRegion = "eu_vat";

    //const baseURL = process.env.REACT_APP_BASEURL!;
    const baseURL2 = process.env.REACT_APP_BASEURL2!;
    const stripeLoaded: Promise<Stripe | null> = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY?.toString() || '');

    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const [intentID, setIntentID] = useState<string>("");
    const [options, setOptions] = useState<any>(null);
    const [submitCount, setSubmitCount] = useState<number>(0);

    const [taxLoading, setTaxLoading] = useState<boolean>(false);
    const [taxError, setTaxError] = useState<boolean>(false);
    const [taxSuccess, setTaxSuccess] = useState<boolean>(false);
    const [taxIDInput, setTaxIDInput] = useState<string>("");
    const [taxIdGeneralError, setTaxIdGeneralError] = useState<string>("");
    const [animationData, setAnimationData] = useState<any>(null);
    const appearance = {
        // labels: 'floating',
        variables: {}
    };




    const createSetupIntent = async (cusID: any) => {
        try {
            const response = await axiosWithToken.post(`${baseURL2}api/stripe/setup-intent`);

            if (response.data.error) {
                throw new Error(response.data.error);
            }

            const data = response.data;
            const setUpID = data.client_secret;

            setOptions({ clientSecret: setUpID, appearance: appearance });
            setIntentID(setUpID);
        } catch (error) {
            console.error("Error creating setup intent:", error);
        }
    };

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("user")!)
        let cusID = user?.stripeCustomerID
        createSetupIntent(cusID)
    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        const fetchAnimationData = async () => {
            const response = await fetch(lottieAnimationUrl);
            const data = await response.json();
            setAnimationData(data);
        };

        fetchAnimationData();
    }, []);


    useEffect(() => {
        const fetchTaxID = async () => {
            setTaxLoading(false);
            setTaxError(false);
            setTaxSuccess(false);
            setTaxIdGeneralError('');

            try {
                const response = await axiosWithToken.get(`${baseURL2}api/stripe/tax-id`);

                const data = response.data;

                if (data.error && data.message.raw.code === "resource_already_exists") {
                    data.error = false;
                }

                if (data.length) {
                    setTaxIDInput(data[data.length - 1].value);
                    setTaxSuccess(!data.error);
                }
            } catch (error) {
                setTaxIdGeneralError('An internal error occurred');
                console.error('Error searching for tax ID:', error);
            } finally {
                setTaxLoading(false);
            }

        };

        fetchTaxID();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!animationData) {
        return null;
    }

    const stripeResponse = (response: any) => {

        const success = response.success
        if (success) {
            const paymentMethodID = response.response.setupIntent.payment_method
            setPaymentMethod(paymentMethodID)
        }
        else {
            setError(response.error)
        }
    }


    const setPaymentMethod = async (paymentMethodID: any) => {
        setLoading(true);

        try {
            try {
                const taxResponse = await axiosWithToken.post(`${baseURL2}api/stripe/tax-id`, {
                    taxId: taxIDInput,
                    region: taxIDRegion
                });
                console.log(taxResponse);
                if (taxResponse.data.error) {
                    setTaxError(taxResponse.data.error);
                    throw new Error(taxResponse.data.error);
                }
            } catch (taxError) {
                console.error("Error setting tax ID:", taxError);
                setTaxError(true);
                setLoading(false);
                return;
            }

            try {
                const paymentResponse = await axiosWithToken.post(`${baseURL2}api/stripe/payment-method/default`, {
                    paymentMethodId: paymentMethodID
                });
                if (paymentResponse.data.error) {
                    throw new Error(paymentResponse.data.error);
                }

                setCard(paymentResponse.data);
            } catch (paymentError) {
                console.error("Error setting payment method:", paymentError);
                setError("Failed to set payment method. Please try again.");
                setLoading(false);
                return;
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error setting payment method:", error);
        }
    };

    const cancelClicked = () => {
        cancel()
    }


    return (

        <div className='tint'>
            <div className='popUp updatePayment'>
                <div className='sfpuTopRow'>

                    <svg className='sfpuIcon' xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                        <path d="M25.6666 12.1673H2.33325M2.33325 10.0673L2.33325 18.934C2.33325 20.2408 2.33325 20.8942 2.58757 21.3933C2.81127 21.8323 3.16823 22.1893 3.60727 22.413C4.1064 22.6673 4.7598 22.6673 6.06658 22.6673L21.9333 22.6673C23.24 22.6673 23.8934 22.6673 24.3926 22.413C24.8316 22.1893 25.1886 21.8323 25.4123 21.3933C25.6666 20.8942 25.6666 20.2408 25.6666 18.934V10.0673C25.6666 8.76053 25.6666 8.10714 25.4123 7.60801C25.1886 7.16896 24.8316 6.81201 24.3926 6.5883C23.8934 6.33399 23.24 6.33399 21.9333 6.33399L6.06659 6.33398C4.7598 6.33398 4.1064 6.33398 3.60727 6.5883C3.16823 6.81201 2.81127 7.16896 2.58757 7.60801C2.33325 8.10713 2.33325 8.76053 2.33325 10.0673Z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <img className='closeButton pointer' src='/assets/x-close.png' onClick={close} alt='close' />
                </div>

                <div className='sfpuMain'>
                    <div className='sfpuMain inner'>
                        <span className='text-lg semibold gray-900'>Update payment method</span>
                        <span className='text-sm regular gray-600'>Update your card details.</span>
                    </div>

                    <div className='sfpuMain inner'>
                        <span className='text-sm regular gray-700'>Tax ID</span>
                        <div className='row gap12 ai-centre width100'>
                            <input className={`textInput text-md gray-90`} type="text" placeholder={"Enter your tax ID"}
                                value={taxIDInput} onChange={(e) => setTaxIDInput(e.target.value)} />
                        </div>

                        {taxIDInput !== null && taxError && !taxLoading && <span className='text-xs error-500'>Invalid Tax ID</span>}
                        {taxSuccess && !taxLoading && !taxError && <span className='text-xs success-700'>Tax ID fetched</span>}
                        {taxLoading && <span className='text-xs regular-500'>Fetching Tax ID</span>}
                        {taxIdGeneralError && <span className='text-xs error-500'>{taxIdGeneralError}</span>}
                    </div>

                    {(intentID && !loading) &&
                        <Elements stripe={stripeLoaded} options={options} >
                            <PaymentComponent
                                stripeResponse={stripeResponse}
                                submitCount={submitCount}
                                payment={false} />
                        </Elements>
                    }

                    {(!intentID || loading) &&
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            autoplay={true}
                            style={{ transform: 'scale(0.7)' }}
                        />}
                </div>

                <div className='row width100 jc-centre gap20'>
                    <button
                        className='secondaryButton width50'
                        onClick={cancelClicked}
                        disabled={loading}>
                        Cancel
                    </button>
                    <button
                        className='primaryButton width50'
                        onClick={() => setSubmitCount(submitCount + 1)}
                        disabled={loading}>
                        Save</button>
                </div>

                <span className='text-sm regular primary-700 marginTop20'>{error}</span>
            </div>
        </div >
    );
};
export default UpdatePaymentPopUp;
