
import '../../styling/pricing.css'

import React, { useEffect, useState } from 'react';
import Login from '../../screens/Login';

import { signOut } from 'firebase/auth';
import { auth } from '../..';
import ConfirmPlanPopUp from '../Billing/ConfirmPlanPopUp';
import UpdatePaymentPopUp from '../Billing/UpdatePaymentPopUp';
import Lottie from 'lottie-react';
import { bizcrunchAppLiveUrl, lottieAnimationUrl } from 'components/utils/network/endpoints';
import axiosWithToken from 'axiosWithToken';

interface IFramePopUpProps {
    userP: any,
    newPlan: any,
    closeP: any,
    success: any,
}

const IFramePopUp: React.FC<IFramePopUpProps> = ({ userP, newPlan, closeP }) => {

    const baseURL2 = process.env.REACT_APP_BASEURL2;

    const [user, setUser] = useState<any>(userP)
    const [showConfirmPlan, setShowConfirmPlan] = useState(false)
    const [showUpdatePayment, setShowUpdatePayment] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState<any>(null)
    const [paymentSuccessful, setPaymentSuccessful] = useState(false)
    const [animationData, setAnimationData] = useState<any>(null);
    const [subscription, setSubscription] = useState(null)

    const getCustomerData = () => {
        axiosWithToken
            .get(baseURL2 + "api/stripe/customer-data")
            .then((response) => {
                setPaymentMethod(response.data.paymentMethod)
                localStorage.setItem("paymentMethod", JSON.stringify(response.data.paymentMethod))
                setSubscription(response.data.subscription || null)
                setShowConfirmPlan(true)
            });
    }

    useEffect(() => {

        if (userP !== null) {
            setUser(userP)
            //let stripeCustomerID = userP.stripeCustomerID
            getCustomerData()
        }
    }, [userP]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fetchAnimationData = async () => {
            const response = await fetch(lottieAnimationUrl);
            const data = await response.json();
            setAnimationData(data);
        };

        fetchAnimationData();
    }, []);

    if (!animationData) {
        return null;
    }


    const userLoggedIn = (user: any) => {

        setUser(user)

        if (user.stripeCustomerID !== "") {
            //let stripeCustomerID = user.stripeCustomerID
            getCustomerData()
        }
    }

    const close = () => {
        closeP()
    }

    const complete = () => {

        let url = bizcrunchAppLiveUrl;
        window.open(url, '_blank', 'noopener,noreferrer');
        setPaymentSuccessful(true)
    }

    const logout = () => {

        signOut(auth).then(() => {
            // Sign-out successful.
            setUser(null)

        }).catch((error) => {
            // An error happened.
            console.error("ERROR", error.message)
        });


    }

    const confirmEditPayment = () => {
        setShowConfirmPlan(false)
        setShowUpdatePayment(true)
    }
    const closeEditPayment = () => {
        setShowConfirmPlan(true)
        setShowUpdatePayment(false)
    }

    const paymentMethodAdded = (pm: any) => {

        setPaymentMethod(pm)
        setShowConfirmPlan(true)
        setShowUpdatePayment(false)
    }


    return (
        <div className='tint'>
            <div className='column ai-centre gap32 z200 pricingTile iframe padding24'>

                <div className='row width100 jc-between ai-start'>

                    <div className='row ai-centre gap16'>
                        <div className='backGreyCircle' onClick={close}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 18L9 12L15 6" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className='column ai-start gap4'>
                            <span className='gray-900 text-lg semibold'>Top Up Credits</span>
                            <span className='text-sm regular gray-600'>Buy one-time credits without changing your monthly/yearly plan.</span>
                        </div>
                    </div>

                    <svg className='pointer' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={close}>
                        <path d="M18 6L6 18M6 6L18 18" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>


                <div className='row width100 flex1'>

                    {!paymentSuccessful && <div className='column ai-centre width50 gap24 padding24 flex1 '>
                        {(!user) &&
                            <Login iframe={true} setUser={userLoggedIn} />
                        }
                        {(user) &&
                            <div className='column gap24 ai-centre'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.8041 12H29.4298C33.4926 12 36.7861 15.4252 36.7861 19.6505C36.7861 21.6095 36.078 23.3966 34.9136 24.75C36.078 26.1034 36.7861 27.8905 36.7861 29.8495C36.7861 34.0748 33.4926 37.5 29.4298 37.5L13.9003 37.4997V27.2991H18.8041V22.2006H21.2562V22.1991H29.4298C29.4544 22.1991 29.479 22.1992 29.5035 22.1995C30.8237 22.1589 31.8819 21.0332 31.8819 19.6505C31.8819 18.242 30.7841 17.1003 29.4298 17.1003H18.8041V12ZM18.8044 32.3997V27.3009H29.4298C29.4544 27.3009 29.479 27.3008 29.5035 27.3005C30.8237 27.3411 31.8819 28.4668 31.8819 29.8495C31.8819 31.258 30.7841 32.3997 29.4298 32.3997H18.8044Z" fill="black" />
                                    <path d="M13.8998 17.1034H9V12.0031H13.9042V17.0981H18.804V22.1984H13.8998V17.1034Z" fill="black" />
                                </svg>

                                <div className="column ai-start">
                                    <span>You are currently logged in as:</span>
                                    <span className='semibold'>{user.name}</span>

                                </div>
                                <button className="secondaryButton width50" onClick={logout}>Log out</button>
                            </div>
                        }

                    </div>}



                    {!paymentSuccessful &&
                        <div className='column ai-start width50 flex1 lightGrey padding50 gap32 br32'>

                            <span className="grey-700 bold text-xl">Payment</span>

                            {(showConfirmPlan && user) && <ConfirmPlanPopUp
                                iframe={true}
                                plan={newPlan}
                                paymentMethod={paymentMethod}
                                editPayment={confirmEditPayment}
                                currentSubscription={subscription}
                                complete={complete}
                            />}

                            {(!showConfirmPlan && !showUpdatePayment && user) &&
                                <Lottie
                                    animationData={animationData}
                                    loop={true}
                                    autoplay={true}
                                    style={{ transform: 'scale(0.7)' }}
                                />
                            }

                            {(showUpdatePayment && user) && <UpdatePaymentPopUp setCard={(x) => paymentMethodAdded(x)} close={closeEditPayment} cancel={closeEditPayment} />}


                            {!user && <div className="width100 height100 row ai-centre">
                                You must sign in before you can add payment
                            </div>
                            }

                        </div>
                    }

                    {paymentSuccessful &&
                        <div className='column width100 ai-centre marginTop20 gap20 padding50'>
                            <span className='semibold display-sm'>Payment Successful!</span>
                            <span>You have successfully signed up to Bizcrunch. The service should open in a new tab.</span>
                        </div>
                    }
                </div>

            </div>
        </div>
    )

}

export default IFramePopUp;