import { SVGProps } from 'react';
const DiscardModalIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4.75488" width="48" height="48" rx="24" fill="#DCFAE6" />
        <rect x="4" y="4.75488" width="48" height="48" rx="24" stroke="#ECFDF3" strokeWidth="8" />
        <path d="M28 24.7549V28.7549M28 32.7549H28.01M38 28.7549C38 34.2777 33.5228 38.7549 28 38.7549C22.4772 38.7549 18 34.2777 18 28.7549C18 23.232 22.4772 18.7549 28 18.7549C33.5228 18.7549 38 23.232 38 28.7549Z" stroke="#079455" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default DiscardModalIcon;

