
import { SVGProps } from 'react';
const SvgWarningIconWithOutline = (props: SVGProps<SVGSVGElement>) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
            <rect x="5.49984" y="5.50033" width="25" height="25" rx="12.5" stroke="#DC6803" strokeWidth="1.66667" />
        </g>
        <g opacity="0.1">
            <rect x="1.33333" y="1.33333" width="33.3333" height="33.3333" rx="16.6667" stroke="#DC6803" strokeWidth="1.66667" />
        </g>
        <g clipPath="url(#clip0_1299_2346)">
            <path d="M17.9998 14.667V18.0003M17.9998 21.3337H18.0082M26.3332 18.0003C26.3332 22.6027 22.6022 26.3337 17.9998 26.3337C13.3975 26.3337 9.6665 22.6027 9.6665 18.0003C9.6665 13.398 13.3975 9.66699 17.9998 9.66699C22.6022 9.66699 26.3332 13.398 26.3332 18.0003Z" stroke="#DC6803" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1299_2346">
                <rect width="20" height="20" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgWarningIconWithOutline;