/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
} from "@tanstack/react-table";
import '../../styling/saved.css';
import { Table, TableBody, TableCell, TableHeader, TableRow } from 'components/shadcn/ui/table';
import { Button } from 'components/shadcn/ui/button';
import { useLocation, useNavigate } from "react-router-dom";
import SvgChevronSelectorIcon from 'components/common/iconComponents/chevronSelectorIcon';
import SvgBackArrowIcon from 'components/common/iconComponents/backArrowIcon';
import axiosWithToken from 'axiosWithToken';
import { getSICTitle } from 'components/Search/Basic/SearchBar';
import { SIC_CODES } from 'components/Search/Advanced/FilterTypes/siccodes';
import { createExcel } from 'localFunctions/exportSheet';
import { convertResponse } from './convertResponse';
import Loader from 'components/common/loader';
import { columnDataForCollectionDetails } from './columnData';
import { formatPercentage, getFinancialStr } from 'helpers';
import { useDispatch } from 'react-redux';
import { resetCampaignState } from 'components/campaign/campaignSlice';
import { updateCollectionDetails } from 'components/campaign/services';
import { updateCollectionDetailsRequest } from 'components/campaign/types';
import { Mixpanel } from 'components/Mixpanel/Mixpanel';
import lang from 'lang';
import { showCampaignFeature } from 'components/utils/network/endpoints';

interface RowData {
    id: string;
    company: string;
    industry: string;
    revenue: string;
    netProfit: string;
    netProfitPercentage: string;
    debtRatio: string;
    turnover: string;
    turnoverStatus: string;
    netProfitEstimate: string;
}

const CollectionDetails: React.FC = () => {
    const baseURL2 = process.env.REACT_APP_BASEURL2;
    const { collection: collectionCopy } = lang;

    const location = useLocation();
    const { collectionId, collection } = location.state || {};
    const [tableList, setTableList] = useState<any>([]);
    const [companyJSONList, setCompanyJSONList] = useState<any>([]);
    const [PSCJSONList, setPSCJSONList] = useState<any>([]);
    const [contactJSONList, setContactJSONList] = useState<any>([]);
    const [financialJSONList, setFinancialJSONList] = useState<any>([]);
    const [combinedData, setCombinedData] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    useEffect(() => {
        if (collection?.id) {
            const fetchData = async () => {
                try {
                    setIsloading(true);

                    const [companiesResponse, financialsResponse, contactsResponse] = await Promise.all([
                        axiosWithToken.get(`${baseURL2}api/collections/${collectionId}/companies`),
                        axiosWithToken.get(`${baseURL2}api/collections/${collectionId}/financials`),
                        axiosWithToken.get(`${baseURL2}api/collections/${collectionId}/pscs`),
                    ]);

                    const companies = companiesResponse.data;
                    const financials = financialsResponse.data;
                    const contacts = contactsResponse.data;

                    const latestFinancialsMap = financials.reduce((map: any, financial: any) => {
                        const existing = map[financial.companyNumber];
                        if (!existing || new Date(financial.financialYear) > new Date(existing.financialYear)) {
                            map[financial.companyNumber] = financial;
                        }
                        return map;
                    }, {});

                    const mergedDataWithLatestFinancial = companies.map((company: any) => {
                        const financial = latestFinancialsMap[company.companyNumber] || {};
                        const { turnover, turnoverStatus } = formatTurnover(financial);
                        const { netProfitPercentage } = getNetProfitPercentage(financial);
                        return {
                            ...company,
                            ...financial,
                            industry: getSICTitle(company.SIC, SIC_CODES)?.title,
                            turnover,
                            turnoverStatus,
                            netProfitPercentage,
                        };
                    });

                    setCombinedData(mergedDataWithLatestFinancial);

                    const mergedDataWithAllRecords = companies.flatMap((company: any) => {
                        const uniqueFinancialsForCompany = Array.from(
                            new Map(
                                financials
                                    .filter((financial: any) => financial.companyNumber === company.companyNumber)
                                    .map((financial: any) => [`${financial.companyNumber}-${financial.financialYear}`, financial])
                            ).values()
                        );

                        const uniqueContacts = contacts.filter((contact: any) => contact.companyNumber === company.companyNumber);

                        const financialRecords = uniqueFinancialsForCompany.length > 0 ? uniqueFinancialsForCompany : [{ financialYear: "N/A" }];

                        return financialRecords.flatMap((financial: any) => {
                            const formattedFinancial = {
                                ...company,
                                ...financial,
                                industry: getSICTitle(company.SIC, SIC_CODES)?.title,
                                ...formatTurnover(financial),
                            };

                            if (uniqueContacts.length === 0) {
                                return formattedFinancial;
                            }

                            return uniqueContacts.map((contact: any) => ({
                                ...formattedFinancial,
                                ...contact,
                            }));
                        });
                    });

                    const { tableList, companyList, PSCTableList, PSCList, contactList, financialsList } = convertResponse(mergedDataWithAllRecords);

                    setIsloading(false);
                    setTableList(tableList);
                    setCompanyJSONList(companyList);
                    setPSCJSONList(PSCList);
                    setContactJSONList(contactList);
                    setFinancialJSONList(financialsList);

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId, collection]);

    const formatTurnover = (company: any) => {
        let turnOverStatus = "Reported";
        if (company.revenue === null) {
            var tradeDebtors = company.tradeDebtors
            if (!company.tradeDebtors) {
                tradeDebtors = company.currentAssets * .75
                company.tradeDebtors = tradeDebtors
            }

            let lowRange = Math.floor(tradeDebtors * 6 / 100000) * 100000
            let highRange = Math.floor(tradeDebtors * 10 / 100000) * 100000

            company.turnover = lowRange + " - " + highRange
            turnOverStatus = "Estimated"
        }
        else {
            company.turnover = company.revenue;
        }
        return { turnover: company.turnover, turnoverStatus: turnOverStatus };
    }

    const getNetProfitPercentage = (item: any) => {
        if (!item.netProfitPercentage && item.netProfit) {
            item.netProfitPercentage = ((item.netProfit / item.turnover) * 100).toFixed(2);
            if ((item.turnover + "").includes(" - ")) {
                let split = item.turnover.split(" - ");
                let middle = (+split[0] + +split[1]) / 2;
                item.netProfitPercentage = ((item.netProfit / middle) * 100).toFixed(2);
            }
        }

        if (item.netProfitPercentage === null) {
            item.netProfitPercentage = "Not Available";
        }

        if (item.netProfitPercentage && parseFloat(item.netProfitPercentage) > 80) {
            item.netProfitPercentage = null;
            item.netProfit = null;
            item.netProfitEstimate = false;
        }
        return item;
    }

    const createExcelExport = () => {
        Mixpanel.track("FeatureUsage - Collections", {
            "User ID": user?.uid,
            "Date": new Date().toISOString(),
            $email: user?.email,
            $name: user?.displayName,
            "User Type": "Buyer",
            "Action": `Downloaded collection ${collection.name}`
        });
        const requestData: updateCollectionDetailsRequest = {
            name: collection.name,
            description: collection.description,
            exportedOn: new Date().toISOString()
        }
        const fetchedCampaignList = updateCollectionDetails(requestData, collectionId);

        companyJSONList.forEach((element: any) => {
            element.emailCount = null;
            element.validEmailCount = null;
            //element.premium = element.premium ? "Premium" : "Standard";
        });

        contactJSONList.forEach((element: any) => {
            if (element.email === "null") { element.email = ""; }
            if (element.linkedIn === "null") { element.linkedIn = ""; }

            if (user.plan === "free") {
                element.email = "Upgrade to access email";
                element.emailStatus = "N/A";
            }

        });

        PSCJSONList.forEach((element: any) => {
            if (element.email === "null") { element.email = ""; }

            if (user.plan === "free") {
                element.email = "Upgrade to access email";
                element.emailStatus = "N/A";
            }

        });

        createExcel(
            `${collection.name}`,
            companyJSONList,
            contactJSONList,
            PSCJSONList,
            financialJSONList
        );
    };


    const columns: ColumnDef<RowData>[] = useMemo(() => columnDataForCollectionDetails, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data: RowData[] = useMemo(() => combinedData, []);

    const [sorting, setSorting] = useState<SortingState>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const table = useReactTable({
        data: combinedData,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const handleCampaignNavigation = () => {
        dispatch(resetCampaignState());
        navigate(`../campaignLanding`, { replace: true });
    }

    const handleBackNavigation = () => {
        Mixpanel.track("FeatureUsage - Collections", {
            "User ID": user?.uid,
            "Date": new Date().toISOString(),
            $email: user?.email,
            $name: user?.displayName,
            "User Type": "Buyer",
            "Action": 'Back to collections'
        });
        navigate(`../collections`, { replace: true });
    }

    return (
        <div className="fullScreen">
            <div className="container max-w-[80%]">
                < div className="savedFiltersScreen">
                    <div className='text-sm text-gray-600 gap-2 cursor-pointer flex flex-row item-center' onClick={handleBackNavigation}> <SvgBackArrowIcon /> Back</div>
                    <div className='sfTitles flex flex-row items-center justify-between'>
                        <div className=" flex flex-col gap-5">
                            <div className="display-sm semibold text-gray-900 text-left">{collection.name}</div>
                        </div>
                        <div className='flex flex-row gap-3'>
                            <Button variant="outline" onClick={() => createExcelExport()}>
                                <div className="font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                                    {collectionCopy.downloadCollection}
                                </div>
                            </Button>
                            {showCampaignFeature && <Button variant="primary" onClick={handleCampaignNavigation}>
                                <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                                    {collectionCopy.newCampaign}
                                </div>
                            </Button>
                            }
                        </div>
                    </div>
                    <div className="border border-gray-200 rounded-[12px] max-h-[500px] overflow-y-auto w-full">
                        {isLoading && <Loader />}
                        <Table key={combinedData.length} className="border-separate border-spacing-0 w-full">
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <TableCell
                                                key={header.id}
                                                className="cursor-pointer border-b border-gray-200"
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div className='flex flex-row gap-2 items-center text-sm font-medium text-gray-600'>
                                                        {header.column.columnDef.header as string}
                                                        {header.column.getIsSorted()
                                                            ? header.column.getIsSorted() === "asc"
                                                                ? <SvgChevronSelectorIcon />
                                                                : <SvgChevronSelectorIcon />
                                                            : <SvgChevronSelectorIcon />}
                                                    </div>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows.map((row) => (
                                    <TableRow key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            const isCompanyColumn = cell.column.id === "company";
                                            const isConvertRevenueColumn = cell.column.id === "revenue";
                                            const isConvertCurrencyColumn = cell.column.id === "netProfit";
                                            const cellValue = cell.renderValue();

                                            let displayValue = cellValue == null ? "-" : cellValue;

                                            if (cell.column.id === "netProfitPercentage") {
                                                displayValue = (displayValue != null && !isNaN(Number(displayValue))) ? formatPercentage(displayValue) + "%" : "-";
                                            }
                                            if (cell.column.id === "debtRatio") {
                                                displayValue = (displayValue != null && !isNaN(Number(displayValue))) ? `${(Number(displayValue) / 100).toFixed(2)}` : "-";
                                            }

                                            const renderValue = isConvertCurrencyColumn ? getFinancialStr(displayValue, cell.row.original.netProfitEstimate) : isConvertRevenueColumn ? getFinancialStr(cell.row.original.turnover, cell.row.original.turnoverStatus) : displayValue;

                                            return (
                                                <TableCell
                                                    key={cell.id}
                                                    className={`border-b border-gray-200 font-inter text-sm text-left ${isCompanyColumn ? "font-medium leading-5 text-gray-900" : "font-normal leading-5 text-gray-600"}`}
                                                >
                                                    {typeof renderValue === "string" || typeof renderValue === "number" ? renderValue : "-"}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>

                </div>
            </div>
        </div >
    );
};

export default CollectionDetails;