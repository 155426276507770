import axiosWithToken from "axiosWithToken";
import { AddDkimPayload } from "./types";

const baseURL2 = process.env.REACT_APP_BASEURL2;

export const getBurnerDomainList = async () => {
  try {
    const response = await axiosWithToken.get(`${baseURL2}api/admin/domains`);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const addDkimRecord = async (
  userId: string,
  recordId: string,
  payload: AddDkimPayload
) => {
  try {
    const response = await axiosWithToken.post(
      `${baseURL2}api/admin/domains/${userId}/add-dkim-record/${recordId}`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
