import { Card, CardContent } from "components/shadcn/ui/card";
import useCampaignSummary from "./useSummary";
import lang from "lang";
import { HelpCircle } from "lucide-react";
import { Badge } from "components/shadcn/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/shadcn/ui/tooltip";
import { DOMAIN_STATUS } from "components/campaign/types";
import { SUBSCRIPTION_STATUS } from "components/subscription/types";

const CampaignSummary: React.FC = () => {
    const { campaign: campaignCopy } = lang;

    const { emailOutreachQuota, summaryResponse, subscriptionDetails } = useCampaignSummary();


    return (
        <div className="w-[80%] mx-auto p-6 text-left flex flex-col gap-6">
            <div className="flex justify-between items-center mb-4">
                <div className="flex flex-col gap-2">
                    <div className="text-xl font-semibold">{campaignCopy.campaignSummary}</div>
                    <p className="text-sm text-muted-foreground">{campaignCopy.campaignSummarySubtext}</p>
                </div>
            </div>

            <Card className="w-full max-w-sm p-4 shadow-md min-w-[100%]  border border-[#EAECF0]">
                <CardContent className="space-y-4 min-w-[100%]">
                    <div className="flex justify-between items-center h-[40px] border-b pb-2">
                        <span className="text-sm font-regular text-gray-950">{campaignCopy.companiesSelected}</span>
                        <span className="font-semibold text-lg text-gray-700">{summaryResponse.totalCompanies}</span>
                    </div>
                    <div className="flex justify-between items-center h-[40px] border-b pb-2">
                        <span className="text-sm font-regular text-gray-950">{campaignCopy.totalNoOfRecipents}</span>
                        <span className="font-semibold text-lg text-gray-700">{summaryResponse.totalRecipients}</span>
                    </div>
                    <div className="flex justify-between text-red-500 items-center h-[40px] border-b pb-2">
                        <span className="text-sm font-regular text-gray-950">{campaignCopy.quotaUsage}</span>
                        <span className="font-semibold text-lg text-gray-700">{summaryResponse.totalRecipients}/{emailOutreachQuota}</span>
                    </div>
                    <div className="flex justify-between items-center h-[40px] border-b pb-2">
                        <span className="text-sm font-regular text-gray-950">{campaignCopy.emailsPerWorkflow}</span>
                        <span className="font-semibold text-lg text-gray-700">{summaryResponse.emailsPerWorkflow}</span>
                    </div>

                    <TooltipProvider>
                        <div className="flex justify-between items-center h-[40px]">
                            <span className="text-sm font-regular text-gray-950">Sending limit:</span>
                            <span className="font-semibold text-lg text-gray-700 flex items-center gap-1">
                                {subscriptionDetails.status !== SUBSCRIPTION_STATUS.TRAILING ? "30 emails per day" : "25 Emails(Trial limit)"}
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <HelpCircle size={16} className="text-gray-400 hover:cursor-pointer" />
                                    </TooltipTrigger>
                                    <TooltipContent side="top">
                                        {campaignCopy.toPreventSpam}
                                    </TooltipContent>
                                </Tooltip>
                            </span>
                        </div>
                    </TooltipProvider>

                    {subscriptionDetails.status !== SUBSCRIPTION_STATUS.TRAILING &&

                        <div className="flex justify-between  items-center h-[40px] border-b pb-2">
                            <span className="text-sm font-regular text-gray-950">Domain & mailbox setup:</span>
                            {summaryResponse.domainStatus === DOMAIN_STATUS.DOMAIN_READY ? (
                                <Badge variant="outline" className="bg-green-100 text-green-600 border-green-400">Domain ready</Badge>
                            ) : (
                                <Badge variant="outline" className="bg-yellow-100 text-yellow-600 border-yellow-400">In progress</Badge>
                            )}
                        </div>
                    }
                    <TooltipProvider>
                        <div className="flex justify-between items-center h-[40px]">
                            <span className="text-sm font-regular text-gray-950">Campaign start</span>
                            <span className="flex flex-row gap-2 items-center">
                                <Badge variant="outline" className="bg-yellow-100 text-yellow-600 border-yellow-400">Pending</Badge>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <HelpCircle size={16} className="text-gray-400 hover:cursor-pointer" />
                                    </TooltipTrigger>
                                    <TooltipContent side="top">
                                        {campaignCopy.notifyViaEmail}
                                    </TooltipContent>
                                </Tooltip>
                            </span>
                        </div>
                    </TooltipProvider>

                </CardContent>
            </Card>
        </div >
    );
};

export default CampaignSummary;
