import type React from "react"
import { useState } from "react"
import { DndContext, closestCorners, useDroppable, DragOverlay } from "@dnd-kit/core"
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { motion, AnimatePresence } from "framer-motion"
import { Plus, Grip, Star } from "lucide-react"
import { Button } from "components/shadcn/ui/button"
import { Input } from "components/shadcn/ui/input"
import { Card, CardContent } from "components/shadcn/ui/card"
import { Badge } from "components/shadcn/ui/badge"
import { Dialog, DialogContent, DialogTitle } from "components/shadcn/ui/dialog"
import SvgCloseIcon from "components/popUps/CloseIcon"
import { Textarea } from "components/shadcn/ui/textarea"
import { Checkbox } from "components/shadcn/ui/checkbox"
import { DialogFooter } from "components/shadcn/ui/dialog"
import { Label } from "components/shadcn/ui/label"
import { initialColumns } from "./mockData"

interface DealTask {
    id: string
    title: string
    companyName: string
    companyNumber: string
    description: string
    keyContactName: string
    keyContactEmail: string
    isImportant: boolean
    campaignDate: string
    columnId?: string
}

interface TaskProps {
    task: DealTask
    isDragging?: boolean
    onClick: (task: DealTask) => void
}

const Task: React.FC<TaskProps> = ({ task, isDragging, onClick }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: task.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    // Track user interaction
    let startX = 0
    let startY = 0
    let moved = false

    const handlePointerDown = (e: React.PointerEvent) => {
        startX = e.clientX
        startY = e.clientY
        moved = false
    }

    const handlePointerMove = (e: React.PointerEvent) => {
        if (Math.abs(e.clientX - startX) > 5 || Math.abs(e.clientY - startY) > 5) {
            moved = true // User is dragging
        }
    }

    // const handlePointerUp = (e: React.PointerEvent) => {
    //     if (!moved) {
    //         onClick(task) // Only open modal if the user didn't drag
    //     }
    // }

    return (
        <motion.div
            ref={setNodeRef}
            style={style}
            className={`group relative rounded-lg border bg-card p-4 shadow-sm transition-all duration-200 hover:shadow-md 
                ${isDragging ? "z-50 scale-105 shadow-lg" : ""}`}
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerUp={() => !moved && onClick(task)}
            {...attributes}
            {...listeners}
        >
            <div className="flex items-start gap-2">
                <Grip className="h-4 w-4 text-muted-foreground opacity-0 transition-opacity group-hover:opacity-100 mt-1" />
                <div className="flex-1 text-left">
                    <div className="flex items-center justify-between">
                        <p className="text-sm font-medium">{task.title}</p>
                        {task.isImportant && <Star className="h-4 w-4 text-yellow-500 fill-yellow-500" />}
                    </div>
                    <a
                        href="https://www.google.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-start text-primary hover:underline cursor-pointer text-purple-800 hover:text-underlined"
                        onPointerUp={(e) => {
                            e.stopPropagation()
                        }}
                        onPointerDown={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {task.companyName}
                    </a>
                    <p className="text-xs text-muted-foreground mt-1 text-start">{new Date(task.campaignDate).toLocaleDateString()}</p>
                </div>
            </div>
        </motion.div>
    )
}

interface DroppableProps {
    id: string
    children: React.ReactNode
}

const Droppable: React.FC<DroppableProps> = ({ id, children }) => {
    const { setNodeRef, isOver } = useDroppable({ id })

    return (
        <div
            ref={setNodeRef}
            className={`min-h-[200px] rounded-lg p-4 transition-colors ${isOver ? "bg-muted/60" : "bg-transparent"}`}
        >
            {children}
        </div>
    )
}

interface ColumnProps {
    columnId: string
    column: { title: string; tasks: DealTask[] }
    addTask: (columnId: string, task: DealTask) => void
    onTaskClick: (task: DealTask) => void
    setFormData: (data: DealTask) => void
    setDialogOpen: (open: boolean) => void
}

const Column: React.FC<ColumnProps> = ({ columnId, column, addTask, onTaskClick, setFormData, setDialogOpen }) => {
    const [taskTitle, setTaskTitle] = useState("")
    // const [isAdding, setIsAdding] = useState(false)

    // const handleAddTask = () => {
    //     setFormData({
    //         id: "",
    //         title: taskTitle,
    //         companyName: "",
    //         companyNumber: "",
    //         description: "",
    //         keyContactName: "",
    //         keyContactEmail: "",
    //         isImportant: false,
    //         campaignDate: new Date().toISOString().split("T")[0],
    //     })
    //     setDialogOpen(true)
    //     setTaskTitle("")
    // }

    return (
        <Card className="w-[300px] shrink-0 h-[fit-content]">
            <CardContent className="p-4">
                <div className="mb-4 flex items-center justify-between">
                    <h3 className="text-lg font-semibold">{column.title}</h3>
                    <Badge variant="secondary">{column.tasks.length}</Badge>
                </div>
                <Droppable id={columnId}>
                    <AnimatePresence>
                        <SortableContext items={column.tasks.map((task) => task.id)} strategy={verticalListSortingStrategy}>
                            <div className="space-y-3">
                                {column.tasks.map((task) => (
                                    <Task key={task.id} task={task} onClick={onTaskClick} /> // Pass onClick prop
                                ))}
                            </div>
                        </SortableContext>
                    </AnimatePresence>
                </Droppable>
                <div className="mt-4 space-y-2">
                    {/* <Input
                        type="text"
                        value={taskTitle}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTaskTitle(e.target.value)}
                        placeholder="Add new deal"
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === "Enter" && handleAddTask()}
                    /> */}
                    <Button
                        className="w-full text-white"
                        size="sm"
                        variant="primary"
                        onClick={() => {
                            setFormData({
                                id: "",
                                title: taskTitle,
                                companyName: "",
                                companyNumber: "",
                                description: "",
                                keyContactName: "",
                                keyContactEmail: "",
                                isImportant: false,
                                campaignDate: new Date().toISOString().split("T")[0],
                                columnId: columnId, // Set the columnId when adding from a specific column
                            })
                            setDialogOpen(true)
                            setTaskTitle("")
                        }}
                    //disabled={isAdding}
                    >
                        <Plus className="mr-2 h-4 w-4" />
                        Add Deal
                    </Button>
                </div>
            </CardContent>
        </Card>
    )
}

const KanbanBoard = () => {
    const [columns, setColumns] = useState<Record<string, { title: string; tasks: DealTask[] }>>(initialColumns)
    const [activeId, setActiveId] = useState<string | null>(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    //const [selectedTaskTitle, setSelectedTaskTitle] = useState("")

    const [formData, setFormData] = useState<DealTask>({
        id: "",
        title: "",
        companyName: "",
        companyNumber: "",
        description: "",
        keyContactName: "",
        keyContactEmail: "",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
    })

    const addTask = (columnId: string, task: DealTask) => {
        setColumns({
            ...columns,
            [columnId]: {
                ...columns[columnId],
                tasks: [...columns[columnId].tasks, task],
            },
        })
    }

    const handleDragStart = (event: any) => {
        setActiveId(event.active.id)
    }

    const handleDragEnd = (event: any) => {
        const { active, over } = event
        setActiveId(null)

        if (!over) return

        const activeTaskId = active.id
        const overId = over.id

        let activeColumnId: string | undefined
        let overColumnId: string | undefined

        for (const colId in columns) {
            if (columns[colId].tasks.some((task) => task.id === activeTaskId)) {
                activeColumnId = colId
                break
            }
        }

        for (const colId in columns) {
            if (columns[colId].tasks.some((task) => task.id === overId)) {
                overColumnId = colId
                break
            }
        }

        if (!overColumnId && columns[overId]) {
            overColumnId = overId
        }

        if (!activeColumnId || !overColumnId) {
            return
        }
        console.log(
            `Task ${activeTaskId} moved from "${columns[activeColumnId].title}" to "${columns[overColumnId].title}"`,
        )
        if (activeColumnId === overColumnId) {
            const column = columns[activeColumnId]
            const newTasks = arrayMove(
                column.tasks,
                column.tasks.findIndex((task) => task.id === activeTaskId),
                column.tasks.findIndex((task) => task.id === overId),
            )

            setColumns({
                ...columns,
                [activeColumnId]: { ...column, tasks: newTasks },
            })
        } else {
            const fromColumn = columns[activeColumnId]
            const toColumn = columns[overColumnId]

            const taskToMove = fromColumn.tasks.find((task) => task.id === activeTaskId)
            if (!taskToMove) return

            const newFromTasks = fromColumn.tasks.filter((task) => task.id !== activeTaskId)
            const newToTasks = [...toColumn.tasks, taskToMove]

            setColumns({
                ...columns,
                [activeColumnId]: { ...fromColumn, tasks: newFromTasks },
                [overColumnId]: { ...toColumn, tasks: newToTasks },
            })
        }
    }

    const activeTask = activeId
        ? Object.values(columns)
            .flatMap((column) => column.tasks)
            .find((task) => task.id === activeId)
        : null

    const handleTaskClick = (task: DealTask) => {
        setFormData(task)
        setDialogOpen(true)
    }

    const handleSaveTask = () => {
        if (!formData.title) return

        const columnId = formData.id
            ? Object.keys(columns).find((key) => columns[key].tasks.some((task) => task.id === formData.id)) || "replied"
            : formData.columnId || "replied" // Use the columnId from formData for new tasks

        if (formData.id) {
            setColumns((prev) => ({
                ...prev,
                [columnId]: {
                    ...prev[columnId],
                    tasks: prev[columnId].tasks.map((task) => (task.id === formData.id ? formData : task)),
                },
            }))
        } else {
            const newTask = {
                ...formData,
                id: Date.now().toString(),
            }
            addTask(columnId, newTask)
        }

        setDialogOpen(false)
        setFormData({
            id: "",
            title: "",
            companyName: "",
            companyNumber: "",
            description: "",
            keyContactName: "",
            keyContactEmail: "",
            isImportant: false,
            campaignDate: new Date().toISOString().split("T")[0],
            columnId: "",
        })
    }

    return (
        <div className="fullScreen">
            <div className="container">
                <div className="savedFiltersScreen">
                    <div className=" flex flex-col gap-5 justify-between  ">
                        <div className="mx-auto max-w-7xl h-[73vh]">
                            <div className="sfTitles mb-8 text-left flex flex-row justify-between">
                                <div>
                                    <h1 className="text-3xl font-bold">Deal Tracker</h1>
                                    <p className="text-muted-foreground">Manage and track your deals through different stages</p>
                                </div>
                                <Button
                                    className="text-white"
                                    size="sm"
                                    variant="primary"
                                    onClick={() => {
                                        setFormData({
                                            id: "",
                                            title: "",
                                            companyName: "",
                                            companyNumber: "",
                                            description: "",
                                            keyContactName: "",
                                            keyContactEmail: "",
                                            isImportant: false,
                                            campaignDate: new Date().toISOString().split("T")[0],
                                            columnId: "", // Set the columnId when adding from a specific column
                                        })
                                        setDialogOpen(true)
                                        //setTaskTitle("")
                                    }}
                                //disabled={isAdding}
                                >
                                    <Plus className="mr-2 h-4 w-4" />
                                    Add Deal
                                </Button>
                            </div>

                            <DndContext collisionDetection={closestCorners} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                                <div className="flex gap-6 overflow-x-auto pb-4 h-[100%]">
                                    {Object.entries(columns).map(([columnId, column]) => (
                                        <Column
                                            key={columnId}
                                            columnId={columnId}
                                            column={column}
                                            addTask={addTask}
                                            onTaskClick={handleTaskClick}
                                            setFormData={setFormData}
                                            setDialogOpen={setDialogOpen}
                                        />
                                    ))}
                                </div>
                                <DragOverlay>
                                    {activeId && activeTask ? (
                                        <div className="rotate-3">
                                            <Task task={activeTask} onClick={handleTaskClick} />
                                        </div>
                                    ) : null}
                                </DragOverlay>
                            </DndContext>
                        </div>
                    </div>
                </div>
            </div>

            {dialogOpen && (
                <Dialog aria-describedby="Task dialgue" open={dialogOpen} onOpenChange={setDialogOpen}>
                    <DialogContent className="sm:max-w-[600px] z-[9999] rounded-[32px] bg-[#F2F4F7]">
                        <div className="flex flex-row align-center justify-between">
                            <DialogTitle className="text-lg font-semibold text-gray-950">
                                {formData.title ? "Edit Deal" : "New Deal"}
                            </DialogTitle>
                            <button onClick={() => setDialogOpen(false)}>
                                <SvgCloseIcon />
                            </button>
                        </div>
                        <div className="grid gap-4 py-4">
                            <div className="grid gap-2">
                                <Label htmlFor="dealName">Deal Name</Label>
                                <Input
                                    id="dealName"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="companyName">Company Name</Label>
                                <Input
                                    id="companyName"
                                    value={formData.companyName}
                                    onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="companyNumber">Company Number</Label>
                                <Input
                                    id="companyNumber"
                                    value={formData.companyNumber}
                                    onChange={(e) => setFormData({ ...formData, companyNumber: e.target.value })}
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="description">Description</Label>
                                <Textarea
                                    id="description"
                                    value={formData.description}
                                    onChange={(e: any) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="keyContactName">Key Contact Name</Label>
                                <Input
                                    id="keyContactName"
                                    value={formData.keyContactName}
                                    onChange={(e) => setFormData({ ...formData, keyContactName: e.target.value })}
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="keyContactEmail">Key Contact Email</Label>
                                <Input
                                    id="keyContactEmail"
                                    type="email"
                                    value={formData.keyContactEmail}
                                    onChange={(e) => setFormData({ ...formData, keyContactEmail: e.target.value })}
                                />
                            </div>
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="important"
                                    checked={formData.isImportant}
                                    onCheckedChange={(checked: any) => setFormData({ ...formData, isImportant: checked as boolean })}
                                />
                                <Label htmlFor="important">Mark as Important</Label>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button variant="outline" onClick={() => setDialogOpen(false)}>
                                Cancel
                            </Button>
                            <Button onClick={handleSaveTask}>Save</Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    )
}

export default KanbanBoard

