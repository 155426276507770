import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  nextStep,
  prevStep,
  setCampaignName,
  selectCampaignName,
  selectCampaignType,
  selectedCollection,
  selectCurrentStep,
  setCreateCampaignResponse,
  selectCreateCampaignResponse,
  setEmailTemplates,
  selectEmailTemplate,
  selectMailboxData,
  updateMailboxData,
  resetCampaignState,
  selectCampaignList,
  setCampaignId,
  updateCurrentStep,
} from "../campaignSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createNewCampaign,
  getEmailTemplates,
  saveCampaign,
  saveCollectionId,
  saveCustomisations,
  saveEmailTemplates,
  saveMailBoxDataToApi,
  updateExistingCampaign,
} from "../services";
import { generateCampaignName } from "helpers";
import {
  selectCurrentSubscription,
  updateCurrentSubscription,
} from "components/subscription/subscriptionSlice";
import { SUBSCRIPTION_STATUS } from "components/subscription/types";
import {
  endTrialSubscription,
  getCurrentSubscription,
} from "components/subscription/services";

const useCampaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fromDraftState = location.state?.fromDraftState || false;
  const draftCampaignId = location.state?.campaignId;

  const currentSubscription = useSelector(selectCurrentSubscription);
  const currentStep = useSelector(selectCurrentStep);
  const selectedCollectionIds = useSelector(selectedCollection);
  const campaignType = useSelector(selectCampaignType);
  const alreadyCampaignName = useSelector(selectCampaignName);
  const campaignCreated = useSelector(selectCreateCampaignResponse);
  const emailMessage = useSelector(selectEmailTemplate);
  const mailboxData = useSelector(selectMailboxData);
  const existingCampaignNames = useSelector(selectCampaignList);
  const existingDomainDetails = JSON.parse(
    localStorage.getItem("domainSettings")!
  );
  const [showCampaignNameModal, setShowCampaignNameModal] = useState(false);
  const [generatedCampaignName, setGeneratedCampaignName] = useState("");
  const [name, setName] = useState(alreadyCampaignName);
  const [isEditing, setIsEditing] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customisationsData, setCustomisationsData] = useState<any>({});
  const [discardModal, setDiscardModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const campaignIdForCampaign = fromDraftState
    ? draftCampaignId
    : campaignCreated?.id;
  const inputRef = useRef<HTMLInputElement>(null);
  const userData = JSON.parse(localStorage.getItem("user")!);
  dispatch(setCampaignId(campaignIdForCampaign as string));

  useEffect(() => {
    if (!fromDraftState) {
      dispatch(resetCampaignState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fromDraftState) {
      fetchEmailTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmailTemplates = async () => {
    try {
      setIsLoading(true);
      const emailTemplateData = await getEmailTemplates();
      if (emailTemplateData) {
        setIsLoading(false);
        dispatch(setEmailTemplates(emailTemplateData));
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const handleEditCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(0, inputRef.current.value.length);
      }
    }, 0);
  };

  const handleCampaignNameModalClose = () => {
    setShowCampaignNameModal(false);
  };

  const handleSave = async (name: string) => {
    setIsEditing(false);
    dispatch(setCampaignName(name));
    try {
      setIsLoading(true);
      const payload = {
        name: name,
        type: campaignType || "email",
      };
      if (campaignIdForCampaign) {
        const data = await updateExistingCampaign(campaignIdForCampaign, name);
        if (data) {
          setIsLoading(false);
          dispatch(setCreateCampaignResponse(data));
          setName(data.name);
          setShowCampaignNameModal(false);
        }
      } else {
        const data = await createNewCampaign(payload);
        if (data) {
          setIsLoading(false);
          dispatch(setCreateCampaignResponse(data));
          setName(data.name);
          setShowCampaignNameModal(false);
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.data.message === "Campaign with this name already exists"
      ) {
        setShowCampaignNameModal(true);
        setErrorMessage(true);
      } else {
        console.error("Error:", error);
      }
    }
  };

  const handleFormSubmit = (data: any) => {
    setCustomisationsData(data);
  };

  // const handleFormChange = (data: any, isValid: boolean) => {
  //   setIsFormValid(isValid);
  //   //setCustomisationsData(data);
  //   console.log("Form Data:", data);
  // };

  const handleMailboxData = (data: any) => {
    dispatch(updateMailboxData(data));
  };

  const saveCollectionIds = async () => {
    try {
      setIsLoading(true);
      const data = await saveCollectionId(
        {
          collectionIds: selectedCollectionIds,
        },
        campaignIdForCampaign as string
      );
      if (data) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveCustomisationsData = async () => {
    try {
      setIsLoading(true);
      const data = await saveCustomisations(customisationsData);

      if (data) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveMessagesForOutreach = async () => {
    try {
      setIsLoading(true);

      const payload = {
        messages: emailMessage.map((template) => ({
          subject: template.subject,
          body: template.body,
          sendAfterDays: template.sendAfterDays,
        })),
      };

      const data = await saveEmailTemplates(
        payload,
        campaignIdForCampaign as string
      );

      if (data) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveMailBoxData = async () => {
    try {
      setIsLoading(true);

      const data = await saveMailBoxDataToApi(
        mailboxData,
        campaignIdForCampaign as string
      );

      if (data) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const saveCampaignData = async () => {
    try {
      setIsLoading(true);
      const data = await saveCampaign(campaignIdForCampaign as string);
      if (data) {
        setIsLoading(false);
        //dispatch(setCreateCampaignResponse(data));
        navigate(`../campaign`, {
          state: { saveCampaignSuccess: true },
        });
      }
    } catch (error) {
      navigate(`../campaign`, {
        state: { saveCampaignError: true },
      });
      setIsLoading(false);
      console.error("Error", error);
    }
  };

  const handleNextClick = async () => {
    if (currentStep === 1) {
      saveCollectionIds();
      dispatch(nextStep());
    }
    if (currentStep === 2) {
      if (!isFormValid) return;
      else {
        saveCustomisationsData();
        dispatch(nextStep());
      }
    }
    if (currentStep === 3) {
      if (
        currentSubscription.status === SUBSCRIPTION_STATUS.TRAILING ||
        existingDomainDetails.length > 0
      ) {
        await saveMessagesForOutreach();
        dispatch(updateCurrentStep(5));
      } else {
        saveMessagesForOutreach();
        dispatch(nextStep());
      }
    }
    if (currentStep === 4) {
      saveMailBoxData();
      dispatch(nextStep());
    }
    if (currentStep === 5) {
      saveCampaignData();
      dispatch(nextStep());
    }
  };

  const handleBackClick = () => {
    if (
      (currentStep === 5 &&
        currentSubscription.status === SUBSCRIPTION_STATUS.TRAILING) ||
      existingDomainDetails.length > 0
    ) {
      dispatch(updateCurrentStep(3));
    } else {
      dispatch(prevStep());
    }
  };

  const handleCancelClick = () => {
    setDiscardModal(true);
  };

  const handleEndTrialClick = async () => {
    setIsLoading(true);
    try {
      const endTrialResponse = await endTrialSubscription(
        currentSubscription?.id
      );
      if (endTrialResponse) {
        const currentSubscription = await getCurrentSubscription();
        if (currentSubscription) {
          dispatch(updateCurrentSubscription(currentSubscription));
          navigate(`../campaign`, {
            state: { subscriptionActivated: true },
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error ending trial:", error);
    }
  };

  const handleYesClick = () => {
    dispatch(resetCampaignState());
    navigate("../campaign");
  };

  const isNextButtonDisabled = () => {
    if (currentStep === 1) return selectedCollectionIds?.length === 0;
    if (currentStep === 2) return !isFormValid;
    if (currentStep === 4) return !isFormValid;
    return false;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [currentStep]);

  useEffect(() => {
    if (alreadyCampaignName === "New Campaign") {
      setShowCampaignNameModal(true);

      const campaignNames = existingCampaignNames.map(
        (campaign: any) => campaign.name
      );

      const newCampaignName = generateCampaignName(campaignNames);
      setGeneratedCampaignName(newCampaignName);
    } else {
      setGeneratedCampaignName(alreadyCampaignName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    steps: [
      { id: 1, name: "Select Collection" },
      { id: 2, name: "Customise" },
      { id: 3, name: "Edit Message" },
      ...(currentSubscription.status !== SUBSCRIPTION_STATUS.TRAILING &&
      !(existingDomainDetails.length > 0)
        ? [{ id: 4, name: "Mailboxes" }]
        : []),
      { id: 5, name: "Summary" },
    ],
    currentStep,
    name,
    isEditing,
    isFormValid,
    isLoading,
    inputRef,
    prevStep,
    showCampaignNameModal,
    alreadyCampaignName,
    discardModal,
    generatedCampaignName,
    errorMessage,
    userData,
    currentSubscription,
    setShowCampaignNameModal,
    handleEditCampaignName,
    handleEditClick,
    handleSave,
    handleFormSubmit,
    handleNextClick,
    handleBackClick,
    isNextButtonDisabled,
    setIsFormValid,
    setIsLoading,
    dispatch,
    handleMailboxData,
    handleCampaignNameModalClose,
    handleCancelClick,
    setDiscardModal,
    handleYesClick,
    handleEndTrialClick,
  };
};

export default useCampaign;
