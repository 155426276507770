import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { appSlice } from "./appSlice";
import { collectionSlice } from "components/collection/collectionSlice";
import { campaignSlice } from "components/campaign/campaignSlice";
import { subscriptionSlice } from "components/subscription/subscriptionSlice";
export const rootReducer = combineReducers({
  app: appSlice,
  collection: collectionSlice.reducer,
  campaign: campaignSlice.reducer,
  subscription: subscriptionSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoreActions: true,
        ignoreState: true,
      },
    }),
});

export const persistor = persistStore(store);
