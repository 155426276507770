import { ColumnDef, useReactTable, getCoreRowModel, getSortedRowModel, flexRender, SortingState } from "@tanstack/react-table";
import { Card, CardContent } from "components/shadcn/ui/card";
import { Button } from "components/shadcn/ui/button";
import { Table, TableHead, TableHeader, TableRow, TableBody, TableCell } from "components/shadcn/ui/table";
import { Badge } from "components/shadcn/ui/badge";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCampaignDetailRecipients } from "../campaignSlice";
import lang from "lang";
import SvgChevronSelectorIcon from "components/common/iconComponents/chevronSelectorIcon";
import SvgBackArrowIcon from "components/common/iconComponents/backArrowIcon";
import SvgCollectionExportedIcon from "components/common/iconComponents/CollectionExportedIcon";

interface CampaignData {
    company: string | null;
    recipient: string;
    email: string;
    comms: string;
    statuses: string[];
}

const statusLabels: Record<string, { label: string; bg: string; text: string }> = {
    email_sent: { label: "Email Sent", bg: "bg-blue-100", text: "text-blue-800" },
    email_opened: { label: "Email Opened", bg: "bg-green-100", text: "text-green-800" },
    email_clicked: { label: "Email Clicked", bg: "bg-yellow-100", text: "text-yellow-800" },
    email_replied: { label: "Email Replied", bg: "bg-purple-100", text: "text-purple-800" },
    email_bounced: { label: "Email Bounced", bg: "bg-red-100", text: "text-red-800" },
    reply_received: { label: "Reply Received", bg: "bg-green-100", text: "text-green-800" },
    auto_reply_received: { label: "Auto Reply Received", bg: "bg-green-100", text: "text-green-800" },
    link_clicked: { label: "Link Clicked", bg: "bg-blue-100", text: "bg-blue-100" },
    account_error: { label: "Account Error", bg: "bg-red-100", text: "text-red-800" },
    campaign_completed: { label: "Campaign Completed", bg: "bg-green-100", text: "text-green-800" },
};

export function CampaignDetails() {
    const navigate = useNavigate();
    const location = useLocation();

    const campaignDetail = useSelector(selectCampaignDetailRecipients);
    const { campaign: campaignCopy } = lang;
    const [tableData, setTableData] = useState<CampaignData[]>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const isCampaignWarmingUp = location?.state?.campaignWarmingUp;

    useEffect(() => {
        if (campaignDetail) {
            const transformedData = campaignDetail.recipients.map((item: any) => ({
                company: item.companyName ?? "N/A",
                recipient: `${item.firstName} ${item.lastName}`,
                email: item.email,
                comms: "E-mail",
                statuses: item.statuses || [],
            }));
            setTableData(transformedData);
        }
    }, [campaignDetail]);

    const columns: ColumnDef<CampaignData>[] = [
        {
            accessorKey: "company",
            header: "Company",
            cell: ({ row }) => (
                <span className="font-inter font-medium text-[14px] leading-[20px] text-gray-900">
                    {row.getValue("company")}
                </span>
            ),
        },
        { accessorKey: "recipient", header: "Recipient" },
        { accessorKey: "email", header: "E-mail" },
        { accessorKey: "comms", header: "Comms", enableSorting: false },
        {
            accessorKey: "statuses",
            header: "Status",
            enableSorting: false,
            cell: ({ row }) => {
                const statuses = row.getValue("statuses") as string[];

                return (
                    <div className="flex flex-wrap gap-2">
                        {statuses.map((status) => {
                            const { label, bg, text } = statusLabels[status] || { bg: "bg-gray-100", text: "text-gray-800", label: "Unknown" };
                            return (
                                <Badge key={status} variant="secondary" className={`px-2 py-1 rounded-full ${bg} ${text}`}>{label}</Badge>
                            );
                        })}
                    </div>
                );
            },
        },
    ];

    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: { sorting },
        onSortingChange: setSorting,
    });

    return (
        <div className="fullScreen">
            <div className="container w-full">
                <div className="savedFiltersScreen">
                    <div className="sfTitles flex flex-row gap-5 justify-between">

                        <div className="w-full text-left">


                            <div className="flex flex-col gap-3 items-start">
                                <Button variant="link" className=" text-gray-600 pl-0 hover:text-purple-600" onClick={() => navigate('../campaign')}>
                                    <SvgBackArrowIcon /> {campaignCopy.back}
                                </Button>
                                <h2 className="text-2xl font-bold">{campaignDetail.campaignName}</h2>
                                {isCampaignWarmingUp && (
                                    <div className="w-full p-[24px] gap-0 flex flex-row align-center rounded-[24px] justify-between bg-primary-100">
                                        <div className="flex flex-row flex-start gap-6">
                                            <SvgCollectionExportedIcon />
                                            <div className="flex flex-col justify-start items-start">
                                                <div className="font-instrument-sans font-semibold text-lg leading-7 text-gray-950">
                                                    {campaignCopy.warmingUpCampaign}
                                                </div>
                                                <div className="font-inter text-base leading-6 text-gray-950">
                                                    {campaignCopy.warmingUpSubText}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Card className="mt-4">
                                <CardContent className="flex justify-around items-center py-4 px-6 border border-[#EAECF0] h-[120px]">
                                    <div>
                                        <p className="text-sm font-medium text-gray-600">{campaignCopy.recipients}</p>
                                        <p className="text-[32px] font-bold text-primary-700 text-center">{campaignDetail.totalRecipients}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-600">{campaignCopy.totalEmailSent}</p>
                                        <p className="text-[32px] font-bold text-primary-700 text-center">{campaignDetail.totalEmailsSent}/{campaignDetail.totalEmails}</p>
                                    </div>
                                </CardContent>
                            </Card>

                            <div className="overflow-x-auto mt-4">
                                <Table>
                                    <TableHeader>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id}>
                                                {headerGroup.headers.map((header) => (
                                                    <TableHead key={header.id} className="text-left cursor-pointer" onClick={header.column.getToggleSortingHandler()}>
                                                        <div className="text-left cursor-pointer flex flex-row gap-3">
                                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                            {header.column.getCanSort() && ( // Only show sorting icon if sorting is enabled
                                                                header.column.getIsSorted()
                                                                    ? header.column.getIsSorted() === "asc"
                                                                        ? <SvgChevronSelectorIcon />
                                                                        : <SvgChevronSelectorIcon />
                                                                    : <SvgChevronSelectorIcon />
                                                            )}
                                                        </div>
                                                    </TableHead>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableHeader>
                                    <TableBody className="text-left">
                                        {table.getRowModel().rows.map((row) => (
                                            <TableRow key={row.id}>
                                                {row.getVisibleCells().map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        className={cell.column.id === "company" ? "w-[40%]" : ""}
                                                    >
                                                        <div className="text-sm font-regular text-gray-600">
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CampaignDetails;