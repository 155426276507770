export type CreateCampaignDto = {
  name: string;
  type: string;
};

export type CreateCampaignResponseDto = {
  createdAt: string;
  currentStep: number;
  id: string;
  name: string;
  status: string;
  type: string;
  updatedAt: string;
};

export type SaveCollectionIdsDTO = {
  collectionIds: string[];
};

export type CustomisationRequestDTO = {
  holdingCompany: string;
  phoneNumber: string;
  website: string;
};

export type EmailTemplateRequestDTO = {
  body: string;
  sendAfterDays: number;
  subject: string;
};

export type SaveEmailTemplatesPayload = {
  messages: EmailTemplateRequestDTO[];
};

export type MailboxRequestDTO = {
  preferredDomain: string;
  replyTo: string;
};

export interface CampaignSummaryDto {
  emailsToSend: number;
  creditsBalance: number;
  creditsToUse: number;
  creditsLeft: number;
  companiesWithoutContacts: string[];
  domainStatus: string;
  totalCompanies: number;
  totalRecipients: number;
  emailsPerWorkflow: number;
}

export type CampaignList = {
  id: string;
  name: string;
  company: string;
  status: "sent" | "draft" | "warming-up";
  recipients: number;
  type: "Email" | "Letter" | "Email and Letter";
  replies: string;
  date: string;
  actions: string;
};

export type updateCollectionDetailsRequest = {
  name: string;
  description: string;
  exportedOn: string;
};

type Collection = {
  id: string;
  name: string;
  description: string;
};

type Message = {
  subject: string;
  body: string;
  sendAfterDays: number;
};

type DomainSettings = {
  id: string;
  preferredDomain: string;
  domain: string;
  mailboxes: string[];
  createdAt: string;
  expireAt: string;
  updatedAt: string;
  status: "active" | "inactive" | "expired";
  replyTo: string;
};

export type CampaignDetails = {
  id: string;
  name: string;
  type: "email" | "sms" | "push";
  currentStep: number;
  status: "draft" | "active" | "completed";
  createdAt: string;
  updatedAt: string;
  collections: Collection[];
  messages: Message[];
  domainSettings: DomainSettings;
};

export enum STATUS {
  SENT = "sent",
  DRAFT = "draft",
  WARMING_UP = "warming-up",
  WARMING = "warming",
  ERROR = "error",
  PAUSED = "paused",
  COMPLETED = "completed",
  RUNNING = "running",
}

export enum DOMAIN_STATUS {
  DOMAIN_READY = "domain_ready",
}
