import React, { useEffect, useState } from "react";
import SavedFilterRow, { convertResponseReExport } from "../components/savedFilterRow";
import LoadingPopUp from "../components/popUps/LoadingPopUp";
import SvgFolderIcon from "components/common/iconComponents/FolderIcon";
import SvgFilterIcon from "components/common/iconComponents/FilterIcon";
import { Button } from "components/shadcn/ui/button";
import { useNavigate } from "react-router-dom";
import { selectCollections, setCollections } from "components/collection/collectionSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosWithToken from "axiosWithToken";
import { showCampaignFeature, showOutreachFeature } from "components/utils/network/endpoints";
import { resetCampaignState } from "components/campaign/campaignSlice";
import { AlertCircle, Search } from "lucide-react";
import { Mixpanel } from "components/Mixpanel/Mixpanel";
import { auth } from '..';
import axios from "axios";
import { Alert, AlertDescription, AlertTitle } from "components/shadcn/ui/alert";
import SvgWarningIconWithOutline from "components/common/iconComponents/warningIconWithOutline";
import SvgCloseIcon from "components/popUps/CloseIcon";
import Loader from "components/common/loader";
import { Input } from "components/shadcn/ui/input";



interface SavedFiltersProps {
  isExports?: boolean;
  isFilters?: boolean;
  updateCredits?: any;
  creditsP?: number;
  hasData?: any;
  isCollection?: boolean;
}

const SavedFilters: React.FC<SavedFiltersProps> = ({
  isExports,
  isFilters,
  updateCredits,
  creditsP,
  hasData,
  isCollection,
}) => {
  const baseURL2 = process.env.REACT_APP_BASEURL2;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collections = useSelector(selectCollections);
  const [downloadStatus, setDownloadStatus] = useState<{
    total: number;
    success: number;
    failedFiles: string[];
  } | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [updateVar, setUpdateVar] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [collectionData, setCollectionData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (isCollection) {
      axiosWithToken
        .get(baseURL2 + "api/collections")
        .then((response) => {
          const collectionsData = response.data;
          dispatch(setCollections(collectionsData));
          setCollectionData(collectionsData);
        })
        .catch((error) => {
          console.error("Error fetching collections data:", error);
        });
    }
    // eslint-disable-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollection]);

  const showSaved = () => {

    const filteredCollections = collections
      ?.filter((item: any) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return (
      <div className="w-full">
        {isCollection && (
          <div className="relative w-full mb-4">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
            <Input
              type="text"
              placeholder="Search collection by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 w-full border border-gray-300 rounded-md py-2"
            />
          </div>
        )}

        {isCollection ? (
          filteredCollections.length > 0 ? (
            filteredCollections
              .sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
              .map((element: any) => (
                <SavedFilterRow
                  savedFilterP={element}
                  deleteFilter={deleteFilter}
                  isExports={isExports}
                  updateCredits={updateCredits}
                  creditsP={creditsP}
                  key={element.id}
                  collection={element}
                  isCollection={isCollection}
                  isFilters={isFilters}
                />
              ))
          ) : (
            <div className="h-[40vh] text-sm font-medium flex items-center justify-center">
              No collections found
            </div>
          )
        ) : (
          savedFilters?.map((element: any) => (
            <SavedFilterRow
              savedFilterP={element}
              deleteFilter={deleteFilter}
              isExports={isExports}
              updateCredits={updateCredits}
              creditsP={creditsP}
              key={element.id}
              collection={collectionData}
              isCollection={isCollection}
              isFilters={isFilters}
            />
          ))
        )}
      </div>
    );
  };

  // #endregion

  // #region WEB REQUESTS

  const getAccountDetails = async () => {
    try {
      const response = await axiosWithToken.get(`${baseURL2}api/auth/account-dashboard-details`);
      const data = response.data;
      setSavedFilters(data.email);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };


  const deleteFilter = (id: any) => {
    setSavedFilters(savedFilters.filter((x: any) => x.id !== id));
    setUpdateVar(updateVar + 1);
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user") || "null");

    if (user === null || user === undefined) {
      getAccountDetails();
    } else {
      if (isExports) {
        if (user.exports) {
          setSavedFilters(user.exports);
        } else {
          setSavedFilters([]);
        }
      }
      if (isFilters) {
        setSavedFilters(user.savedFilters || []);
      }
      setLoading(false);
    }
  }, [hasData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchNavigation = () => {
    navigate(`../`, { replace: true });
  };

  const handleCampaignNavigation = () => {
    dispatch(resetCampaignState());
    navigate(`../campaignLanding`, { replace: true });
  }

  const downloadAll = async () => {
    Mixpanel.track("FeatureUsage", {
      "User ID": auth.currentUser?.uid,
      "Date": new Date().toISOString(),
      $email: auth?.currentUser?.email,
      $name: auth?.currentUser?.displayName,
      "User Type": "Buyer",
      "Action": "Download all Export"
    });

    let successCount = 0;
    const failedFiles: string[] = [];

    await Promise.all(
      savedFilters.map(async (savedFilter: any) => {
        try {
          setIsLoading(true);
          const response = await axios.post(baseURL2 + "reExportSameBusinesses", {
            companies: savedFilter.companies,
            timestamp: savedFilter.time,
          });

          convertResponseReExport(response.data.companies, savedFilter.name);
          successCount++;
        } catch (error) {
          console.error(`Failed to download: ${savedFilter.name}`, error);
          failedFiles.push(savedFilter.name);
        }
      })
    );

    setDownloadStatus({
      total: savedFilters.length,
      success: successCount,
      failedFiles: failedFiles,
    });
    setShowAlert(true);
    setIsLoading(false);
  };

  return (
    <div className="fullScreen">
      <div className="container max-w-[80%]">
        <div className="savedFiltersScreen">
          <div className="sfTitles flex flex-row items-center justify-between">
            {isLoading && <Loader />}
            <div className="flex flex-col items-start">
              <span className="display-sm semibold gray-900">
                {isExports ? (showOutreachFeature ? "Collections" : "Exports") : "Saved Filters"}
              </span>
              <span className="text-md regular gray-600">
                {isExports
                  ? showOutreachFeature
                    ? ""
                    : "Past exported companies"
                  : "Track companies or perform new searches based on your saved filter criteria."}
              </span>
            </div>
            {showOutreachFeature && showCampaignFeature && !isFilters && collections.length > 0 && (
              <Button variant="primary" onClick={handleCampaignNavigation}>
                <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                  New Campaign
                </div>
              </Button>
            )}
          </div>
          {isExports && !isCollection && savedFilters?.length > 0 && < div className="flex items-center justify-between p-4 bg-[#FFE4E8] text-pink-900 rounded-[24px] w-full">
            <div className="flex items-center gap-3">
              <div className="p-2 rounded-full">
                <AlertCircle className="text-pink-700" size={20} />
              </div>
              <div>
                <p className="font-semibold text-lg text-gray-950 text-left">Exports are changing!</p>
                <p className="text-md font-regular text-gray-950">We are changing the way users save and export companies. Download your data before this page is no longer available.</p>
              </div>
            </div>
            <Button variant="primary" className="bg-pink-600 hover:bg-pink-700 text-white px-4 py-2 text-sm font-medium rounded-[32px]" onClick={downloadAll}>
              <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                Download all
              </div>
            </Button>
          </div>
          }
          {downloadStatus && showAlert && <Alert variant="info" className="mb-4">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row gap-5 items-center">
                <SvgWarningIconWithOutline className="h-5 w-5 mr-3" />
                <div className="flex flex-col font-inter gap-2 text-[14px] font-semibold leading-[20px] text-left text-[#B54708]">
                  <AlertTitle>Successfully downloaded {downloadStatus.success} out of {downloadStatus.total} files.</AlertTitle>
                  {downloadStatus.failedFiles.length > 0 && <AlertDescription>Failed to download exports: {downloadStatus.failedFiles.join(", ")}</AlertDescription>}
                </div>
              </div>
              <button onClick={() => setShowAlert(false)}><SvgCloseIcon className="text-right h-4 w-4 cursor-pointer opacity-70 hover:opacity-100" /></button>
            </div>
          </Alert>
          }
          {isCollection ? (
            <div className={`flex items-center flex-col gap-8 w-full min-h-[50vh] ${collections.length === 0 ? "justify-center" : ""}`}>
              {collections.length === 0 ? (
                <>
                  <SvgFolderIcon />
                  <div className="flex flex-col gap-2">
                    <p className="text-lg font-semibold font-[InstrumentSans] text-center leading-[28px]">
                      No Collections yet
                    </p>
                    <p className="text-sm font-normal font-[Inter] text-center leading-[20px] decoration-none decoration-[from-font]">
                      Use search to find and collect interesting companies. You can then use your collection to run outreach campaigns.
                    </p>
                  </div>
                  <Button variant="primary" onClick={handleSearchNavigation}>
                    <div className="font-inter text-sm font-semibold leading-5 text-left text-white">Go to Search</div>
                  </Button>
                </>
              ) : (
                showSaved()
              )}
            </div>
          ) : (
            <div className="w-full">
              {savedFilters.length > 0 ? (
                showSaved()
              ) : (
                <div className="flex items-center justify-center flex-col gap-8 w-full min-h-[50vh]">
                  {isExports ? <SvgFolderIcon /> : <SvgFilterIcon />}
                  <div className="flex flex-col gap-2">
                    <p className="text-lg font-semibold font-[InstrumentSans] text-center leading-[28px]">
                      {isExports ? "No exports yet" : "No saved filters yet"}
                    </p>
                    <p className="text-sm font-normal font-[Inter] text-center leading-[20px] decoration-none decoration-[from-font]">
                      {isExports ? "Export companies first" : "Save filters first"}
                    </p>
                  </div>
                  <Button
                    onClick={handleSearchNavigation}
                    variant="primary"
                    className="rounded-[32px] text-lg font-normal text-gray-600 bg-transparent border border-gray-300 px-4 py-2 gap-2 flex items-center"
                  >
                    <div className="font-inter text-sm font-semibold leading-5 text-left text-white">Go to Search</div>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {loading && <LoadingPopUp />}
    </div >
  );
};

export default SavedFilters;