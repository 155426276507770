import "../../styling/saved.css";
import React, { useEffect, useState } from "react";
import { Button } from "components/shadcn/ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import { CampaignsTable } from "./campaignTable";
import { selectCollections } from "components/collection/collectionSlice";
import SvgFolderIcon from "components/common/iconComponents/FolderIcon";
import { useDispatch, useSelector } from "react-redux";
import lang from "lang";
import { getCampaignList, getEmailTemplates } from "./services";
import Loader from "components/common/loader";
import { setCampaignListWithDetails, setEmailTemplates } from "./campaignSlice";
import { Alert, AlertDescription, AlertTitle } from "components/shadcn/ui/alert";
import SvgWarningIconWithOutline from "components/common/iconComponents/warningIconWithOutline";
import { MailCheckIcon } from "lucide-react";

interface CampaignProps { }
const Campaign: React.FC<CampaignProps> = () => {
    const { campaign: campaignCopy } = lang;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const collections = useSelector(selectCollections);

    const [campaignList, setCampaignList] = useState([]);
    const [loading, setLoading] = useState(false);
    const isCampaignSaved = location.state;

    const handleSearchNavigation = () => {
        navigate(`../`, { replace: true });
    };

    useEffect(() => {
        fetchEmailTemplates();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchEmailTemplates = async () => {
        try {
            setLoading(true);
            const emailTemplateData = await getEmailTemplates();
            if (emailTemplateData) {
                setLoading(false);
                dispatch(setEmailTemplates(emailTemplateData));
            }
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        if (collections.length === 0) return;
        const fetchCampaignList = async () => {
            setLoading(true);
            const fetchedCampaignList = await getCampaignList();
            if (fetchedCampaignList.length === 0 && collections.length) {
                navigate(`../campaignLanding`, { replace: true });
                setLoading(false);
            } else {
                setCampaignList(fetchedCampaignList);
                dispatch(setCampaignListWithDetails(fetchedCampaignList));
                setLoading(false);
            }
        };
        fetchCampaignList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="fullScreen">
            {loading && <Loader />}
            <div className="container max-w-[80%]">
                <div className="savedFiltersScreen">
                    <div className="sfTitles flex flex-row gap-5 justify-between">
                        <div className="display-sm semibold text-gray-900">Campaigns</div>

                        {collections.length > 0 && (
                            <Button
                                variant="primary"
                                onClick={() =>
                                    navigate(`../campaignLanding`, { replace: true })
                                }
                            >
                                <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                                    {campaignCopy.newCampaign}
                                </div>
                            </Button>
                        )}
                    </div>

                    {isCampaignSaved?.saveCampaignSuccess && (
                        <Alert className="bg-[#ECFDF3] rounded-[12px] border border-[#067647]">
                            <div className="flex flex-row items-center gap-3">
                                <MailCheckIcon className="text-[#067647]" />
                                <div className="flex flex-col gap-2  text-left text-[#067647]">
                                    <AlertTitle className="text-md font-semibold">
                                        {campaignCopy.campaignSuccessBannerHeader}
                                    </AlertTitle>
                                    <AlertDescription className="text-sm font-normal">
                                        {campaignCopy.campaignSuccessBannerSubtext}
                                    </AlertDescription>
                                </div>
                            </div>
                        </Alert>
                    )}

                    {isCampaignSaved?.subscriptionActivated && (
                        <Alert className="bg-[#ECFDF3] rounded-[12px] border border-[#067647]">
                            <div className="flex flex-row items-center gap-3">
                                <MailCheckIcon className="text-[#067647]" />
                                <div className="flex flex-col gap-2  text-left text-[#067647]">
                                    <AlertTitle className="text-md font-semibold">
                                        Your Subscription have been activated
                                    </AlertTitle>
                                    <AlertDescription className="text-sm font-normal">
                                        We have saved your campaign as draft and you can start it now by clicking on it.
                                    </AlertDescription>
                                </div>
                            </div>
                        </Alert>
                    )}

                    {isCampaignSaved?.saveCampaignError && (
                        <Alert variant={"destructive"}>
                            <div className="flex flex-row items-center gap-3">
                                <div>
                                    <SvgWarningIconWithOutline className="h-5 w-5 mr-3" />
                                </div>
                                <div className="flex flex-col gap-2 text-left">
                                    <AlertTitle className="text-md font-semibold">
                                        {campaignCopy.campaignErrorBannerHeader}
                                    </AlertTitle>
                                    <AlertDescription className="text-sm font-normal">
                                        {campaignCopy.campaignErrorBannerSubtext}
                                    </AlertDescription>
                                </div>
                            </div>
                        </Alert>
                    )}

                    {!loading &&
                        (collections.length > 0 && campaignList.length > 0 ? (
                            <CampaignsTable campaignList={campaignList} />
                        ) : (
                            <div className="w-full flex flex-col gap-8 items-center min-h-[50vh] justify-center">
                                <SvgFolderIcon />
                                <div className="flex flex-col gap-2">
                                    <p className="text-lg font-semibold font-[InstrumentSans] text-center leading-[28px]">
                                        {campaignCopy.noCollectionsYet}
                                    </p>
                                    <p className="text-sm font-normal font-[Inter] text-center leading-[20px] decoration-none decoration-[from-font]">
                                        {campaignCopy.noCollectionsSubText}
                                    </p>
                                </div>
                                <Button variant="primary" onClick={handleSearchNavigation}>
                                    <div className="font-inter text-sm font-semibold leading-5 text-left text-white">
                                        {campaignCopy.goToSearch}
                                    </div>
                                </Button>
                            </div>
                        ))}
                </div>
            </div>
        </div >
    );
};

export default Campaign;
