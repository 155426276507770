import React, { useState, useEffect } from "react";
import {
    Select,
    SelectTrigger,
    SelectContent,
    SelectItem,
    SelectValue,
    SelectGroup,
} from "components/shadcn/ui/select";
import { Button } from "components/shadcn/ui/button";
import SvgDownloadIcon from "components/common/iconComponents/DownloadIcon";
import SvgCloseIcon from "./CloseIcon";
import axiosWithToken from "axiosWithToken";
import Loader from "components/common/loader";
import { useSelector } from "react-redux";
import { selectCollections } from "components/collection/collectionSlice";
import BuyCreditsPopUp from "components/Billing/BuyCreditsPopUp";
import UpdatePaymentPopUp from "components/Billing/UpdatePaymentPopUp";
import CustomNameCollectionModal from "./customNameCollectionPopup";
import { AxiosError } from "axios";
import { showNewPaymentFlow } from "components/utils/network/endpoints";
import EmailQuotaModal from "components/subscription/emailQuotaModal";
import UpgradeQuotaModal from "components/subscription/upgradeQuota";

export const SaveCollectionModal = ({
    onCancel,
    onSave,
    selectedCompanies,
    creditsUsed,
    updateCredits,
}: {
    onCancel: () => void;
    onSave: (collection: string, updatedExistingCollection: boolean) => void;
    selectedCompanies: string[];
    creditsUsed: number;
    companies: any;
    collection: any;
    updateCredits: any;
}) => {
    const baseURL2 = process.env.REACT_APP_BASEURL2;
    const collections = useSelector(selectCollections);
    let user = JSON.parse(localStorage.getItem("user")!);
    const [selectedCollection, setSelectedCollection] = useState<string | null>(
        null
    );
    //const [customCollection, setCustomCollection] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [companiesTobeCollected, setCompaniesTobeCollected] = useState<any>([]);
    const [isError, setIsError] = useState(false);
    const [isFreePlanWithCredits, setIsFreePlanWithCredits] = useState(false);
    const [showBuyCredits, setShowBuyCredits] = useState(false);
    const [showUpdatePayment, setShowUpdatePayment] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<any>(null);
    const [noCreditsLeft, setNoCreditsLeft] = useState(
        user.credits - user.usedCredits < creditsUsed
    );
    const [showCustomCollectionModal, setShowCustomCollectionModal] =
        useState(false);
    const [errorAlreadyExists, setErrorAlreadyExists] = useState<boolean>(false);
    const [showUpgradeEmailQuotaMOdal, setShowUpgradeEmailQuotaModal] =
        useState(false);
    const [showUpgradeQutaModal, setShowUpgradeQuotaModal] = useState(false);
    useEffect(() => {
        let pm = JSON.parse(localStorage.getItem("paymentMethod")!);
        setPaymentMethod(pm);
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user")!);

        if (!user) return;

        const isFreePlanWithCredits =
            user.plan === "free" &&
            collections.length === 0 &&
            user.credits > 0 &&
            selectedCompanies.length > 25;

        setIsFreePlanWithCredits(isFreePlanWithCredits);

        const companiesToCollect = isFreePlanWithCredits
            ? selectedCompanies.slice(0, 25)
            : selectedCompanies;
        setCompaniesTobeCollected(companiesToCollect);
    }, [selectedCompanies, collections]);

    const handleSave = async (customCollection?: string) => {
        let collectionId;
        setIsError(false);

        let updatedExistingCollection = false;

        if (customCollection && customCollection.trim() !== "") {
            try {
                setIsLoading(true);
                const createCollectionResponse = await axiosWithToken.post(
                    `${baseURL2}api/collections`,
                    {
                        name: customCollection,
                        description: "This is my custom collection.",
                    }
                );

                collectionId = createCollectionResponse.data.id;
            } catch (error: any) {
                if (
                    error instanceof AxiosError &&
                    (error.response?.status === 409 || error.response?.data?.message === "Collection with this name already exists")
                ) {
                    setIsLoading(false);
                    setIsError(true);
                    setErrorAlreadyExists(true);
                } else {
                    console.error("Error creating custom collection:", error);
                }
                return;
            }
        } else {
            collectionId = collections.find(
                (col: any) => col.name === selectedCollection
            )?.id;

            if (collectionId) {
                updatedExistingCollection = true;
            } else {
                console.error("Selected collection not found.");
                return;
            }
        }
        if (isError && !collectionId) {
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const response = await axiosWithToken.post(
                `${baseURL2}api/collections/${collectionId}/companies`,
                {
                    companyNumbers: companiesTobeCollected,
                }
            );

            if (response.status === 200 || response.status === 201) {
                setIsLoading(false);
                onSave(
                    customCollection || (selectedCollection as string),
                    updatedExistingCollection
                ); // Pass the flag to the parent
            } else {
                setIsLoading(false);
                console.error("Failed to associate companies with the collection.");
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error associating companies with the collection:", error);
        }
    };

    const confirmEditPayment = () => {
        setShowBuyCredits(false);
        setShowUpdatePayment(true);
    };

    const closePayment = () => {
        setShowUpdatePayment(false);
    };

    const paymentMethodAdded = (pm: any) => {
        setPaymentMethod(pm);
        paymentCancel(pm);
    };

    const paymentCancel = (pm: any) => {
        setShowUpdatePayment(false);

        if (pm === null || pm === undefined) {
            setShowBuyCredits(true);
        }
    };

    const paySuccess = () => {
        setShowBuyCredits(false);
        user.credits =
            user.credits + (creditsUsed - (user.credits - user.usedCredits));
        updateCredits(user.credits);
        setNoCreditsLeft(false);
    };

    const handleCustomNameSave = (customCollection: string) => {
        handleSave(customCollection);
    };

    const handleChangePlan = () => {
        setShowUpgradeEmailQuotaModal(false);
        setShowUpgradeQuotaModal(true);
    }

    const isButtonDisabled =
        selectedCollection === null || selectedCollection === "create-new";

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50">
            {isLoading && <Loader />}
            <div className="bg-gray-100 p-6 shadow-lg w-full max-w-[40%] rounded-[32px]">
                <div className="flex flex-row justify-between">
                    <SvgDownloadIcon />
                    <button onClick={() => onCancel()}>
                        <SvgCloseIcon />
                    </button>
                </div>
                <div className="mb-4 flex flex-col items-start gap-1 border border-gray-100">
                    <h3 className="text-lg font-semibold text-gray-800">
                        Collect {selectedCompanies.length} companies
                    </h3>
                    <p className="text-sm text-gray-600">
                        You will find your downloadable data in the Collections tab.
                    </p>
                </div>
                {showNewPaymentFlow && noCreditsLeft && (
                    <>
                        <div className="flex flex-row justify-between mb-3">
                            <span className="text-sm font-medium text-gray-950">
                                Available quota
                            </span>
                            <span className="flex flex-row gap-2">
                                <span className="text-sm font-medium text-primary-600">
                                    {" "}
                                    {user.usedCredits}/{user.credits}
                                </span>
                                <button
                                    className="text-sm font-semibold text-primary-700"
                                    onClick={() => setShowUpgradeEmailQuotaModal(true)}
                                >
                                    {" "}
                                    Upgrade
                                </button>
                            </span>
                        </div>
                        <div className="mb-6 bg-[#FFE4E8] rounded-[12px] py-4 px-6 text-sm font-normal text-gray-950">
                            Collection exceeds {user.credits} limit. Upgrade your limits or change
                            sellection
                        </div>
                    </>
                )}

                <div className="mb-6 bg-white rounded-[24px] p-6">
                    <label
                        htmlFor="collection"
                        className="flex text-sm font-medium text-gray-700 mb-2"
                    >
                        Choose Collection
                    </label>

                    {showCustomCollectionModal ? (
                        // <input
                        //     type="text"
                        //     id="collection"
                        //     className="w-full border border-gray-300 rounded-lg px-4 py-2 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        //     value={customCollection}
                        //     onChange={(e) => setCustomCollection(e.target.value)}
                        //     placeholder="Enter new collection name"
                        // />
                        <CustomNameCollectionModal
                            isOpen={showCustomCollectionModal || errorAlreadyExists}
                            onClose={() => setShowCustomCollectionModal(false)}
                            onSave={(name) => handleCustomNameSave(name)}
                            errorAlreadyExists={errorAlreadyExists}
                            onNewChange={() => setErrorAlreadyExists(false)}
                        />
                    ) : (
                        <Select
                            onValueChange={(value: string) => setSelectedCollection(value)}
                        >
                            <SelectTrigger
                                className={`w-full text-left border border-gray-300 rounded-lg px-4 py-2 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none ${selectedCollection === null
                                    ? "text-gray-500"
                                    : "text-gray-900"
                                    }`}
                            >
                                <SelectValue
                                    placeholder="Choose an existing collection or create a new one"
                                    className="text-left"
                                />
                            </SelectTrigger>
                            <SelectContent className="z-50 bg-white border border-gray-200 rounded-lg shadow-lg max-h-64 overflow-y-auto">
                                <SelectGroup className="flex max-h-48 flex-col justify-start gap-2 overflow-y-auto p-2 font-inter text-base font-medium text-[#101828]">
                                    {collections.map((col: any) => (
                                        <SelectItem key={col.id} value={col.name} disabled={col.isAddedToCampaign}>
                                            {col.name}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                                <div
                                    onClick={() => setShowCustomCollectionModal(true)}
                                    className="sticky bg-gray-100 flex max-h-48 flex-col justify-start gap-2 overflow-y-auto p-2 font-inter text-sm font-medium pl-[2rem] text-[#101828]"
                                >
                                    + Create new one
                                </div>
                            </SelectContent>
                        </Select>
                    )}
                </div>

                {noCreditsLeft && !showNewPaymentFlow && (
                    <div className="exportBuyMore row ai-centre jc-between mb-4">
                        <div className="row ai-centre gap8">
                            <img
                                className="sfrItemIcon"
                                src="/assets/coinsStacked.png"
                                alt="coinStacked"
                            />
                            <div className="column ai-start jc-centre">
                                <span className="gray-700 text-xs semibold">
                                    {creditsUsed - (user.credits - user.usedCredits)} more credits
                                    needed to export
                                </span>
                            </div>
                        </div>

                        <button
                            className="primaryButton"
                            onClick={() => setShowBuyCredits(true)}
                            disabled={user.plan === "Free"}
                        >
                            Buy more
                        </button>
                    </div>
                )}

                {/* {errorAlreadyExists && <div className="text-sm text-primary-600 mb-5"> {errorAlreadyExists} </div>} */}

                <div
                    className={`flex items-center ${!noCreditsLeft ? "justify-between" : "justify-end"
                        }`}
                >
                    {!noCreditsLeft && (
                        <p className="text-sm text-gray-600">
                            {`${isFreePlanWithCredits
                                ? "First 25 companies will be collected"
                                : `${creditsUsed} credits will be used.`
                                }`}{" "}
                        </p>
                    )}
                    <div className="flex gap-4">
                        <Button
                            variant="secondary"
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                            onClick={() => handleSave()}
                            disabled={isButtonDisabled || noCreditsLeft}
                        >
                            Complete
                        </Button>
                    </div>
                </div>
            </div>
            {showBuyCredits && (
                <BuyCreditsPopUp
                    close={() => setShowBuyCredits(false)}
                    editPayment={confirmEditPayment}
                    paymentMethod={paymentMethod}
                    complete={paySuccess}
                    option={{
                        credits:
                            creditsUsed - (user.credits - user.usedCredits) < 5
                                ? 5
                                : creditsUsed - (user.credits - user.usedCredits),
                        rate: 0.15,
                    }}
                    updateCredits={updateCredits}
                />
            )}
            {showUpdatePayment && (
                <UpdatePaymentPopUp
                    close={closePayment}
                    setCard={(x) => paymentMethodAdded(x)}
                    cancel={paymentCancel}
                />
            )}
            {showUpgradeEmailQuotaMOdal && <EmailQuotaModal open={showUpgradeEmailQuotaMOdal} close={() => setShowUpgradeEmailQuotaModal(false)} changePlan={handleChangePlan} />}
            {showUpgradeQutaModal && <UpgradeQuotaModal open={showUpgradeQutaModal} close={() => setShowUpgradeQuotaModal(false)} />}
        </div>
    );
};

export default SaveCollectionModal;
