import { useState, useCallback } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/shadcn/ui/dialog";
import { Input } from "components/shadcn/ui/input";
import { Button } from "components/shadcn/ui/button";
import SvgCloseIcon from "./CloseIcon";
import lang from "lang";
import { Label } from "components/shadcn/ui/label";
import SvgFolderIcon from "components/common/iconComponents/FolderIcon";

interface CampaignModalProps {
    isOpen: boolean;
    errorAlreadyExists: boolean;
    onClose: () => void;
    onSave: (name: string) => void;
    onNewChange: () => void;
}

export default function CustomNameCollectionModal({ isOpen, errorAlreadyExists, onClose, onSave }: CampaignModalProps) {
    const { collection: collectionCopy } = lang;
    //const baseURL2 = process.env.REACT_APP_BASEURL2;

    const [customCollection, setCustomCollection] = useState<string>("");

    const handleSave = useCallback(async () => {
        // try {
        //     setIsLoading(true);
        //     const createCollectionResponse = await axiosWithToken.post(
        //         `${baseURL2}api/collections`,
        //         {
        //             name: customCollection,
        //             description: "This is my custom collection.",
        //         }
        //     );

        //     if (createCollectionResponse.data) {
        //         onClose();
        //         onSave(customCollection);
        //         setIsLoading(false);
        //     }
        // } catch (error) {
        //     setIsLoading(false);
        //     if (error instanceof AxiosError && error.response?.status === 409) {
        //         setCustomCollectionError("Collection name already exists. Please try another.");
        //     } else {
        //         console.error("Error creating custom collection:", error);
        //     }
        //     return;
        // }
        onSave(customCollection);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customCollection]);

    const handleCancel = () => {
        onClose();
    }


    return (
        <>
            <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
                <DialogContent className="min-w-[40%] z-[9999] rounded-[32px] bg-[#F2F4F7]">
                    <div className="flex flex-row align-center justify-between">
                        <SvgFolderIcon />
                        <button onClick={onClose}><SvgCloseIcon /></button>
                    </div>
                    <DialogHeader className="flex flex-row items-center justify-between">
                        <DialogTitle className="text-lg font-semibold text-gray-950">{collectionCopy.collectionName}</DialogTitle>
                    </DialogHeader>

                    <div className="border border-[#F2F4F7] bg-white p-6 rounded-3xl flex flex-col gap-3">
                        <Label>{collectionCopy.enterNameofCollection}</Label>
                        <Input value={customCollection} onChange={(e) => setCustomCollection(e.target.value)} placeholder="Enter new collection name" className="w-full" />
                        {errorAlreadyExists && <div className="text-sm text-primary-600 mb-5">Collection name already exists. Please try another.</div>}
                    </div>

                    <div className="flex justify-end gap-4">
                        <Button variant="outline" onClick={handleCancel}>
                            {collectionCopy.cancel}
                        </Button>
                        <Button onClick={() => handleSave()} variant="primary" disabled={!customCollection} className="bg-[#E6007A] hover:bg-[#E6007A]/90 text-white">
                            {collectionCopy.save}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
