

import * as React from 'react';
import { SVGProps } from 'react';
const SvgMinusCircularIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="63" height="39" rx="19.5" fill="white" />
        <rect x="0.5" y="0.5" width="63" height="39" rx="19.5" stroke="url(#paint0_linear_2974_12324)" />
        <path d="M25 20H39" stroke="#FF008A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <defs>
            <linearGradient id="paint0_linear_2974_12324" x1="0" y1="0" x2="64" y2="-1.06743e-08" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B8055E" />
                <stop offset="1" stopColor="#FF3A64" />
            </linearGradient>
        </defs>
    </svg>

);
export default SvgMinusCircularIcon;
