import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserFeatureQuota = {
  id: string | null;
  expiresAt: string | null;
  featureId: string | null;
  usedQuota: number | null;
  totalQuota: number | null;
  featureName: string | null;
  remainingQuota: number | null;
};

type ProductPricing = {
  id: string;
  price: number;
  currency: string;
  interval: string;
};

type SubscriptionDetails = {
  id: string;
  userId: string;
  status: string;
  daysUntilDue: number | null;
  startDate: string;
  endedAt: string | null;
  trialStart: string;
  trialEnd: string;
  productName: string;
  productPricing: ProductPricing;
  userFeatureQuotas: UserFeatureQuota[];
};

type Feature = {
  id: string;
  name: string;
  capacity: number | null;
  canHighlight: boolean | null;
};

type SubscriptionPlan = {
  id: string;
  name: string;
  description: string;
  isActive: number;
  createdAt: string;
  features: Feature[];
  productPricingId: string;
  price: number;
  pricingInterval: string;
};

interface ProductTopUp {
  id: string;
  name: string;
  description: string;
  isActive: number;
  createdAt: string;
  productPricingId: string;
  price: number;
  quota: number;
  feature: string;
}

interface SubscriptionState {
  currentSubscription: SubscriptionDetails;
  productPlan: SubscriptionPlan[];
  productTopup: ProductTopUp[];
}

const initialState: SubscriptionState = {
  currentSubscription: {
    id: "",
    userId: "",
    status: "trialing",
    daysUntilDue: null,
    startDate: "",
    endedAt: null,
    trialStart: "",
    trialEnd: "",
    productName: "",
    productPricing: {
      id: "",
      price: 0,
      currency: "",
      interval: "month",
    },
    userFeatureQuotas: [],
  },
  productPlan: [],
  productTopup: [],
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updateCurrentSubscription: (
      state,
      action: PayloadAction<SubscriptionDetails>
    ) => {
      state.currentSubscription = action.payload;
    },
    setProductPlan: (state, action: PayloadAction<SubscriptionPlan[]>) => {
      state.productPlan = action.payload;
    },
    setTopUpProduct: (state, action: PayloadAction<ProductTopUp[]>) => {
      state.productTopup = action.payload;
    },
  },
});

export const selectCurrentSubscription = (state: {
  subscription: SubscriptionState;
}) => state.subscription.currentSubscription;

export const selectProductPlans = (state: {
  subscription: SubscriptionState;
}) => state.subscription.productPlan;

export const selectProductTopup = (state: {
  subscription: SubscriptionState;
}) => state.subscription.productTopup;

export const { updateCurrentSubscription, setProductPlan, setTopUpProduct } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
