import * as React from 'react';
import { SVGProps } from 'react';
const SvgCollectionIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.22485 6.5C4.22485 5.09987 4.22485 4.3998 4.49734 3.86502C4.73702 3.39462 5.11947 3.01217 5.58988 2.77248C6.12466 2.5 6.82472 2.5 8.22485 2.5H11.8915C13.2917 2.5 13.9917 2.5 14.5265 2.77248C14.9969 3.01217 15.3794 3.39462 15.619 3.86502C15.8915 4.3998 15.8915 5.09987 15.8915 6.5V17.5L10.0582 14.1667L4.22485 17.5V6.5Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


);
export default SvgCollectionIcon;
