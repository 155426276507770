import "../styling/header.css";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderPopUp from "./popUps/HeaderPopUp";
import { auth } from "..";
import { ParentContext } from "./constants/ParentContext";
import PricingPlanSelect from "./Pricing/PricingPlanSelect";
import ConfirmPlanPopUp from "./Billing/ConfirmPlanPopUp";
import UpdatePaymentPopUp from "./Billing/UpdatePaymentPopUp";
import { Mixpanel } from "./Mixpanel/Mixpanel";
import { bizCrunchSiteUrl, fundMyDealUrl, showCampaignFeature, showNewPaymentFlow, showOutreachFeature } from "./utils/network/endpoints";
import axiosWithToken from "axiosWithToken";
import { useDispatch, useSelector } from "react-redux";
import { resetCampaignState } from "./campaign/campaignSlice";
import { Progress } from "./shadcn/ui/progress";
import { selectCurrentSubscription } from "./subscription/subscriptionSlice";
import { SUBSCRIPTION_STATUS } from "./subscription/types";

interface HeaderProps {
  updateCredits: any;
  loggedIn: any;
  user: any;
  creditsP: any;
  saveToast: any;
  showChoosePlanP: any;
}

const Header: React.FC<HeaderProps> = ({
  updateCredits,
  loggedIn,
  user,
  creditsP,
  saveToast,
  showChoosePlanP,
}) => {
  // #region CONSTANTS & STATE VARIABLES
  const navigate = useNavigate();
  const location = useLocation();

  const [initials, setInitials] = useState("");
  const [credits, setCredits] = useState(creditsP);

  const [userVar, setUserVar] = useState(user);

  const [selected, setSelected] = useState<any | null>(null);

  const [showPopUp, setShowPopUp] = useState(false);

  const [endpoint, setEndpoint] = useState("");

  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [showConfirmPlan, setShowConfirmPlan] = useState(false);
  const [showBuyCredits, setShowBuyCredits] = useState(false);
  const [addingCredit, setAddingCredit] = useState<any>(null);

  const [plan, setPlan] = useState(null);
  const [plans, setPlans] = useState(null);

  const [subscription, setSubscription] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [newPlan, setNewPlan] = useState<any>(null);
  const context = useContext<any>(ParentContext);

  const currentSubscription = useSelector(selectCurrentSubscription);
  const dispatch = useDispatch();
  let theme = "bizcrunch";

  if (context.isHarbour) {
    theme = "harbour";
  }

  if (context.isBiz4Biz) {
    theme = "biz4biz";
  }

  // const isBiz = useContext(ParentContext);
  const baseURL2 = process.env.REACT_APP_BASEURL2;

  // #endregion

  useEffect(() => {
    setEndpoint(window.location?.pathname);
  }, [window.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user !== null) {
      let name = user.name;

      let initialsArray: any = [];

      let split = name.split(" ");
      initialsArray.push(split[0][0]);
      if (split.length > 1) {
        initialsArray.push(split[1][0]);
      }

      setInitials(initialsArray.join(""));
      setCredits(creditsP);
      setUserVar(user);
      let stripeCustomerID = user.stripeCustomerID;
      if (auth.currentUser?.emailVerified) {
        getCustomerData(stripeCustomerID);
        getPlanData(user.plan, user.uid);
      }
      // setUser(user)
    } else {
      setInitials("");
      setCredits("");
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCredits(creditsP);
  }, [creditsP]);

  useEffect(() => {
    setShowChoosePlan(showChoosePlanP);
  }, [showChoosePlanP]);


  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setSelected("search");
        break;
      case "/exports":
        setSelected("exports");
        break;
      case "/savedFilters":
        setSelected("saved");
        break;
      case "/billing":
        setSelected("billing");
        break;
      case "/campaignLanding":
        setSelected("campaign");
        break;
      case "/collections":
        setSelected("collections");
        break;

      default:
        break;
    }
  }, [location]);

  // #region SHOW COMPONENTS
  const planChosen = (plan: any) => {
    setNewPlan(plan);
    setShowChoosePlan(false);
    if (paymentMethod === null) {
      setShowUpdatePayment(true);
    } else {
      setShowChoosePlan(false);
      setShowConfirmPlan(true);
    }
  };

  const getCustomerData = async (cusID: any) => {
    try {
      const response = await axiosWithToken.get(`${baseURL2}api/stripe/customer-data`);

      setPaymentMethod(response.data.paymentMethod);
      localStorage.setItem("paymentMethod", JSON.stringify(response.data.paymentMethod));

      setSubscription(response.data.subscription || null);

      let buyCredits = localStorage.getItem("buyCredits");

      if (buyCredits === "true") {
        setShowBuyCredits(true);
        localStorage.setItem("buyCredits", "false");
      }

    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const getPlanData = async (plan: any, uid: any) => {
    try {
      const response = await axiosWithToken.get(`${baseURL2}api/stripe/plans/v2`);

      const plans = response.data;
      setPlans(plans);

      plans?.forEach((planVar: any) => {
        if (planVar.id === plan) {
          setPlan(planVar);
        }
      });

      let upgradePlan = localStorage.getItem("upgradePlan");
      if (upgradePlan === "true") {
        setShowChoosePlan(true);
        localStorage.setItem("upgradePlan", "false");
      }
    } catch (error) {
      console.error("Error fetching plan data:", error);
    }
  };

  const newPlanAdded = (sub: any) => {

    setShowConfirmPlan(false);

    saveToast("Plan Updated");

    setPlan(newPlan);

    user.usedCredits = 0;
    user.credits = newPlan.Credits;
    user.plan = newPlan.id;
    localStorage.setItem("user", JSON.stringify(user));
    updateCredits(newPlan.Credits);
    setNewPlan(null);
    setSubscription(sub);
  };

  const confirmEditPayment = () => {
    setShowConfirmPlan(false);
    setShowBuyCredits(false);
    setShowUpdatePayment(true);
  };

  const closePayment = () => {
    setNewPlan(null);
    setAddingCredit(false);
    setShowUpdatePayment(false);
  };

  const paymentCancel = () => {
    setShowUpdatePayment(false);

    if (paymentMethod === null) {
      setShowChoosePlan(true);
    } else if (newPlan !== null) {
      setShowConfirmPlan(true);
    } else if (addingCredit) {
      setShowBuyCredits(true);
    }
  };

  useEffect(() => {
    if (showBuyCredits && !addingCredit) {
      setAddingCredit(true);
    }
  }, [showBuyCredits]); // eslint-disable-line react-hooks/exhaustive-deps

  const paymentMethodAdded = (pm: any) => {
    setPaymentMethod(pm);
    paymentCancel();
  };
  // #endregion

  // #region WEB REQUESTS

  // #endregion

  // #region BUTTONS CLICKED

  const goHome = () => {
    if (window.location.pathname === "/login") {
      if (theme === "bizcrunch") {
        window.open(bizCrunchSiteUrl, "_self");
      }

    } else {
      navigate(`../`, { replace: true });
      setSelected("search");
    }
  };

  const goExports = () => {

    Mixpanel.track("FeatureUsage - Collections", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User ID": user?.uid,
      "Date": new Date().toISOString(),
      "User Type": "Buyer",
      "Feature": "Visit collections Screen"
    });

    const targetPath = showOutreachFeature ? '/collections' : '/exports';
    navigate(targetPath, { replace: true });
    setSelected(showOutreachFeature ? "collections" : "exports");
  };
  const goAdmin = () => {
    navigate(`../admin`, { replace: true });
    setSelected("admin");
  };

  const goSaved = () => {

    Mixpanel.track("FeatureUsage", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User ID": user?.uid,
      "Date": new Date().toISOString(),
      "User Type": "Buyer",
      "Feature": "Visit Saved Filters Screen"
    });

    navigate(`../savedFilters`, { replace: true });
    setSelected("saved");

  };

  const navigateToCampaign = () => {

    Mixpanel.track("FeatureUsage", {
      $name: user?.name,
      $email: user?.email,
      "Plan": user?.plan,
      "User ID": user?.uid,
      "Date": new Date().toISOString(),
      "User Type": "Buyer",
      "Feature": "Visit Campaign Screen"
    });
    dispatch(resetCampaignState());
    navigate(`../campaign`, { replace: true });
    setSelected("campaign");

  };

  const goFund = () => {
    if (plan !== "free") {
      Mixpanel.track("FeatureUsage", {
        $name: user?.name,
        $email: user?.email,
        "Plan": user?.plan,
        "User ID": user?.uid,
        "Date": new Date().toISOString(),
        "User Type": "Buyer",
        "Feature": "Visit Fund My Deal Screen"
      });

      window.open(fundMyDealUrl, "_blank", "noopener,noreferrer");
    }
  };


  const login = () => {
    navigate(`../login`, { replace: true });
  };

  const goDealTracker = () => {
    navigate(`../deals`, { replace: true });
    setSelected("deal");
  };

  const renderProgressBars = () => {
    return (
      <div className="flex flex-row gap-4">
        {currentSubscription?.userFeatureQuotas?.map((quota) => {
          const progress = (quota?.usedQuota && quota?.totalQuota) ? (quota.usedQuota / quota.totalQuota) * 100 : 0;
          return (
            <div key={quota.id} className="flex flex-col bg-gray-100 rounded-[8px] p-2">
              <span className="text-gray-700 text-xs font-normal">
                {quota.featureName} {currentSubscription.status === SUBSCRIPTION_STATUS.TRAILING && " (Trial)"}:
                <span className="font-bold"> {quota.usedQuota}/{quota.totalQuota}</span>
              </span>
              <Progress value={progress} className="mt-2 bg-white h-2 #A11043" />
            </div>
          );
        })}
      </div>
    );
  };

  // #endregion

  // #region OTHER

  // #endregion

  return (
    <div className="header">
      <div className="headerLeftSection">
        <img
          className="logoImg"
          src={
            theme === "bizcrunch"
              ? "/assets/logo.png"
              : theme === "harbour"
                ? "/assets/logoHC.png"
                : "/assets/logoB4B.jpg"
          }
          onClick={goHome}
          alt="logo"
        />
        {endpoint !== "/login" && (
          <div
            className={`headerLink ${selected === "search" ? "selected" : ""
              } text-sm semibold gray-900`}
            onClick={goHome}
          >
            Search
          </div>
        )}
        {loggedIn && userVar && (
          <div
            className={`headerLink ${selected === "exports" || selected === "collections" ? "selected" : ""}
              } text-sm semibold gray-900`}
            onClick={goExports}
          >
            {showOutreachFeature ? "Collections" : "Exports"}
          </div>
        )}

        {loggedIn && userVar && showOutreachFeature && showCampaignFeature && (
          <div
            className={`headerLink ${selected === "campaign" ? "selected" : ""
              } text-sm semibold gray-900`}
            onClick={navigateToCampaign}
          >
            Campaign
          </div>
        )}

        {loggedIn && userVar && (
          <div
            className={`headerLink ${selected === "saved" ? "selected" : ""
              } text-sm semibold gray-900`}
            onClick={goSaved}
          >
            Saved filters
          </div>
        )}

        {loggedIn && userVar && (
          <div
            className={`${userVar.plan !== "free"
              ? "headerLink gray-900"
              : "gray-400 cursorDefault"
              } ${selected === "fundMyDeal" ? "selected" : ""
              } text-sm semibold tooltip`}
            onClick={goFund}
          >
            <span>Fund My Deal</span>
            {userVar.plan === "free" && (
              <span className="tooltiptext header text-sm regular">
                You must be on a plan to use this feature
              </span>
            )}
          </div>
        )}

        {loggedIn && userVar && showOutreachFeature && showCampaignFeature && (
          <div
            className={`headerLink ${selected === "deal" ? "selected" : ""
              } text-sm semibold gray-900`}
            onClick={goDealTracker}
          >
            Deal Tracker
          </div>
        )}

        {loggedIn && user?.isAdmin && (
          <div
            className={`headerLink ${selected === "admin" ? "selected" : ""
              } text-sm semibold gray-900`}
            onClick={goAdmin}
          >
            Admin
          </div>
        )}
      </div>

      {!loggedIn && (
        <button className="secondaryButton" onClick={login}>
          Login
        </button>
      )}
      {loggedIn && !userVar && (
        <span className="text-sm">Loading account...</span>
      )}
      {loggedIn && userVar && (
        <div className="column ai-start">
          <div className="headerLoggedIn">
            {showNewPaymentFlow && currentSubscription && userVar?.isSubscribed && renderProgressBars()}
            {!showNewPaymentFlow && <div className="headerCredits">{credits} Credits</div>}
            <div
              className="headerName pointer"
              onClick={() => setShowPopUp(!showPopUp)}
            >
              {initials}
            </div>

            {showPopUp && (
              <HeaderPopUp user={user} close={() => setShowPopUp(false)} />
            )}
          </div>
          {!showNewPaymentFlow && window.location.pathname !== "/billing" && (
            <div
              className="headerLink text-xs primary-700 semibold pointer"
              onClick={() => setShowChoosePlan(true)}
            >
              Upgrade plan
            </div>
          )}
        </div>
      )}

      {showUpdatePayment && (
        <UpdatePaymentPopUp
          close={closePayment}
          setCard={(x) => paymentMethodAdded(x)}
          cancel={paymentCancel}
        />
      )}
      {showChoosePlan && (
        <PricingPlanSelect
          close={() => setShowChoosePlan(false)}
          purchasePlan={(x: any) => planChosen(x)}
          plans={plans}
          planChoice={plan}
        />
      )}
      {showConfirmPlan && (
        <ConfirmPlanPopUp
          close={() => setShowConfirmPlan(false)}
          plan={newPlan}
          dismissNewPlan={() => setNewPlan(null)}
          paymentMethod={paymentMethod}
          editPayment={confirmEditPayment}
          currentSubscription={subscription}
          complete={newPlanAdded}
        />
      )}
    </div>
  );
}

export default Header;