import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateCampaignResponseDto } from "./types";

interface CampaignState {
  currentStep: number;
  selectedCollectionIds: string[];
  selectAllForUncheck: boolean;
  campaignType?: string;
  campaignName: string;
  createCampaignResponse?: CreateCampaignResponseDto;
  emailTemplateData: EmailTemplate[];
  mailBoxStates: any;
  campaignListWithDetails: any;
  customizations: Customization;
  campaignDetailRecipients: CampaignDetailRecipients;
  campaignId: string;
  domainSettings: any;
}

type Recipient = {
  email: string;
  lastName: string;
  statuses: string[];
  firstName: string;
  emailsSent: number;
  companyName: string | null;
};

type CampaignDetailRecipients = {
  campaignId: string;
  campaignName: string;
  totalRecipients: number;
  totalEmails: number;
  totalEmailsSent: number;
  recipients: Recipient[];
};
interface EmailTemplate {
  body: string;
  name: string;
  sendAfterDays: number;
  subject: string;
}
interface Customization {
  holdingCompany: string;
  phoneNumber: string;
  website: string;
}

interface MailboxState {
  burnerDomains: string[];
  mailboxes: string[];
  replyTo: string;
  preferredDomain: string;
  domain?: string;
}

const initialState: CampaignState = {
  currentStep: 1,
  campaignId: "",
  selectedCollectionIds: [],
  selectAllForUncheck: false,
  campaignType: "email",
  campaignName: "New Campaign",
  createCampaignResponse: undefined,
  emailTemplateData: [],
  mailBoxStates: {
    replyTo: "",
    preferredDomain: "",
  },
  campaignListWithDetails: [],
  customizations: {
    holdingCompany: "",
    phoneNumber: "",
    website: "",
  },
  campaignDetailRecipients: {
    campaignId: "",
    campaignName: "",
    totalRecipients: 0,
    totalEmails: 0,
    totalEmailsSent: 0,
    recipients: [],
  },
  domainSettings: null,
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    nextStep: (state) => {
      if (state.currentStep < 6) state.currentStep += 1;
    },
    prevStep: (state) => {
      if (state.currentStep > 1) state.currentStep -= 1;
    },
    resetStep: (state) => {
      state.currentStep = 1;
    },

    setCampaignType: (state, action: PayloadAction<string>) => {
      state.campaignType = action.payload;
    },
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.campaignName = action.payload;
    },
    setSelectedCollectionIds: (state, action: PayloadAction<string[]>) => {
      state.selectedCollectionIds = action.payload;
    },
    toggleCollectionId: (state, action: PayloadAction<string>) => {
      const index = state.selectedCollectionIds?.indexOf(action.payload);
      if (index >= 0) {
        state.selectedCollectionIds.splice(index, 1);
      } else {
        state.selectedCollectionIds.push(action.payload);
      }
    },
    setSelectAllClickedForUncheck: (state, action: PayloadAction<boolean>) => {
      state.selectAllForUncheck = action.payload;
    },
    setCreateCampaignResponse: (
      state,
      action: PayloadAction<CreateCampaignResponseDto>
    ) => {
      state.createCampaignResponse = action.payload;
    },
    setEmailTemplates: (state, action: PayloadAction<EmailTemplate[]>) => {
      state.emailTemplateData = action.payload;
    },
    setCustomizations: (state, action: PayloadAction<Customization>) => {
      state.customizations = action.payload;
    },

    updateMailboxData: (
      state,
      action: PayloadAction<Partial<MailboxState>>
    ) => {
      const { domain, ...restOfPayload } = action.payload;
      state.mailBoxStates = {
        ...state.mailBoxStates,
        ...restOfPayload,
      };
    },
    resetCampaignState: () => initialState,
    setCampaignListWithDetails: (state, action: PayloadAction<any>) => {
      state.campaignListWithDetails = action.payload;
    },
    setCampaignDetailsRecipients: (
      state,
      action: PayloadAction<CampaignDetailRecipients>
    ) => {
      state.campaignDetailRecipients = action.payload;
    },
    setCampaignId: (state, action: PayloadAction<string>) => {
      state.campaignId = action.payload;
    },
    setDomainSettings: (state, action: PayloadAction<any>) => {
      state.domainSettings = action.payload[0];
    },
  },
});

export const {
  updateCurrentStep,
  nextStep,
  prevStep,
  setSelectedCollectionIds,
  toggleCollectionId,
  resetStep,
  setSelectAllClickedForUncheck,
  setCampaignType,
  setCampaignName,
  setCreateCampaignResponse,
  setEmailTemplates,
  updateMailboxData,
  resetCampaignState,
  setCampaignListWithDetails,
  setCustomizations,
  setCampaignDetailsRecipients,
  setCampaignId,
  setDomainSettings,
} = campaignSlice.actions;

export default campaignSlice.reducer;

export const selectedCollection = (state: { campaign: CampaignState }) =>
  state.campaign.selectedCollectionIds;

export const selectSelectAllForUncheck = (state: { campaign: CampaignState }) =>
  state.campaign.selectAllForUncheck;

export const selectCampaignType = (state: { campaign: CampaignState }) =>
  state.campaign.campaignType;

export const selectCampaignName = (state: { campaign: CampaignState }) =>
  state.campaign.campaignName;

export const selectCurrentStep = (state: { campaign: CampaignState }) =>
  state.campaign.currentStep;

export const selectCreateCampaignResponse = (state: {
  campaign: CampaignState;
}) => state.campaign.createCampaignResponse;

export const selectEmailTemplate = (state: { campaign: CampaignState }) =>
  state.campaign.emailTemplateData;

export const selectMailboxData = (state: { campaign: CampaignState }) =>
  state.campaign.mailBoxStates;

export const selectCampaignList = (state: { campaign: CampaignState }) =>
  state.campaign.campaignListWithDetails;

export const selectCustomizations = (state: { campaign: CampaignState }) => {
  return state.campaign.customizations;
};

export const selectCampaignDetailRecipients = (state: {
  campaign: CampaignState;
}) => {
  return state.campaign.campaignDetailRecipients;
};
export const selectCampaignId = (state: { campaign: CampaignState }) => {
  return state.campaign.campaignId;
};

export const selectDomainSettings = (state: { campaign: CampaignState }) => {
  return state.campaign.domainSettings;
};
