export const initialColumns = {
  replied: {
    title: "Replied",
    tasks: [
      {
        id: "1",
        title: "Follow up with client",
        companyName: "Acme Corp",
        companyNumber: "123456",
        description: "Send a follow-up email",
        keyContactName: "John Doe",
        keyContactEmail: "john.doe@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "2",
        title: "Send additional information",
        companyName: "Beta Inc",
        companyNumber: "789012",
        description: "Prepare and send additional details",
        keyContactName: "Jane Smith",
        keyContactEmail: "jane.smith@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  research: {
    title: "Research",
    tasks: [
      {
        id: "3",
        title: "Analyze market trends",
        companyName: "Gamma Ltd",
        companyNumber: "345678",
        description: "Research current market trends",
        keyContactName: "David Lee",
        keyContactEmail: "david.lee@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "4",
        title: "Gather competitor data",
        companyName: "Delta Co",
        companyNumber: "901234",
        description: "Collect data on competitors",
        keyContactName: "Sarah Johnson",
        keyContactEmail: "sarah.johnson@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  offerSent: {
    title: "Offer Sent",
    tasks: [
      {
        id: "5",
        title: "Confirm offer receipt",
        companyName: "Epsilon LLC",
        companyNumber: "567890",
        description: "Check if the offer was received",
        keyContactName: "Michael Brown",
        keyContactEmail: "michael.brown@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "6",
        title: "Negotiate terms",
        companyName: "Zeta Corp",
        companyNumber: "123987",
        description: "Negotiate the terms of the offer",
        keyContactName: "Linda Davis",
        keyContactEmail: "linda.davis@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  offerAccepted: {
    title: "Offer Accepted",
    tasks: [
      {
        id: "7",
        title: "Draft initial agreement",
        companyName: "Eta Inc",
        companyNumber: "654321",
        description: "Draft the initial agreement",
        keyContactName: "James Wilson",
        keyContactEmail: "james.wilson@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "8",
        title: "Schedule due diligence",
        companyName: "Theta Ltd",
        companyNumber: "789456",
        description: "Schedule the due diligence process",
        keyContactName: "Karen Garcia",
        keyContactEmail: "karen.garcia@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  inDueDiligence: {
    title: "In Due Diligence",
    tasks: [
      {
        id: "9",
        title: "Review financial records",
        companyName: "Iota Co",
        companyNumber: "321654",
        description: "Review all financial records",
        keyContactName: "Robert Rodriguez",
        keyContactEmail: "robert.rodriguez@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "10",
        title: "Conduct property inspection",
        companyName: "Kappa LLC",
        companyNumber: "987654",
        description: "Inspect the property",
        keyContactName: "Angela Martinez",
        keyContactEmail: "angela.martinez@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  spaAgreed: {
    title: "SPA Agreed",
    tasks: [
      {
        id: "11",
        title: "Prepare SPA document",
        companyName: "Lambda Corp",
        companyNumber: "456789",
        description: "Prepare the SPA document",
        keyContactName: "Christopher Anderson",
        keyContactEmail: "christopher.anderson@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "12",
        title: "Verify legal compliance",
        companyName: "Mu Inc",
        companyNumber: "654987",
        description: "Verify legal compliance",
        keyContactName: "Jessica Thomas",
        keyContactEmail: "jessica.thomas@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  inEscrow: {
    title: "In Escrow",
    tasks: [
      {
        id: "13",
        title: "Deposit funds in escrow",
        companyName: "Nu Ltd",
        companyNumber: "321456",
        description: "Deposit funds in escrow",
        keyContactName: "Brandon Jackson",
        keyContactEmail: "brandon.jackson@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "14",
        title: "Confirm escrow conditions",
        companyName: "Xi Co",
        companyNumber: "987321",
        description: "Confirm escrow conditions",
        keyContactName: "Ashley White",
        keyContactEmail: "ashley.white@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  completed: {
    title: "Completed",
    tasks: [
      {
        id: "15",
        title: "Finalize transaction",
        companyName: "Omicron LLC",
        companyNumber: "456123",
        description: "Finalize the transaction",
        keyContactName: "Justin Harris",
        keyContactEmail: "justin.harris@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "16",
        title: "Send confirmation to client",
        companyName: "Pi Corp",
        companyNumber: "654789",
        description: "Send confirmation to client",
        keyContactName: "Amanda Martin",
        keyContactEmail: "amanda.martin@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
  closedLost: {
    title: "Closed Lost",
    tasks: [
      {
        id: "17",
        title: "Document reasons for loss",
        companyName: "Rho Inc",
        companyNumber: "321789",
        description: "Document reasons for loss",
        keyContactName: "Ryan Thompson",
        keyContactEmail: "ryan.thompson@example.com",
        isImportant: true,
        campaignDate: new Date().toISOString().split("T")[0],
      },
      {
        id: "18",
        title: "Follow up for feedback",
        companyName: "Sigma Ltd",
        companyNumber: "987123",
        description: "Follow up for feedback",
        keyContactName: "Emily Garcia",
        keyContactEmail: "emily.garcia@example.com",
        isImportant: false,
        campaignDate: new Date().toISOString().split("T")[0],
      },
    ],
  },
};
