import * as React from "react";
import {
    type ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    type SortingState,
} from "@tanstack/react-table";
import { Button } from "components/shadcn/ui/button";
import { Badge } from "components/shadcn/ui/badge";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/shadcn/ui/table";
import { ChevronsUpDown } from "lucide-react";
import { CampaignList, STATUS } from "./types";
import SvgPencilIcon from "components/common/iconComponents/pencilIcon";
import { formatDate } from "helpers";
import { useNavigate } from "react-router-dom";
import { getCampaignRecipientsDetails, getIndividualCampignDetails } from "./services";
import { useDispatch, useSelector } from "react-redux";
import { selectEmailTemplate, setCampaignDetailsRecipients, setCampaignName, setCampaignType, setEmailTemplates, setSelectedCollectionIds, updateCurrentStep } from "./campaignSlice";
import Loader from "components/common/loader";
import SvgWarmingUpIcon from "components/common/iconComponents/warmingUpIcon";
import SvgCheckGreenIcon from "components/common/iconComponents/checkGreenIcon";
import { Input } from "components/shadcn/ui/input";

type CampaignsTableProps = {
    campaignList: CampaignList[];
};

export function CampaignsTable({ campaignList }: CampaignsTableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([{ id: "createdAt", desc: true }]);
    const [isLoding, setisLoding] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const defaultEmailTemplateData = useSelector(selectEmailTemplate);

    const handleCampaignClick = async (campaignId: string) => {
        if (isLoding) return; // Prevent multiple calls
        setisLoding(true);

        try {
            const campaignDetails = await getIndividualCampignDetails(campaignId);

            if (!campaignDetails) return;

            if (campaignDetails.status === STATUS.DRAFT) {
                handleDraftCampaign(campaignDetails, campaignId);
            } else {
                await handleActiveCampaign(campaignId, campaignDetails);
            }
        } catch (error) {
            console.error("Error fetching campaign details:", error);
        } finally {
            setTimeout(() => setisLoding(false), 500);
        }
    };

    const handleDraftCampaign = (campaignDetails: any, campaignId: string) => {
        dispatch(updateCurrentStep(campaignDetails.currentStep));
        dispatch(setCampaignType('email'));
        dispatch(setCampaignName(campaignDetails.name));

        if (campaignDetails.collections?.length > 0) {
            dispatch(setSelectedCollectionIds(campaignDetails.collections.map((collection: any) => collection.id)));
        }

        const emailTemplates = (campaignDetails?.messages ?? defaultEmailTemplateData).map((message: any, index: number) => {
            let name = "";
            if (index === 0) name = "Initial Contact";
            else if (index === 1) name = "Follow Up";
            else if (index === 2) name = "Final Outreach";
            return { ...message, name };
        });
        dispatch(setEmailTemplates(emailTemplates));

        navigate(`../campaignFlow`, { state: { fromDraftState: true, campaignId } });
    };

    // Handle active campaigns
    const handleActiveCampaign = async (campaignId: string, campaignDetails: any) => {
        if (campaignDetails.status !== STATUS.ERROR && campaignDetails.status !== STATUS.COMPLETED && campaignDetails.status !== STATUS.PAUSED) {
            const campaignRecipientsDetails = await getCampaignRecipientsDetails(campaignId);

            if (campaignRecipientsDetails) {
                dispatch(setCampaignDetailsRecipients(campaignRecipientsDetails));
                if (campaignDetails.status === STATUS.WARMING)
                    navigate(`../campaignDetails`, {
                        state: { campaignWarmingUp: true },
                    });
                else {
                    navigate(`../campaignDetails`);

                }
            }
        };
    }

    const filteredData = React.useMemo(() => {
        return campaignList.filter((campaign) =>
            campaign.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [campaignList, searchQuery]);

    const columns: ColumnDef<CampaignList>[] = [
        {
            accessorKey: "name",
            header: ({ column }) => {
                return (
                    <Button
                        variant="custom"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                        className="flex items-center pl-0"
                    >
                        Name
                        {column.getIsSorted() === "asc" ? (
                            <ChevronsUpDown className="ml-2 h-4 w-4" />
                        ) : column.getIsSorted() === "desc" ? (
                            <ChevronsUpDown className="ml-2 h-4 w-4" />
                        ) : (
                            <ChevronsUpDown className="ml-2 h-4 w-4" />
                        )}
                    </Button>
                );
            },
        },
        {
            accessorKey: "status",
            header: "Status",
            cell: ({ row }) => {
                const status = row.getValue("status") as keyof typeof statusConfig;

                const statusConfig: Record<string, { bg: string; text: string; icon: JSX.Element | null; label: string }> = {
                    sent: { bg: "bg-green-100", text: "text-green-800", icon: <SvgCheckGreenIcon />, label: "Sent" },
                    draft: { bg: "bg-blue-100", text: "text-blue-800", icon: <SvgPencilIcon />, label: "Draft" },
                    warming: { bg: "bg-pink-100", text: "text-pink-700", icon: <SvgWarmingUpIcon />, label: "Warming up" },
                    running: { bg: "bg-green-100", text: "text-green-800", icon: <SvgCheckGreenIcon />, label: "Running" },
                    paused: { bg: "bg-gray-100", text: "text-gray-800", icon: <SvgCheckGreenIcon />, label: "Paused" },
                    completed: { bg: "bg-purple-100", text: "text-purple-800", icon: <SvgCheckGreenIcon />, label: "Completed" },
                    error: { bg: "bg-red-100", text: "text-red-800", icon: <SvgCheckGreenIcon />, label: "Error" },
                };

                const { bg, text, icon, label } = statusConfig[status] || {
                    bg: "bg-pink-100",
                    text: "text-pink-700",
                    icon: null,
                    label: "Unknown",
                };

                return (
                    <Badge variant="secondary" className={`flex flex-row gap-1 w-[fit-content] ${bg} ${text}`}>
                        {icon}
                        {label}
                    </Badge>
                );
            },
        },

        {
            accessorKey: "recipients",
            header: "Recipients",
        },
        {
            accessorKey: "type",
            header: "Type",
            cell: ({ row }) => {
                const type = row.getValue("type") as string;
                return type.charAt(0).toUpperCase() + type.slice(1);
            },
        },
        // {
        //     accessorKey: "replies",
        //     header: "Replies",
        //     cell: ({ row }) => {
        //         const replies = row.getValue("replies") as string;
        //         return (
        //             <Badge
        //                 variant="secondary"
        //                 className={replies?.includes("No") ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800"}
        //             >
        //                 {replies}
        //             </Badge>
        //         );
        //     },
        // },
        {
            accessorKey: "createdAt",
            header: "Created date",
            cell: ({ row }) => {
                const date = row.getValue("createdAt") as string;
                return <div>{formatDate(date)}</div>;
            },
        },
    ];

    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    });

    return (
        <div className="space-y-4 w-full">
            {isLoding && <Loader />}
            <div className="rounded-md border">
                <Table className="w-full">
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id} className="text-sm text-gray-600">
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableCell colSpan={columns.length} className="p-2">
                                <Input
                                    className="w-full"
                                    placeholder="Search by campaign name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} className="text-left">
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            className={
                                                cell.column.id === "name" && ["running", "warming", "draft"].includes(row.original.status)
                                                    ? "text-sm font-medium text-gray-900 hover:text-purple-800 hover:underline cursor-pointer"
                                                    : "text-sm font-medium text-gray-900"
                                            }
                                            onClick={
                                                cell.column.id === "name" && ["running", "warming", "draft"].includes(row.original.status)
                                                    ? () => handleCampaignClick(row.original.id)
                                                    : undefined
                                            }
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No campaign found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

export default CampaignsTable;