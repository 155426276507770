import { useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "components/shadcn/ui/tabs";
import { Card, CardContent } from "components/shadcn/ui/card";
import clsx from "clsx";
import UserProfile from "./userProfile";
import BurnerDomainsTable from "./burnerDomainTable";
import lang from "lang";

export default function Admin() {
    const { admin: adminCopy } = lang;
    const [activeTab, setActiveTab] = useState(adminCopy.profile);

    const UserProfileTab = () => (
        <Card>
            <CardContent className="p-4">
                <UserProfile />
            </CardContent>
        </Card>
    );

    const BurnerDomainsTab = () => (
        <Card>
            <CardContent className="p-4">
                <h2 className="text-left text-xl font-semibold">{adminCopy.burnerDomains}</h2>
                <BurnerDomainsTable />
            </CardContent>
        </Card>
    );

    return (
        <div className="fullScreen">
            <div className="container">
                <div className="savedFiltersScreen">
                    <div className="sfTitles flex flex-col gap-5 justify-between">
                        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                            <TabsList className="flex space-x-4 p-2 bg-white rounded-lg">
                                {adminCopy.tabOptions.map(({ value, label }) => (
                                    <TabsTrigger
                                        key={value}
                                        value={value}
                                        className={clsx("height-[40px]", activeTab === value ? "text-primary-700" : "text-gray-500")}
                                    >
                                        {label}
                                    </TabsTrigger>
                                ))}
                            </TabsList>

                            <TabsContent value="profile">
                                <UserProfileTab />
                            </TabsContent>

                            <TabsContent value="domains">
                                <BurnerDomainsTab />
                            </TabsContent>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}


