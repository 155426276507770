export enum PRICING_INTERVAL {
    MONTH = "month",
    YEAR = "year"
}

export enum SUBSCRIPTION_STATUS {
    TRAILING = "trialing",
    ACTIVE = "active",
}

interface Feature {
    id: string;
    name: string;
    capacity: number | null;
    canHighlight: string;
}

interface highlightsDetails {
    Search?: number;
    "Email Outreach"?: number;
    "Letter Outreach"?: number | null;
}

export interface SubscriptionPlan {
    createdAt: string;
    id: string;
    name: string;
    description: string;
    features: Feature[];
    price: number;
    pricingInterval: "month" | "year";
    highlights: highlightsDetails;
}
type UserFeatureQuota = {
    id: string | null;
    expiresAt: string | null;
    featureId: string | null;
    usedQuota: number | null;
    totalQuota: number | null;
    featureName: string | null;
    remainingQuota: number | null;
};

type ProductPricing = {
    id: string;
    price: number;
    currency: string;
    interval: string;
};
export interface CurrentSubscriptionDetails {
    id: string;
    userId: string;
    status: string;
    daysUntilDue: number | null;
    startDate: string;
    endedAt: string | null;
    trialStart: string;
    trialEnd: string;
    productName: string;
    productPricing: ProductPricing;
    userFeatureQuotas: UserFeatureQuota[];
}