import type React from "react";
import { Button } from "components/shadcn/ui/button";
import { Card, CardFooter, CardContent } from "components/shadcn/ui/card";
import { CheckIcon, HelpCircle } from "lucide-react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "components/shadcn/ui/dialog";
import { Tabs, TabsList, TabsTrigger } from "components/shadcn/ui/tabs";
import clsx from "clsx";
import { useState } from "react";
import lang from "lang";
import { PRICING_INTERVAL, SubscriptionPlan } from "./types";
import { formatService, getCardWidthClass } from "helpers";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/shadcn/ui/tooltip";
import SvgCloseIcon from "components/popUps/CloseIcon";
import { Badge } from "components/shadcn/ui/badge";
import SvgBagIcon from "components/common/iconComponents/bagIcon";
import SvgEmailOutreachIcon from "components/common/iconComponents/emailOutreachIcon";
import SvgLetterOutreach from "components/common/iconComponents/letterOutreach";
import SvgNewsLetterAndCommunity from "components/common/iconComponents/newsLetterAndCommunityIcon";
import SvgDomainIcon from "components/common/iconComponents/domainIcon";
import SvgDealTracker from "components/common/iconComponents/dealTracker";
import SvgMailBoxIcon from "components/common/iconComponents/mailboxIcon";

interface CreateSubscriptionProps {
    open: boolean;
    purchasePlan: (plan: SubscriptionPlan) => void;
    close: () => void;
    plansData: SubscriptionPlan[];
}

const CreateSubscription: React.FC<CreateSubscriptionProps> = ({
    open,
    purchasePlan,
    close,
    plansData,
}) => {

    const { payment: paymentCopy } = lang;
    const [activeTab, setActiveTab] = useState<"monthly" | "yearly">("yearly");
    const plans = Array.isArray(plansData)
        ? plansData.filter(
            (plan) =>
                plan.pricingInterval === (activeTab === "monthly" ? "month" : "year")
        )
        : [];

    const renderFeatureIcon = (featureName: string) => {
        switch (featureName) {
            case "Collection":
                return <SvgBagIcon className="h-4 w-4 text-gray-600 mr-2" />;
            case "Email Outreach":
                return <SvgEmailOutreachIcon className="h-4 w-4 text-gray-600 mr-2" />;
            case "Letter Outreach":
                return <SvgLetterOutreach className="h-4 w-4 text-gray-600 mr-2" />;
            default:
                return null;
        }
    };

    const renderAdditionalFeatureIcon = (featureName: string) => {
        switch (featureName) {
            case "Community":
                return <SvgNewsLetterAndCommunity className="h-4 w-4 text-gray-600 mr-2" />;
            case "Newsletter":
                return <SvgNewsLetterAndCommunity className="h-4 w-4 text-gray-600 mr-2" />;
            case "Domains":
                return <SvgDomainIcon className="h-4 w-4 text-gray-600 mr-2" />;
            case "Deal Tracker":
                return <SvgDealTracker className="h-4 w-4 text-gray-600 mr-2" />;
            case "Letter Outreach":
                return <SvgLetterOutreach className="h-4 w-4 text-gray-600 mr-2" />;
            case "Mailboxes":
                return <SvgMailBoxIcon className="h-4 w-4 text-gray-600 mr-2" />;
            default:
                return <CheckIcon className="h-4 w-4 text-pink-500 mr-2 mt-0.5 flex-shrink-0" />;
        }
    };

    return (
        <Dialog open={open}>
            <DialogContent className="w-full max-w-[70vw] h-auto max-h-[90vh] flex flex-col px-2 sm:px-4 md:px-6 lg:px-8">
                <DialogHeader className="mb-2 sm:mb-4">
                    <button className="flex justify-end outline-none" onClick={close}>
                        <SvgCloseIcon />
                    </button>
                    <DialogTitle className="text-center text-2xl font-semibold sm:text-2xl flex flex-col gap-2 sm:gap-4 items-center justify-center">
                        {paymentCopy.welcome}
                        <div className="text-sm font-normal text-gray-600 flex flex-col align-center">
                            <div>
                                {paymentCopy.pricingPlanSubtext1}
                            </div>
                            <div>
                                {paymentCopy.pricingPlansSubtext2}
                            </div>
                        </div>
                    </DialogTitle>
                </DialogHeader>

                <Tabs
                    value={activeTab}
                    onValueChange={(value) => setActiveTab(value as "monthly" | "yearly")}
                    className="w-full max-w-[90%] min-w-[200px] flex justify-center mx-auto"
                >
                    <TabsList className="flex flex-row content-center space-x-4 p-2 bg-[#F9FAFB] rounded-lg">
                        {paymentCopy.tabOptions.map(({ value, label }) => (
                            <TabsTrigger
                                key={value}
                                value={value}
                                className={clsx(
                                    "h-[40px] px-4",
                                    activeTab === value
                                        ? "bg-[#FFF1F3] text-primary-700"
                                        : "text-gray-500"
                                )}
                            >
                                <div className="flex items-center gap-2">
                                    {label}
                                    {value === "yearly" &&
                                        <Badge
                                            className={`rounded-full ${activeTab === "yearly" ? "bg-[#FFF1F3] text-primary-700" : "bg-[#F9FAFB] text-gray-700"
                                                } border border-[#FECCD6] px-3 py-1 text-sm font-medium `}
                                        >
                                            Save 20%
                                        </Badge>}
                                </div>
                            </TabsTrigger>
                        ))}
                    </TabsList>
                </Tabs>

                <div className="flex flex-wrap justify-center gap-3 sm:gap-4 mt-3 sm:mt-4 overflow-y-auto max-h-[70vh] sm:max-h-[75vh] md:max-h-none pb-1 sm:pb-2 -mx-2 px-2">
                    {plans.map((plan) => (
                        <Card
                            key={plan.id}
                            className={clsx(
                                "rounded-xl border border-gray-300 bg-gray-50 overflow-hidden p-3 sm:p-4 flex flex-col flex-grow",
                                getCardWidthClass(plans.length),
                                plans.length === 1 ? "mx-auto" : ""
                            )}
                        >
                            <CardContent className="flex flex-col gap-6 flex-grow p-0 sm:p-2">
                                <div className="text-base sm:text-lg font-semibold text-gray-600">
                                    {plan.name}
                                </div>
                                <div className="flex items-baseline">
                                    <span className="text-2xl sm:text-3xl font-semibold text-gray-900">
                                        ${plan.price.toLocaleString()}
                                    </span>
                                    <span className="text-sm text-gray-500 ml-1">
                                        {plan.pricingInterval === PRICING_INTERVAL.MONTH ? "/m" : "/y"}
                                    </span>
                                </div>
                                <div className="flex text-left flex-col gap-2 content-start">
                                    {plan.features
                                        .filter((feature) => feature.canHighlight === "true")
                                        .map((feature, idx) => (
                                            <div
                                                key={idx}
                                                className="flex text-sm sm:text-base mb-2 flex-row gap-4"
                                            >
                                                <div>
                                                    {renderFeatureIcon(feature.name)}
                                                </div>
                                                <div className="flex flex-row gap-1 text-left">
                                                    <span className="font-semibold text-gray-600">
                                                        {feature?.capacity?.toLocaleString()}
                                                    </span>
                                                    <span className="text-gray-600">{feature.name}</span>
                                                    {plan.pricingInterval === PRICING_INTERVAL.MONTH ? "/m" : "/y"}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </CardContent>

                            <div className="flex flex-col items-center justify-center mt-3 sm:mt-4 gap-4">
                                <Button
                                    variant="custom"
                                    className="w-full sm:w-[80%] text-white bg-gradient-to-r from-[#870940] via-[#FF008A] to-[#FF5454]"
                                    onClick={() => purchasePlan(plan)}
                                >
                                    <span className="text-white font-semibold text-sm">
                                        {paymentCopy.subscribe}
                                    </span>
                                </Button>
                                <TooltipProvider>
                                    <div className="flex items-left gap-2 flex-col">
                                        <div className="flex flex-row gap-2 items-center">
                                            <div className="text-sm font-medium text-primary-700">{paymentCopy.startTrial}</div>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <HelpCircle size={16} className="text-gray-400 cursor-pointer" />
                                                </TooltipTrigger>
                                                <TooltipContent side="top">
                                                    {paymentCopy.trialHelpIconTooltip}
                                                </TooltipContent>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </TooltipProvider>
                            </div>

                            <CardFooter className="p-0 sm:p-2 pt-4 sm:pt-6 flex-grow">
                                <div className="space-y-2 sm:space-y-3 w-full">
                                    <h4 className="text-xs sm:text-sm font-semibold uppercase text-gray-500">
                                        {paymentCopy.services}
                                    </h4>
                                    <ul className="flex flex-col gap-4">
                                        {plan.features
                                            .filter(
                                                (feature) =>
                                                    feature.canHighlight !== "true"
                                            )
                                            .map((feature, idx) => (
                                                <li key={idx} className="flex items-center gap-3">
                                                    {renderAdditionalFeatureIcon(feature.name)}
                                                    <span className="text-sm sm:text-base font-normal ">
                                                        {formatService(feature.name, feature.capacity)}
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </DialogContent >
        </Dialog >
    );
};

export default CreateSubscription;


