import SvgMinusCircularIcon from "components/common/iconComponents/minusCircularIcon";
import SvgPlusCircularIcon from "components/common/iconComponents/plusCircularIcon";
import { Button } from "components/shadcn/ui/button";
import { Card } from "components/shadcn/ui/card";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectProductTopup } from "./subscriptionSlice";
import lang from "lang";

const IndividualQuotaTopUp = () => {
    const productTopUp = useSelector(selectProductTopup);
    const { payment: paymentCopy } = lang;
    const [topUpState, setTopUpState] = useState(
        productTopUp.reduce((acc, item) => {
            acc[item.id] = { amount: 0, quota: 0 };
            return acc;
        }, {} as Record<string, { amount: number; quota: number }>)
    );

    const handleIncrement = (id: string) => {
        setTopUpState((prev) => ({
            ...prev,
            [id]: {
                amount: prev[id].amount + (productTopUp.find((p) => p.id === id)?.price ?? 0),
                quota: prev[id].quota + (productTopUp.find((p) => p.id === id)?.quota ?? 0),
            },
        }));
    };

    const handleDecrement = (id: string) => {
        setTopUpState((prev) => {
            if (prev[id].amount > 0) {
                return {
                    ...prev,
                    [id]: {
                        amount: prev[id].amount - (productTopUp.find((p) => p.id === id)?.price ?? 0),
                        quota: prev[id].quota - (productTopUp.find((p) => p.id === id)?.quota ?? 0),
                    },
                };
            }
            return prev;
        });
    };

    const totalPrice = Object.values(topUpState).reduce((sum, item) => sum + item.amount, 0);

    return (
        <Card className="w-full max-w-md py-6 px-3 rounded-[24px] shadow-lg bg-white flex flex-col gap-4">
            <div className="text-lg font-semibold text-gray-600">{paymentCopy.singleTopUp}</div>

            {productTopUp.map((item) => (
                <div
                    key={item.id}
                    className="flex flex-col gap-2 border rounded-lg px-2 py-4 bg-gray-50"
                >
                    <div className="text-sm font-medium text-gray-700">{item.feature}</div>

                    <div className="flex items-center justify-between border rounded-lg py-3 px-1 bg-white">
                        <button
                            onClick={() => handleDecrement(item.id)}
                            className="text-pink-500 text-xl font-bold"
                            aria-label="Decrease amount"
                        >
                            <SvgMinusCircularIcon />
                        </button>

                        <div className="flex flex-col items-center">
                            <div className="text-2xl font-semibold text-gray-900">
                                ${topUpState[item.id].amount}
                            </div>
                            <p className="text-sm text-gray-600">
                                {topUpState[item.id].quota} {item.feature}
                            </p>
                        </div>

                        <button
                            onClick={() => handleIncrement(item.id)}
                            className="text-pink-500 text-xl font-bold"
                            aria-label="Increase amount"
                        >
                            <SvgPlusCircularIcon />
                        </button>
                    </div>
                </div>
            ))}

            <div className="flex flex-col gap-2 border-t pt-3 text-sm text-gray-700">
                {productTopUp.map((item) => (
                    <div key={item.id} className="flex justify-between">
                        <span>{item.feature}:</span>
                        <span>${topUpState[item.id].amount}</span>
                    </div>
                ))}
                <div className="flex justify-between font-semibold text-primary-600">
                    <span>{paymentCopy.summary}:</span>
                    <span>${totalPrice}</span>
                </div>
            </div>

            <Button
                className="w-full text-white py-2 rounded-[120px] bg-gradient-to-r from-[#870940] via-[#FF008A] to-[#FF5454]"
                disabled={totalPrice === 0}
            >
                {paymentCopy.topUp}
            </Button>
        </Card>
    );
};

export default IndividualQuotaTopUp;
