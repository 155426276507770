import SvgSearchIcon from 'components/common/iconComponents/SearchIcon';
import '../../styling/saved.css'
import React, { useEffect, useState } from 'react';
import { RadioGroup, RadioGroupItem } from 'components/shadcn/ui/radio-group';
import { Label } from 'components/shadcn/ui/label';
import { Button } from 'components/shadcn/ui/button';
import { useNavigate } from 'react-router-dom';
import { ParentContext } from 'components/constants/ParentContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectCampaignList, setCampaignListWithDetails, setCampaignType } from './campaignSlice';
import lang from 'lang';
import { getCampaignList } from './services';
import Loader from 'components/common/loader';

interface CampaignLandingProps { }
const CampaignLanding: React.FC<CampaignLandingProps> = () => {
    const { campaign: campaignCopy } = lang;
    const context = React.useContext<any>(ParentContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const campaignList = useSelector(selectCampaignList);

    const [selectedOption, setSelectedOption] = useState("option-1");
    const [loading, setLoading] = useState(false);
    const isFetched = React.useRef(false);


    useEffect(() => {
        if (!isFetched.current) {
            const fetchCampaignList = async () => {
                setLoading(true);
                const fetchedCampaignList = await getCampaignList();
                dispatch(setCampaignListWithDetails(fetchedCampaignList));
                setLoading(false);
            };

            fetchCampaignList();
            isFetched.current = true;
        }

    }, [dispatch]);

    const handleChange = (value: string) => {
        setSelectedOption(value);
        dispatch(setCampaignType("email"));
    };

    const handleNewCampaign = () => {
        navigate('../campaignFlow')
    }

    return (
        <div className="fullScreen">
            <div className="container max-w-[80%]" >
                {loading && <Loader />}
                <div className="savedFiltersScreen">
                    <div className="sfTitles flex flex-col gap-5">
                        <div className="display-sm semibold text-gray-900">{campaignCopy.campaigns}</div>
                    </div>
                    {!loading && <div className='w-full flex flex-col gap-8 items-center'>
                        <div className=" flex align-center justify-center w-12 h-12 p-3 pt-3 gap-0 rounded-[10px]  border  border-gray-200 bg-white shadow-sm">
                            <SvgSearchIcon />
                        </div>
                        <div className='flex flex-col gap-2'>
                            {campaignList.length === 0 ? (
                                <div className="font-[InstrumentSans] text-lg font-semibold leading-7 text-center text-gray-900">
                                    {campaignCopy.noCampaignsHeading}
                                </div>
                            ) : null}
                            <div className="font-inter text-sm font-normal leading-5 text-center text-gray-600">{campaignCopy.campaignOptionText}</div>
                        </div>
                        <RadioGroup key={selectedOption} value={selectedOption} onValueChange={handleChange} defaultValue="option-one">
                            <div className="flex gap-2 flex-col">
                                {campaignCopy.campaignOptionType.map((option) => (
                                    <div
                                        className={`w-[352px] h-[74px] p-[16px] gap-1 bg-white ${option.disabled ? 'opacity-50' : 'opacity-100'} rounded-[12px] flex items-center justify-between border-2 
                                        ${selectedOption === `option-${option.id}`
                                                ? context.isHarbour
                                                    ? "border-[#3DAEDF]"
                                                    : "border-[#C01048]"
                                                : "border-[#F2F4F7]"}`}
                                    >
                                        <div className='flex flex-col gap-1 items-start'>
                                            <Label htmlFor={`option-${option.id}`} className="text-sm font-bold text-[#344054]">
                                                {option.label}
                                            </Label>
                                            {/* <div className='text-sm font-regular text-gray-600'>{option.subText}</div> */}
                                        </div>
                                        <RadioGroupItem
                                            value={`option-${option.id}`}
                                            id={`option-${option.id}`}
                                            className="mr-4 flex items-center justify-center"
                                            disabled={option.disabled}
                                            isHarbour={context.isHarbour}
                                        />
                                    </div>
                                ))}
                            </div>
                        </RadioGroup>
                        <Button variant="primary" onClick={handleNewCampaign}>
                            <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                                {campaignCopy.newCampaign}
                            </div>
                        </Button>
                    </div>
                    }
                </div>
            </div >
        </div >
    );


}

export default CampaignLanding;