import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from "components/shadcn/ui/table";
import { Checkbox } from "components/shadcn/ui/checkbox";
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import SvgChevronSelectorIcon from "components/common/iconComponents/chevronSelectorIcon";
import { useSelectCollection } from "./useSelectCollection";
import lang from "lang";
import { Card, CardContent } from "components/shadcn/ui/card";
import { formatDate } from "helpers";
import { Input } from "components/shadcn/ui/input";
import EmailQuotaModal from "components/subscription/emailQuotaModal";
import UpgradeQuotaModal from "components/subscription/upgradeQuota";
import { CurrentSubscriptionDetails } from "components/subscription/types";
import { getCurrentSubscription, upgradeSubscription } from "components/subscription/services";
import { useDispatch } from "react-redux";
import { updateCurrentSubscription } from "components/subscription/subscriptionSlice";
import Loader from "components/common/loader";
import ExceedEmailQuotaModal from "./exceedEmailQuotaModal";

const SelectCollection: React.FC = () => {
    const { campaign: campaignCopy } = lang;
    const [showExceedingQuotaModal, setShowExceedingQuotaModal] =
        useState(false);
    const [showIndividualQuotaModal, setShowIndividualQuotaModal] =
        useState(false);
    const [showUpgradeQuotaModal, setShowUpgradeQuotaModal] =
        useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {
        isAllSelected,
        selectedCollectionIds,
        emailOutreachQuota,
        searchQuery,
        columns,
        data,
        sorting,
        totalEmailQuota,
        toggleSelectAll,
        toggleRowSelection,
        setSorting,
        setSearchQuery,
    } = useSelectCollection();

    const selectedCompaniesCount = data.reduce(
        (total, row) =>
            selectedCollectionIds?.includes(row.id)
                ? total + row.companyCount
                : total,
        0
    );

    const selectedRecipientsCount = data.reduce(
        (total, row) =>
            selectedCollectionIds?.includes(row.id)
                ? total + row.recipientsPerCompany
                : total,
        0
    );

    const stats = [
        {
            label: campaignCopy.companiesSelected,
            value: selectedCompaniesCount.toString(),
        },
        {
            label: campaignCopy.noOfRecipients,
            value: selectedRecipientsCount.toString(),
        },
        {
            label: campaignCopy.recipientQuota,
            value: `${selectedRecipientsCount} / ${emailOutreachQuota}`,
        },
    ];

    useEffect(() => {
        if (selectedRecipientsCount > emailOutreachQuota) {
            setShowExceedingQuotaModal(true);
        }
    }, [selectedRecipientsCount, emailOutreachQuota]);

    const closeExceedingQuotaModal = () => {
        setShowExceedingQuotaModal(false);
    };

    const showUpgradeQuota = () => {
        setShowIndividualQuotaModal(true);
    };

    const handleChangePlan = () => {
        setShowIndividualQuotaModal(false);
        setShowUpgradeQuotaModal(true);
    };

    const handleUpgradeSubscription = async (subscription: CurrentSubscriptionDetails, productPricingId: string) => {
        setShowIndividualQuotaModal(false);
        setShowUpgradeQuotaModal(false);
        setShowExceedingQuotaModal(false);
        setLoading(true);
        try {
            const upgradedSubscription = await upgradeSubscription(subscription.id, productPricingId);
            if (upgradedSubscription) {
                const currentSubscription = await getCurrentSubscription();
                if (currentSubscription) {
                    dispatch(updateCurrentSubscription(currentSubscription));
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    };


    const table = useReactTable({
        data,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableRowSelection: true,
        getRowId: (row: any) => row.id,
    });

    return (
        <div className="border border-gray-200 rounded-[12px] max-h-[100%] overflow-y-auto w-full flex gap-6 flex-col">
            {loading && <Loader />}
            {showExceedingQuotaModal && (
                <ExceedEmailQuotaModal
                    open={showExceedingQuotaModal}
                    onClose={closeExceedingQuotaModal}
                    showUpgradeQuota={showUpgradeQuota}
                    totalEmailQuota={totalEmailQuota}
                    emailOutreachQuota={emailOutreachQuota}
                    selectedRecipientsCount={selectedRecipientsCount}
                />
            )}
            {showIndividualQuotaModal && (
                <EmailQuotaModal
                    open={showIndividualQuotaModal}
                    close={() => setShowIndividualQuotaModal(false)}
                    changePlan={handleChangePlan}
                />
            )}
            {showUpgradeQuotaModal && (
                <UpgradeQuotaModal
                    open={showUpgradeQuotaModal}
                    close={() => setShowUpgradeQuotaModal(false)}
                    handleUpgradeSubscription={(subscription, productPricingId) =>
                        handleUpgradeSubscription(subscription, productPricingId)
                    }
                />
            )}
            <div className="display-sm semibold text-gray-900 text-left">
                {campaignCopy.selectCollection}
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
                {stats.map((stat, index) => (
                    <Card
                        key={index}
                        className="w-[100%] sm:w-1/3 md:w-1/3 p-4 text-center"
                    >
                        <CardContent className="p-2 flex flex-row justify-between items-center">
                            <p className="text-sm text-gray-500">{stat.label}</p>
                            <p className="text-xl font-semibold">{stat.value}</p>
                        </CardContent>
                    </Card>
                ))}
            </div>
            <Table className="border-separate border-spacing-0 w-full bg-white">
                <TableHeader>
                    <TableRow>
                        <TableCell className="border-b border-gray-200">
                            <Checkbox
                                checked={isAllSelected}
                                onCheckedChange={(checked: any) => toggleSelectAll(!!checked)}
                                aria-label="Select all collections"
                            />
                        </TableCell>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <React.Fragment key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        className="cursor-pointer border-b border-gray-200"
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        <div className="flex flex-row gap-2 items-center text-sm font-medium text-gray-600">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {header.column.getIsSorted() ? (
                                                header.column.getIsSorted() === "desc" ? (
                                                    <SvgChevronSelectorIcon />
                                                ) : (
                                                    <SvgChevronSelectorIcon />
                                                )
                                            ) : (
                                                <SvgChevronSelectorIcon />
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={columns.length + 1} className="p-2">
                            <Input
                                className="w-full"
                                placeholder="Search by collection name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows.map((row) => {
                        const isDisabled = row.original.isAlreadyInCampaign;

                        return (
                            <TableRow
                                className={`${isDisabled ? "cursor-not-allowed opacity-50" : ""
                                    }`}
                            >
                                <TableCell
                                    className={`border-b border-gray-200 ${selectedCollectionIds?.includes(row.id) ? "bg-muted/50" : ""
                                        }`}
                                >
                                    <Checkbox
                                        checked={selectedCollectionIds?.includes(row.id)}
                                        onCheckedChange={() =>
                                            !isDisabled && toggleRowSelection(row.id)
                                        }
                                        aria-label={`Select ${row.original.collection}`}
                                        disabled={isDisabled}
                                    />
                                </TableCell>

                                {row.getVisibleCells().map((cell) => (
                                    <TableCell
                                        key={cell.id}
                                        className={`border-b border-gray-200 text-left ${selectedCollectionIds?.includes(row.id)
                                            ? "bg-muted/50"
                                            : ""
                                            }`}
                                    >
                                        {cell.column.id === "collection" ? (
                                            <div>
                                                <div
                                                    className={`font-medium font-inter ${selectedCollectionIds?.includes(row.id)
                                                        ? "text-purple-600"
                                                        : "text-gray-900"
                                                        }`}
                                                >
                                                    {row.original.collection}
                                                </div>
                                                <div
                                                    className={`text-regular font-inter ${selectedCollectionIds?.includes(row.id)
                                                        ? "text-purple-600"
                                                        : "text-gray-600"
                                                        }`}
                                                >
                                                    {row.original.companyCount} companies
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className={`text-regular font-inter ${selectedCollectionIds?.includes(row.id)
                                                    ? "text-purple-600"
                                                    : "text-gray-600"
                                                    }`}
                                            >
                                                {cell.column.id === "date"
                                                    ? `${formatDate(cell.renderValue() as string)}`
                                                    : (cell.renderValue() as string)}
                                            </div>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

export default SelectCollection;
