

import { SVGProps } from 'react';
const SvgEmailOutreachIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 7.00036V12.0004C15 12.796 15.3161 13.5591 15.8787 14.1217C16.4413 14.6843 17.2044 15.0004 18 15.0004C18.7957 15.0004 19.5587 14.6843 20.1213 14.1217C20.6839 13.5591 21 12.796 21 12.0004V11.0004C20.9999 8.74339 20.2362 6.55283 18.8333 4.78489C17.4303 3.01694 15.4706 1.77558 13.2726 1.26265C11.0747 0.749726 8.76794 0.995398 6.72736 1.95972C4.68677 2.92405 3.03241 4.55031 2.03327 6.57408C1.03413 8.59785 0.748975 10.9001 1.22418 13.1065C1.69938 15.3128 2.90699 17.2936 4.65064 18.7266C6.39429 20.1597 8.57144 20.9607 10.8281 20.9995C13.0847 21.0383 15.2881 20.3126 17.08 18.9404M15 11.0004C15 13.2095 13.2092 15.0004 11 15.0004C8.79087 15.0004 7.00001 13.2095 7.00001 11.0004C7.00001 8.79122 8.79087 7.00036 11 7.00036C13.2092 7.00036 15 8.79122 15 11.0004Z" stroke="#C01048" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
export default SvgEmailOutreachIcon;

