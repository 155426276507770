import type React from "react"
import { useMemo, useState, useEffect, useRef } from "react"
import { type ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import SvgChevronSelectorIcon from "components/common/iconComponents/chevronSelectorIcon"
import { Badge } from "components/shadcn/ui/badge"
import { Table, TableBody, TableCell, TableHeader, TableRow } from "components/shadcn/ui/table"
import { getBurnerDomainList } from "./services"
import Loader from "components/common/loader"
import { formatDate } from "helpers"


export default function BurnerDomainsTable() {

    type Domain = {
        domain: string;
        registration: string;
        dkimStatus: string;
        action: string;
        createdAt: string;
    };

    const [domainList, setDomainList] = useState<Domain[]>([]);
    const [loading, setLoading] = useState(false);
    //const [rowData, setRowData] = useState<any>([]);
    const isFetched = useRef(false);

    useEffect(() => {
        const fetchDomainList = async () => {
            setLoading(true);
            const burnerDomainList = await getBurnerDomainList();
            setDomainList(burnerDomainList);
            setLoading(false);
        };
        fetchDomainList();
        isFetched.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const [showDkimModal, setShowDkimModal] = useState(false);

    // const handleDkimGeneration = useCallback(() => {
    //     setShowDkimModal(false);
    // }, []);

    // const openDkimModal = useCallback((rowData: any) => {
    //     setRowData(rowData);
    //     setShowDkimModal(true);
    // }, []);

    const columns: ColumnDef<(typeof domainList)[0]>[] = useMemo(
        () => [
            { accessorKey: "preferredDomain", header: () => "Domain" },
            {
                accessorKey: "createdAt", header: () => "Domain Registration",
                cell: ({ row }) => formatDate(row.original.createdAt)
            },
            {
                accessorKey: "status",
                header: () => "Status",
                cell: ({ getValue }: { getValue: () => any }) => {
                    const value = getValue() || "Unknown";
                    return (
                        <Badge
                            className={
                                value === "domain_failed"
                                    ? "bg-primary-700 text-white"
                                    : value === "domain_ready"
                                        ? "bg-green-100 text-green-700"
                                        : [
                                            "domain_purchased",
                                            "domain_added",
                                            "domain_verified",
                                            "domain_mx_dkim_added",
                                            "domain_mx_dkim_verified"
                                        ].includes(value)
                                            ? "bg-blue-500 text-white"
                                            : "bg-gray-300 text-black"
                            }
                        >
                            {value as React.ReactNode}
                        </Badge>
                    );
                },
            },
            // {
            //     accessorKey: "action",
            //     header: () => "Actions",
            //     cell: ({ row }) =>
            //         row.original.dkimStatus === "Ready for DKIM Setup" || row.original.dkimStatus === "DKIM Configured" ? (
            //             <Button onClick={() => openDkimModal(row.original)
            //             } variant="link" className="text-primary-700 text-left" >
            //                 {row.original.dkimStatus === "Ready for DKIM Setup" || row.original.dkimStatus === "DKIM Configured" ? "Generate DKIM" : "Not Ready for DKIM"}
            //             </Button >
            //         ) : (
            //             <span className="text-gray-400 cursor-not-allowed">{row.original.dkimStatus === "Awaiting DKIM Verification" ? "Not ready for DKIM" : row.original.dkimStatus}</span>
            //         ),
            // },
        ],
        [],
    )

    const table = useReactTable({
        data: domainList,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })

    return (
        <>
            {/* {showDkimModal && (
                <GenrateDKIMModal
                    isOpen={showDkimModal}
                    onClose={() => setShowDkimModal(false)}
                    onSave={handleDkimGeneration}
                    rowData={rowData}
                />
            )} */}
            <div className="overflow-x-auto mt-4">
                {loading && <Loader />}
                <Table className="border border-gray-200 border-separate border-spacing-0 w-full rounded-s-2xl">
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableCell
                                        key={header.id}
                                        className="cursor-pointer border-b border-gray-200"
                                        onClick={header.column.getToggleSortingHandler()}
                                        style={{ width: header.column.id === "preferredDomain" ? "50%" : "auto" }}
                                    >
                                        {!header.isPlaceholder && (
                                            <div className="flex flex-row gap-2 items-center text-sm font-medium text-gray-600">
                                                {typeof header.column.columnDef.header === "function"
                                                    ? header.column.columnDef.header(header.getContext())
                                                    : header.column.columnDef.header}

                                                {header.column.getIsSorted() && (
                                                    <SvgChevronSelectorIcon
                                                        className={header.column.getIsSorted() === "asc" ? "rotate-180" : ""}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows.map((row) => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell
                                        key={cell.id}
                                        className=" border-b border-gray-200 font-inter text-sm text-left"
                                        style={{
                                            width: cell.column.id === "preferredDomain" ? "50%" : "auto",
                                            fontWeight: cell.column.id === "preferredDomain" ? "600" : "500",
                                            color: cell.column.id === "preferredDomain" ? "#111827" : "#4B5563",
                                        }}
                                    >
                                        {typeof cell.column.columnDef.cell === "function"
                                            ? cell.column.columnDef.cell(cell.getContext())
                                            : cell.getValue()}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}