import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CardContent, CardHeader, CardTitle } from 'components/shadcn/ui/card';
import { Input } from 'components/shadcn/ui/input';
import { Label } from 'components/shadcn/ui/label';
import { validationSchema } from 'components/campaign/validationSchema';
import lang from 'lang';
import { formatPhoneNumber, formatWebsite } from 'helpers';
import { getAccountDetails } from 'components/campaign/services';
import Loader from 'components/common/loader';

type FormInputs = Yup.InferType<typeof validationSchema>;

interface SetupCustomisationsProps {
    onFormSubmit: (data: FormInputs) => void;
    setIsFormValid: (isValid: boolean) => void;
}

const SetupCustomisations: React.FC<SetupCustomisationsProps> = ({ onFormSubmit, setIsFormValid }) => {
    const { campaign: camapignCopy } = lang;
    const [loading, setLoading] = React.useState(false);
    const [customizations, setCustomizations] = React.useState<{
        holdingCompany?: string;
        phoneNumber?: string;
        website?: string;
    }>({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getAccountDetails();
            if (response) {
                setCustomizations({
                    holdingCompany: response?.holdingCompany,
                    phoneNumber: response?.phoneNumber,
                    website: response?.website,
                });
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    const {
        register,
        watch,
        setValue,
        reset,
        formState: { errors, isValid },
    } = useForm<FormInputs>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    useEffect(() => {
        reset({
            holdingCompany: customizations.holdingCompany || '',
            phoneNumber: customizations.phoneNumber || '',
            website: customizations.website || '',
        });
    }, [customizations, reset]);

    const formValues = watch();
    const prevFormValues = useRef<FormInputs | null>(null);
    useEffect(() => {
        setIsFormValid(isValid);

        if (isValid && JSON.stringify(prevFormValues.current) !== JSON.stringify(formValues)) {
            prevFormValues.current = formValues;
            onFormSubmit(formValues);
        }
    }, [isValid, formValues, setIsFormValid, onFormSubmit]);


    const handleWebsiteBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const formattedValue = formatWebsite(e.target.value);
        setValue('website', formattedValue);
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedNumber = formatPhoneNumber(e.target.value);
        //setPhoneNumber(formattedNumber);

        setValue('phoneNumber', formattedNumber, { shouldValidate: true });
    };

    return (
        <div className="w-full text-left shadow-none">
            {loading && <Loader />}
            <CardHeader>
                <CardTitle className="text-xl font-semibold">Setup Customisations</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-6">

                <div className="flex flex-col gap-2">
                    <Label htmlFor="companyName" className="text-sm text-[#344054]">
                        {camapignCopy.labelForCompanyNAme}
                    </Label>
                    <Input id="companyName" {...register('holdingCompany')} placeholder="Acme" />
                    {errors.holdingCompany && (
                        <p className="text-red-500 text-sm">{errors.holdingCompany.message}</p>
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    <Label htmlFor="phone" className='text-sm text-[#344054]'>{camapignCopy.phoneNumber}</Label>
                    <Input
                        id="phone"
                        type="tel"
                        {...register('phoneNumber', { onChange: handlePhoneChange })}
                        placeholder="1234 123 123"
                    />
                    {errors.phoneNumber && (
                        <p className="text-red-500 text-sm">{errors.phoneNumber.message}</p>
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    <Label htmlFor="website" className="text-sm text-[#344054]">{camapignCopy.website}</Label>
                    <Input
                        id="website"
                        type="url"
                        {...register('website')}
                        placeholder="www.acme.com"
                        onBlur={handleWebsiteBlur}
                    />
                    {errors.website && <p className="text-red-500 text-sm">{errors.website.message}</p>}
                </div>
            </CardContent>
        </div>
    );
};

export default SetupCustomisations;
