import { useEffect, useState } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/shadcn/ui/dialog"
import { Input } from "components/shadcn/ui/input"
import { Button } from "components/shadcn/ui/button"
import { EditIcon } from "lucide-react"
import SvgCloseIcon from "./CloseIcon"
import lang from "lang"
import { useSelector } from "react-redux"
import { selectCampaignName } from "components/campaign/campaignSlice"
import { useNavigate } from "react-router-dom"

interface CampaignModalProps {
    isOpen: boolean,
    value: string,
    errorMessage: boolean,
    onClose: () => void,
    onSave: (name: string) => void,
}

export default function CampaignNameModal({ isOpen, value, errorMessage, onClose, onSave }: CampaignModalProps) {
    const { campaign: campaignCopy } = lang;
    const navigate = useNavigate();
    const alreadyCampaignName = useSelector(selectCampaignName);

    const [campaignName, setCampaignName] = useState(value || "");

    useEffect(() => {
        setCampaignName(value ? value : alreadyCampaignName);
    }, [value, alreadyCampaignName]);


    const handleSave = () => {
        onSave(campaignName);
        setCampaignName(alreadyCampaignName ? alreadyCampaignName : value);
        //onClose();
    };

    const handleCancel = () => {
        navigate(`../campaign`, { replace: true });
    };

    return (
        <div className="flex z-999 rounded-[32px]">
            <Dialog open={isOpen} onOpenChange={onClose}>
                <DialogContent className="sm:max-w-md rounded-[32px]">
                    <div className="flex flex-row align-center justify-between">
                        <EditIcon />
                        <button onClick={onClose}><SvgCloseIcon /></button>
                    </div>
                    <DialogHeader className="flex flex-row items-center justify-between">
                        <DialogTitle className="text-lg font-semibold text-gray-950">{campaignCopy.editNameHeader}</DialogTitle>

                    </DialogHeader>
                    <div className="py-6">
                        <Input
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                            placeholder="New campaign"
                            className="w-full"
                        />
                        {errorMessage && <div className="text-sm font-regular text-primary-700 mt-2">Campaign name already exists. Please try with another name</div>}
                    </div>
                    <div className="flex justify-end gap-4">
                        <Button variant="outline" onClick={handleCancel}>
                            {campaignCopy.cancel}
                        </Button>
                        <Button onClick={handleSave} variant="primary" disabled={!campaignName} className="bg-[#E6007A] hover:bg-[#E6007A]/90 text-white">
                            {campaignCopy.save}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div >

    )
}

