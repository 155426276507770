import "../../../../../src/index.css";
import type React from "react";
import { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "components/shadcn/ui/button";
import { Card, CardContent } from "components/shadcn/ui/card";
import { type EmailTemplate, useEmailTemplates } from "./useEditMessage";
import lang from "lang";
import { Badge } from "components/shadcn/ui/badge";
import VariableSelectionDialog from "components/popUps/variableSelctionDialogue";
import sanitizeHtml from "sanitize-html";
import { ArrowRight, ChevronDown } from "lucide-react";

export const ArrowLineWithBadge = ({ index }: { index: number }) => {
    return (
        <div className="flex flex-col items-center">
            <div className="w-1 bg-gray-600 h-8"></div>
            <Badge
                variant={"outline"}
                className="bg-gray-700 text-xs font-medium text-[#FCFCFD] px-4 py-2 rounded-full hover:none"
            >
                E-mail {index + 2}
            </Badge>
            <div className="w-1 bg-gray-600 h-8"></div>
            <div className="w-0 h-0 border-l-4 border-r-4 border-t-8 border-transparent border-t-gray-600"></div>
        </div>
    );
};

const EmailEditor: React.FC = () => {
    const { campaign: campaignCopy } = lang;
    const { emails, updateEmail } = useEmailTemplates();
    const [activeEmailIndex, setActiveEmailIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [editorContent, setEditorContent] = useState("");
    const quillRef = useRef<ReactQuill | null>(null);
    const prevEmailIndexRef = useRef<number | null>(null);
    const isManualEdit = useRef(false);
    const subjectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Only update content when the email changes, not during manual edits
        if (emails.length > 0) {
            if (prevEmailIndexRef.current !== activeEmailIndex) {
                if (
                    !isManualEdit.current &&
                    emails.length > 0 &&
                    emails[activeEmailIndex]?.body !== undefined
                ) {
                    setEditorContent(processContent(emails[activeEmailIndex].body));
                }
                prevEmailIndexRef.current = activeEmailIndex;
            }
        }
    }, [activeEmailIndex, emails]);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            const applyVariableFormatting = () => {
                const text = quill.getText();
                const regex = /\{\{(.*?)\}\}/g;
                let match;

                while ((match = regex.exec(text)) !== null) {
                    const start = match.index;
                    const length = match[0].length;

                    quill.formatText(start, length, "color", "#C01048");
                }
            };

            applyVariableFormatting();

            quill.on("text-change", applyVariableFormatting);

            quill.root.addEventListener("keydown", (event) => {
                const range = quill.getSelection();
                if (!range) return;

                const text = quill.getText();
                const regex = /\{\{(.*?)\}\}/g;
                let match;

                while ((match = regex.exec(text)) !== null) {
                    const variableStart = match.index;
                    const variableEnd = variableStart + match[0].length;

                    if (
                        (range.index >= variableStart && range.index <= variableEnd)
                    ) {
                        if (event.key === "Backspace" || event.key === "Delete") {
                            // Prevent the default behavior (typing or deleting)
                            event.preventDefault();

                            // Delete the entire {{ }} block
                            quill.deleteText(variableStart, variableEnd - variableStart);

                            // Move the cursor to the start of the deleted block
                            quill.setSelection(variableStart, 0);
                            return;
                        }

                        // Prevent typing inside the {{ }} block
                        if (
                            range.index > variableStart &&
                            range.index < variableEnd &&
                            event.key !== "Backspace" &&
                            event.key !== "Delete"
                        ) {
                            event.preventDefault();
                            return;
                        }
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quillRef.current]);

    useEffect(() => {
        if (!subjectRef.current) return;

        const handleKeyDown = (event: KeyboardEvent) => {
            const selection = window.getSelection();
            if (!selection || selection.rangeCount === 0) return;

            const range = selection.getRangeAt(0);
            const text = subjectRef.current?.textContent || "";
            const regex = /\{\{(.*?)\}\}/g;
            let match;

            while ((match = regex.exec(text)) !== null) {
                const variableStart = match.index;
                const variableEnd = variableStart + match[0].length;

                if (
                    range.startOffset > variableStart &&
                    range.startOffset < variableEnd &&
                    event.key !== "Backspace" &&
                    event.key !== "Delete"
                ) {
                    event.preventDefault();
                    return;
                }

                if (range.startOffset === variableEnd) {
                    return;
                }

                if (range.startOffset <= variableStart) {
                    return;
                }

                if (range.startOffset === variableStart && event.key === "Delete") {
                    event.preventDefault();
                    const newText = text.slice(0, variableStart) + text.slice(variableEnd);
                    if (subjectRef.current) {
                        subjectRef.current.textContent = newText;
                    }

                    const newRange = document.createRange();
                    newRange.setStart(subjectRef.current?.childNodes[0] || document.createTextNode(""), variableStart);
                    newRange.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    return;
                }

                if (range.startOffset === variableEnd && event.key === "Backspace") {
                    event.preventDefault();
                    const newText = text.slice(0, variableStart) + text.slice(variableEnd);
                    if (subjectRef.current) {
                        subjectRef.current.textContent = newText;
                    }

                    const newRange = document.createRange();
                    newRange.setStart(subjectRef.current?.childNodes[0] || document.createTextNode(""), variableStart);
                    newRange.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                    return;
                }
            }
        };

        subjectRef.current.addEventListener("keydown", handleKeyDown);

        return () => {
            if (subjectRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                subjectRef.current.removeEventListener("keydown", handleKeyDown);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subjectRef.current]);

    const handleEditorFocus = () => {
        isManualEdit.current = true;
    };

    const handleEditorChange = (content: string) => {
        content = content.replace(/href="(?!https?:\/\/)([^"]*)"/g, (match, p1) => {
            return `href="https://${p1}"`;
        });
        setEditorContent(content);
    };

    const handleBlur = () => {
        if (subjectRef && subjectRef.current) {
            const updatedSubject = subjectRef.current?.textContent?.trim() || "";
            updateEmail(activeEmailIndex, { subject: updatedSubject });
        }
    };

    const getTimingText = (sendAfterDays: number) => {
        if (sendAfterDays === 0) return "Send When Mailbox Warm";
        if (sendAfterDays > 0) return `Send ${sendAfterDays} Days`;
        //if (sendAfterDays === 14) return "Two weeks later";
        return `Send ${sendAfterDays} Days`;
    };
    const handleEditorBlur = () => {
        isManualEdit.current = false;
        if (quillRef.current) {
            //const editor = quillRef.current.getEditor();
            let content = editorContent;
            content = content.replace(/href="(?!https?:\/\/)([^"]*)"/g, (match, p1) => {
                return `href="https://${p1}"`;
            });
            const cleanedContent = sanitizeHtml(content, {
                allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                    "a",
                    "span",
                    "p",
                    "div",
                ]),
                allowedAttributes: {
                    a: ["href", "target", "rel"],
                    span: ["class", "style"],
                    p: ["class", "style"],
                    div: ["class", "style"],
                },
                allowedStyles: {
                    "*": {
                        "text-align": [/^left$/, /^right$/, /^center$/, /^justify$/],
                        color: [
                            /^#[0-9a-fA-F]{3,6}$/,
                            /^rgb$$(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})$$$/,
                            /^rgba$$(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})$$$/,
                        ],
                        "background-color": [
                            /^#[0-9a-fA-F]{3,6}$/,
                            /^rgb$$(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})$$$/,
                            /^rgba$$(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})$$$/,
                        ],
                    },
                },
            });
            updateEmail(activeEmailIndex, { body: cleanedContent });
            setEditorContent(cleanedContent);
        }
    };
    const formatSubject = (subject: string) => {
        return subject.replace(
            /\{\{(.*?)\}\}/g,
            '<span class="highlighted" contentEditable=false>{{$1}}</span>&nbsp;'
        );
    };

    const processContent = (content: string) => {
        content = String(content).replace(/\n/g, "<br>");
        //content = content.replace(/\{\{([^}]+)\}\}/g, '<span class="highlighted">{{$1}}</span>');
        return content;
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            ["clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const renderEmailEditor = (email: EmailTemplate, index: number) => (
        <Card className="w-full" onMouseLeave={handleEditorBlur}>
            <CardContent className="p-6">
                <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-bold text-gray-700">
                        Send email {index + 1}
                    </span>
                    <div className="font-inter text-base font-normal leading-[24px] text-left text-gray-500 underline-from-font decoration-none">
                        {getTimingText(email.sendAfterDays)}
                        {index === 1 || index === 2 ? ` After Email ${index}` : ""}
                    </div>
                </div>

                <div
                    contentEditable
                    ref={subjectRef}
                    className="flex w-full rounded-[8px] font-medium text-md text-gray-900 border border-input bg-background px-3 py-2 focus:border-[#FEA3B4] focus:ring-0 focus:shadow-[0px_0px_0px_4px_#FFE4E8] text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none  disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                    dangerouslySetInnerHTML={{ __html: formatSubject(email.subject) }}
                    onBlur={handleBlur}
                />

                <div className="border rounded-md">
                    <div className="w-full flex justify-end p-2">
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => setShowModal(true)}
                        >
                            <div className="text-white font-inter text-sm font-semibold leading-5 text-left underline-from-font">
                                {campaignCopy.parameters}
                            </div>
                        </Button>
                    </div>
                    <div>
                        <VariableSelectionDialog
                            showModal={showModal}
                            setShowModal={setShowModal}
                            onClose={() => setShowModal(false)}
                        />
                    </div>
                    <ReactQuill
                        ref={quillRef}
                        value={editorContent}
                        onFocus={handleEditorFocus}
                        onChange={handleEditorChange}
                        onBlur={handleEditorBlur}
                        modules={modules}
                        theme="snow"
                        className="text-md font-medium text-black min-h-[200px] focus:outline-none text-left"
                    />
                </div>
            </CardContent>
        </Card>
    );

    const renderEmailCard = (email: EmailTemplate, index: number) => {
        const formatBody = (text: string, maxLength = 150) => {
            const highlightedText = text.replace(
                /\{\{([^}]+)\}\}/g, // Matches text inside {}
                '<span class="text-primary-700 font-semibold">{{$1}}</span>'
            );

            return highlightedText.length > maxLength
                ? highlightedText.slice(0, maxLength) + "..."
                : highlightedText;
        };

        return (
            <Card
                className="text-left w-full max-w-md mx-auto cursor-pointer transition-colors shadow-sm border"
                onClick={() => setActiveEmailIndex(index)}
            >
                <CardContent className="p-4 rounded-2xl">
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-row items-center justify-between mt-2 ">
                            <h3 className="text-lg font-bold text-gray-700 mt-0">{email.name}</h3>
                            <ChevronDown /></div>

                        <div className="w-full bg-[#F2F4F7] rounded-xl h-7 flex items-center">
                            <p className="text-sm font-regular text-gray-500 pl-2">
                                {getTimingText(email.sendAfterDays)}
                                {index === 1 || index === 2 ? ` After Email ${index}` : ""}
                            </p>
                        </div>
                        <p
                            className="text-sm text-[#000000]"
                            dangerouslySetInnerHTML={{ __html: formatBody(email.body) }}
                        />

                        <Button
                            variant="custom"
                            className="w-[fit-content] pl-0 rounded-[32px]"
                            size="sm"
                            onClick={() => {
                                setActiveEmailIndex(index);
                            }}
                        >
                            <div className="flex flex-row gap-2 items-center text-primary-700 font-inter text-sm font-semibold leading-5 text-left underline-from-font pl-0">
                                {campaignCopy.reviewEmail}
                                <ArrowRight />
                            </div>
                        </Button>
                    </div>
                </CardContent>
            </Card>
        );
    };

    return (
        <div className="w-full">
            <div className="text-xl font-semibold mb-6 text-left">
                {campaignCopy.reviewAndRefine}
            </div>
            <div>
                {emails?.map((email, index) => (
                    <div key={index}>
                        {activeEmailIndex === index
                            ? renderEmailEditor(email, index)
                            : renderEmailCard(email, index)}
                        {index < emails.length - 1 && <ArrowLineWithBadge index={index} />}
                    </div>
                ))}
            </div>
            <style>{`
        .highlighted {
          color: #C01048;
        }
      `}</style>
        </div>
    );
};

export default EmailEditor;
