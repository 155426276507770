import axiosWithToken from "axiosWithToken";
import {
  CreateCampaignDto,
  CustomisationRequestDTO,
  MailboxRequestDTO,
  SaveCollectionIdsDTO,
  SaveEmailTemplatesPayload,
  updateCollectionDetailsRequest,
} from "./types";
const baseURL2 = process.env.REACT_APP_BASEURL2;

export const createNewCampaign = async (data: CreateCampaignDto) => {
  try {
    const response = await axiosWithToken.post(
      `${baseURL2}api/campaigns`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const updateExistingCampaign = async (
  campaignId: string,
  name: string
) => {
  try {
    const payload = {
      name: name,
    };
    const response = await axiosWithToken.patch(
      `${baseURL2}api/campaigns/${campaignId}/update`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const saveCollectionId = async (
  data: SaveCollectionIdsDTO,
  campaignId: string
) => {
  try {
    const response = await axiosWithToken.put(
      `${baseURL2}api/campaigns/${campaignId}/collections`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error saving collections:", error);
    throw error;
  }
};

export const saveCustomisations = async (
  customisationsData: CustomisationRequestDTO
) => {
  try {
    const response = await axiosWithToken.put(
      `${baseURL2}api/user/customization`,
      customisationsData
    );
    return response.data;
  } catch (error) {
    console.error("Error saving customisations:", error);
    throw error;
  }
};

export const getEmailTemplates = async () => {
  try {
    const response = await axiosWithToken.get(`${baseURL2}api/templates`);
    return response.data;
  } catch (error) {
    console.error("Error saving customisations:", error);
    throw error;
  }
};

export const saveEmailTemplates = async (
  data: SaveEmailTemplatesPayload,
  campaignId: string
) => {
  try {
    const response = await axiosWithToken.put(
      `${baseURL2}api/campaigns/${campaignId}/messages`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error saving email messages:", error);
    throw error;
  }
};

export const saveMailBoxDataToApi = async (
  data: MailboxRequestDTO,
  campaignId: string
) => {
  try {
    const response = await axiosWithToken.put(
      `${baseURL2}api/campaigns/${campaignId}/mailboxes`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error saving mailbox data:", error);
    throw error;
  }
};

export const getSummaryForCamapign = async (campaignId: string) => {
  try {
    const response = await axiosWithToken.get(
      `${baseURL2}api/campaigns/${campaignId}/summary`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting campaign summary:", error);
    throw error;
  }
};

export const getCampaignList = async () => {
  try {
    const response = await axiosWithToken.get(`${baseURL2}api/campaigns`);
    return response.data;
  } catch (error) {
    console.error("Error getting campaign list:", error);
    throw error;
  }
};

export const saveCampaign = async (campaignId: string) => {
  try {
    const response = await axiosWithToken.patch(
      `${baseURL2}api/campaigns/${campaignId}/start`
    );
    return response.data;
  } catch (error) {
    console.error("Error saving campaign:", error);
    throw error;
  }
};

export const updateCollectionDetails = async (
  data: updateCollectionDetailsRequest,
  collectionId: string
) => {
  try {
    const reponse = await axiosWithToken.put(
      `${baseURL2}api/collections/${collectionId}`,
      data
    );
    return reponse.data;
  } catch (error) {
    console.error("Error saving campaign:", error);
    throw error;
  }
};

export const getIndividualCampignDetails = async (campaignId: string) => {
  try {
    const reponse = await axiosWithToken.get(
      `${baseURL2}api/campaigns/${campaignId}`
    );
    return reponse.data;
  } catch (error) {
    console.error("Error saving campaign:", error);
    throw error;
  }
};

export const getAccountDetails = async () => {
  try {
    const response = await axiosWithToken.get(
      `${baseURL2}api/auth/account-dashboard-details`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting account details:", error);
    throw error;
  }
};

export const getCampaignRecipientsDetails = async (campaignId: string) => {
  try {
    const reponse = await axiosWithToken.get(
      `${baseURL2}api/campaigns/${campaignId}/recipients`
    );
    return reponse.data;
  } catch (error) {
    console.error("Error saving campaign:", error);
    throw error;
  }
};
