import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCollectionIds,
  toggleCollectionId,
  selectedCollection,
  setSelectAllClickedForUncheck,
} from "../../campaignSlice";
import { selectCollections } from "components/collection/collectionSlice";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { selectCurrentSubscription } from "components/subscription/subscriptionSlice";

export const useSelectCollection = () => {
  const dispatch = useDispatch();
  const collectionData = useSelector(selectCollections);
  const selectedCollectionIds = useSelector(selectedCollection);
  const subscriptionDetails = useSelector(selectCurrentSubscription);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "date", desc: true },
  ]);

  const emailOutreachQuota =
    subscriptionDetails.userFeatureQuotas.find(
      (feature: any) => feature.featureName === "Email Outreach"
    )?.remainingQuota || 0;

  const totalEmailQuota =
    subscriptionDetails.userFeatureQuotas.find(
      (feature: any) => feature.featureName === "Email Outreach"
    )?.totalQuota || 0;

  // Handle collection data and syncing selected collection ids
  // useEffect(() => {
  //   if (collectionData?.length > 0) {
  //     if (selectedCollectionIds?.length === 0 && !selectAllForUncheck) {
  //       const allIds = collectionData.map((item: any) => item.id);
  //       dispatch(setSelectedCollectionIds(allIds));
  //       setIsAllSelected(true);
  //     } else {
  //       const validSelectedIds = selectedCollectionIds?.filter((id) =>
  //         collectionData.some((item) => item.id === id)
  //       );
  //       if (validSelectedIds?.length !== selectedCollectionIds?.length) {
  //         dispatch(setSelectedCollectionIds(validSelectedIds));
  //       }
  //       setIsAllSelected(validSelectedIds?.length === collectionData?.length);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [collectionData, selectedCollectionIds, dispatch]);

  const filteredData = useMemo(() => {
    return collectionData.filter((collection) =>
      collection.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [collectionData, searchQuery]);

  const toggleRowSelection = (rowId: string) => {
    dispatch(toggleCollectionId(rowId));
    const updatedSelectedIds = selectedCollectionIds?.includes(rowId)
      ? selectedCollectionIds?.filter((id) => id !== rowId)
      : [...selectedCollectionIds, rowId];
    setIsAllSelected(updatedSelectedIds?.length === filteredData?.length);
  };

  const toggleSelectAll = (checked: boolean) => {
    const allIds = filteredData?.map((item: any) => item.id);
    if (checked) {
      dispatch(setSelectedCollectionIds(allIds));
    } else {
      dispatch(setSelectAllClickedForUncheck(true));
      dispatch(setSelectedCollectionIds([]));
    }
    setIsAllSelected(checked);
  };

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        header: "Collection name",
        accessorKey: "collection",
      },
      {
        header: "Created date",
        accessorKey: "date",
      },
      {
        header: "Companies with emails",
        accessorKey: "recipientsPerCompany",
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      filteredData.map((item) => ({
        id: item.id,
        collection: item.name,
        companyCount: item.totalCompanies,
        recipientsPerCompany: item.totalRecipients,
        date: item.createdAt !== null ? `Created on ${item.createdAt}` : "",
        isAlreadyInCampaign: item.isAddedToCampaign,
      })),
    [filteredData]
  );

  return {
    isAllSelected,
    selectedCollectionIds,
    emailOutreachQuota,
    searchQuery,
    columns,
    data,
    sorting,
    totalEmailQuota,
    toggleSelectAll,
    toggleRowSelection,
    setSorting,
    setSearchQuery,
  };
};
