import { DialogTitle } from "@radix-ui/react-dialog";
import SvgMinusCircularIcon from "components/common/iconComponents/minusCircularIcon";
import SvgPlusCircularIcon from "components/common/iconComponents/plusCircularIcon";
import SvgCloseIcon from "components/popUps/CloseIcon";
import { Button } from "components/shadcn/ui/button";
import { Dialog, DialogContent } from "components/shadcn/ui/dialog";
import React, { useState } from "react";

const EmailQuotaModal: React.FC<{ open: boolean; close: () => void; changePlan: () => void }> = ({
    open,
    close,
    changePlan,
}) => {
    const [amount, setAmount] = useState(50);
    const [emails, setEmails] = useState(500);

    const handleIncrement = () => {
        setAmount((prev) => prev + 50);
        setEmails((prev) => prev + 500);
    };

    const handleDecrement = () => {
        if (amount > 50) {
            setAmount((prev) => prev - 50);
            setEmails((prev) => prev - 500);
        }
    };

    return (
        <Dialog open={open} onOpenChange={close}>

            <DialogContent className="max-w-sm w-full p-6 rounded-[32px] bg-white shadow-lg">
                <button className="w-full flex justify-end" onClick={() => close()}>
                    <SvgCloseIcon />
                </button>
                <DialogTitle className="text-base sm:text-lg font-semibold text-gray-600">
                    Increase your email quota:
                </DialogTitle>

                <div className="flex mt-3 mb-3 items-center justify-between border rounded-lg h-[70px] sm:h-[90px]">
                    <button
                        onClick={handleDecrement}
                        className="text-pink-500 text-xl font-bold px-2 sm:px-3"
                        aria-label="Decrease amount"
                    >
                        <SvgMinusCircularIcon />
                    </button>
                    <div className="flex flex-col items-center gap-1">
                        <div className="text-2xl sm:text-[36px] font-semibold leading-tight sm:leading-[44px] tracking-[-0.01em] font-[Instrument Sans] text-gray-900">
                            ${amount}
                        </div>
                        <p className="text-center text-sm sm:text-lg text-gray-600">
                            +{emails} Emails
                        </p>
                    </div>
                    <button
                        onClick={handleIncrement}
                        className="text-pink-500 text-xl font-bold px-2 sm:px-3"
                        aria-label="Increase amount"
                    >
                        <SvgPlusCircularIcon />
                    </button>
                </div>

                <Button className="w-full text-white py-1.5 sm:py-2 rounded-[120px] bg-gradient-to-r from-[#870940] via-[#FF008A] to-[#FF5454]">
                    Top up
                </Button>

                <button className="w-full mt-2 text-lg font-semibold text-primary-900" onClick={changePlan}>
                    or Change your plan
                </button>
            </DialogContent>
        </Dialog>
    );
};

export default EmailQuotaModal;
