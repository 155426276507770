import axiosWithToken from "axiosWithToken";


const baseURL2 = process.env.REACT_APP_BASEURL2;

export const getNewPlans = async () => {
    try {
        const response = await axiosWithToken.get(
            `${baseURL2}api/products/plans`,
        );
        return response.data;
    } catch (error) {
        console.error("Error Fetching plans:", error);
        throw error;
    }
};

export const createCheckoutSession = async (productPricingId: string) => {
    try {
        const response = await axiosWithToken.post(
            `${baseURL2}api/products/checkout-session`, { productPricingId }
        );
        return response.data;
    } catch (error) {
        console.error("Error Fetching plans:", error);
        throw error;
    }
};

export const getCurrentSubscription = async () => {
    try {
        const response = await axiosWithToken.get(
            `${baseURL2}api/subscriptions`
        );
        return response.data;
    } catch (error) {
        console.error("Error Fetching plans:", error);
        throw error;
    }
};

export const upgradeSubscription = async (subscriptionId: string, productPricingId: string) => {
    try {
        const response = await axiosWithToken.put(
            `${baseURL2}api/subscriptions/${subscriptionId}/upgrade`, { productPricingId }
        );
        return response.data;
    } catch (error) {
        console.error("Error Fetching plans:", error);
        throw error;
    }
}

export const endTrialSubscription = async (subscriptionId: string) => {
    try {
        const response = await axiosWithToken.put(
            `${baseURL2}api/subscriptions/${subscriptionId}/end-trial-now`
        );
        return response.data;
    } catch (error) {
        console.error("Error Fetching plans:", error);
        throw error;
    }
}

export const cancelSubscription = async (subscriptionId: string) => {
    try {
        const response = await axiosWithToken.delete(
            `${baseURL2}api/subscriptions/${subscriptionId}/cancel`
        );
        return response.data;
    } catch (error) {
        console.error("Error Fetching plans:", error);
        throw error;
    }
}

export const getProductTopUp = async () => {
    try {
        const response = await axiosWithToken.get(
            `${baseURL2}api/products/top-ups`
        );
        return response.data;
    } catch (error) {
        console.error("Error Fetching plans:", error);
        throw error;
    }
}