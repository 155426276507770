import { SIC_CODES } from "components/Search/Advanced/FilterTypes/siccodes";
import { getSICTitle } from "components/Search/Basic/SearchBar";
import { getFinancialStrInThousand } from "helpers";

export const convertResponse = (list: any) => {
    let currentYear = new Date().getFullYear();
    function getCountryGB(country: string) {
        if (!country || ["England", "Scotland", "Wales", "Northern Ireland", "United Kingdom"].includes(country)) {
            return "GB";
        }
        return country;
    }

    let tableList: any = [];
    let companyList: any = [];
    let PSCTableList: any = [];
    let PSCList: any = [];
    let financialsList: any = [];
    let compNum = "";

    let pscsListMap = new Map();  // For deduplicating PSC records
    let contactMap = new Map();  // For deduplicating contacts
    let financialMap = new Map();  // For deduplicating financial records
    let latestFinancialMap = new Map();  // For tracking the latest financial year record for each company

    let coregList: any = []; // To track unique company numbers
    let index = 0;

    list.forEach((item: any) => {
        if (index < 5000 && item.companyNumber) {
            let turnOverStatus = "Reported";

            if (!coregList.includes(item.companyNumber)) {
                compNum = item.companyNumber;
                coregList.push(compNum);

                if (item.shareholderType === "none") {
                    item.shareholderType = "N/A";
                }
                if (item.revenue === null) {
                    let tradeDebtors = item.tradeDebtors;
                    if (!item.tradeDebtors) {
                        tradeDebtors = item.currentAssets * 0.75;
                        item.TradeDebtors = tradeDebtors;
                    }

                    let lowRange = Math.floor(tradeDebtors * 6 / 100000) * 100000;
                    let highRange = Math.floor(tradeDebtors * 10 / 100000) * 100000;

                    item.turnover = lowRange + " - " + highRange;
                    item.estimated = true;
                }

                if (item.revenue === null) {
                    var tradeDebtors = item.tradeDebtors
                    if (!item.tradeDebtors) {
                        tradeDebtors = item.currentAssets * .75
                        item.tradeDebtors = tradeDebtors
                    }

                    let lowRange = Math.floor(tradeDebtors * 6 / 100000) * 100000
                    let highRange = Math.floor(tradeDebtors * 10 / 100000) * 100000

                    item.turnover = lowRange + " - " + highRange
                    turnOverStatus = "Estimated"
                }
                else {
                    item.turnover = item.revenue;
                }
                // let emailStatus = item.EmailStatus;
                // if (emailStatus === "invalid" || emailStatus === "abuse") {
                //     item.email = "";
                // }
                // let emailV = item.email || "";
                // if (emailV === "") {
                //     emailStatus = "";
                // } else {
                //     if (emailStatus !== "valid") {
                //         emailStatus = "medium";
                //     } else {
                //         emailStatus = "high";
                //     }
                // }
                if (!item.netProfitPercentage && item.netProfit) {
                    item.netProfitPercentage = ((item.netProfit / item.turnover) * 100).toFixed(2);
                    if ((item.turnover + "").includes(" - ")) {
                        let split = item.turnover.split(" - ");
                        let middle = (+split[0] + +split[1]) / 2;
                        item.netProfitPercentage = ((item.netProfit / middle) * 100).toFixed(2);
                    }
                }

                if (item.netProfitPercentage === null) {
                    item.netProfitPercentage = "Not Available";
                }

                if (item.netProfitPercentage && parseFloat(item.netProfitPercentage) > 80) {
                    item.netProfitPercentage = null;
                    item.netProfit = null;
                    item.netProfitEstimate = false;
                }

                if (item.email === "undefined" || item.email === "null") {
                    item.email = "";
                }

                tableList.push({
                    name: item.companyName.replace("LIMITED", "LTD"),
                    number: item.companyNumber,
                    sic: item.SIC,
                    sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                    registeredAddress: item.registeredAddress,
                    tradingAddress: item.tradingAddress,
                    postTown: item.regAddressPostTown,
                    county: item.regAddressCounty,
                    country: item.regAddressCountry,
                    website: item.website,
                    incorporation: item.incorporationDate,
                    filingDate: item.filingDate || "",
                    year: item.financialYear || "",
                    type: item.companyType,
                    status: item.companyStatus,
                    amountPSCs: item.amountPSCs,
                    debtRatio: parseFloat((item.debtRatio / 100).toFixed(2)),
                    netProfitPercentage: item.netProfitPercentage === 'Not Available' ? null : parseFloat(Number(item.netProfitPercentage).toFixed(2)),
                    fanpRatio: item.ftaRevRatio,
                    turnover: item.turnover && !isNaN(Number(getFinancialStrInThousand(item.turnover))) ? Number(getFinancialStrInThousand(item.turnover)) : null,
                    turnoverStatus: turnOverStatus,
                    netProfit: item.netProfit ? Number(getFinancialStrInThousand(item.netProfit)) : item.netAssets ? Number(getFinancialStrInThousand(item.netAssets)) : null,
                    netProfitEstimate: item.netProfitEstimate ? "Estimated" : "Reported",
                    currentAssets: Number(item.currentAssets),
                    fixedAssets: item.fixedAssets ? Number(getFinancialStrInThousand(item.fixedAssets)) : null,
                    totalAssets: item.totalAssets ? Number(getFinancialStrInThousand(item.totalAssets)) : null,
                    currentLiabilities: item.currentLiabilities ? Number(getFinancialStrInThousand(item.currentLiabilities)) : null,
                    longTermLiabilities: item.longTermLiabilities ? Number(getFinancialStrInThousand(item.longTermLiabilities)) : null,
                    netAssets: item.netAssets ? Number(getFinancialStrInThousand(item.netAssets)) : null,
                    updated: item.companyUpdated,
                    emailCount: item?.emailCount,
                    //validEmailCount: 0,
                    //numEmployees: item.numEmployees,
                    premium: item.premium ? "Premium" : "Standard",
                });
            }

            if (!item.netProfitPercentage && item.netProfit) {
                item.netProfitPercentage = ((item.netProfit / item.turnover) * 100).toFixed(2);
                if ((item.turnover + "").includes(" - ")) {
                    let split = item.turnover.split(" - ");
                    let middle = (+split[0] + +split[1]) / 2;
                    item.netProfitPercentage = ((item.netProfit / middle) * 100).toFixed(2);
                }
            }

            if (item.netProfitPercentage === null) {
                item.netProfitPercentage = "Not Available";
            }

            if (item.netProfitPercentage && parseFloat(item.netProfitPercentage) > 80) {
                item.netProfitPercentage = null;
                item.netProfit = null;
                item.netProfitEstimate = false;
            }
            // Store unique financial records (distinct by companyNumber + financialYear)
            let financialKey = `${item.companyNumber}-${item.financialYear}`;
            if (!financialMap.has(financialKey)) {
                financialMap.set(financialKey, {
                    compNumber: item.companyNumber,
                    compName: item.companyName,
                    sic: item.SIC,
                    sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                    //registeredAddress: item.registeredAddress,
                    //tradingAddress: item.tradingAddress,
                    website: item.website,
                    incorporation: item.incorporationDate,
                    type: item.companyType,
                    status: item.companyStatus,
                    filingDate: item.filingDate,
                    year: item.financialYear,
                    debtRatio: parseFloat((item.debtRatio / 100).toFixed(2)),
                    //revenue: getFinancialStrInThousand(item.revenue),
                    netProfitPercentage: item.netProfitPercentage === 'Not Available' ? null : parseFloat(Number(item.netProfitPercentage).toFixed(2)),
                    fanpRatio: item.ftaRevRatio,
                    turnover: item.turnover && !isNaN(Number(getFinancialStrInThousand(item.turnover))) ? Number(getFinancialStrInThousand(item.turnover)) : null,
                    turnoverStatus: item.estimated ? "Estimated" : "Reported",
                    netProfit: item.netProfit ? Number(getFinancialStrInThousand(item.netProfit)) : item.netAssets ? Number(getFinancialStrInThousand(item.netAssets)) : null,
                    netProfitEstimate: item.netProfitEstimate ? "Estimated" : "Reported",
                    currentAssets: item.currentAssets ? Number(getFinancialStrInThousand(item.currentAssets)) : null,
                    fixedAssets: item.fixedAssets ? Number(getFinancialStrInThousand(item.fixedAssets)) : null,
                    totalAssets: item.totalAssets ? Number(getFinancialStrInThousand(item.totalAssets)) : null,
                    currentLiabilities: item.currentLiabilities ? Number(getFinancialStrInThousand(item.currentLiabilities)) : null,
                    longTermLiabilities: item.longTermLiabilities ? Number(getFinancialStrInThousand(item.longTermLiabilities)) : null,
                    netAssets: item.netAssets ? Number(getFinancialStrInThousand(item.netAssets)) : null
                });
            }

            // Track the latest financial year record for each company
            if (!latestFinancialMap.has(item.companyNumber) || new Date(item.financialYear) > new Date(latestFinancialMap.get(item.companyNumber).financialYear)) {
                latestFinancialMap.set(item.companyNumber, item);
            }

            index++;
        }


        // Store unique contacts (distinct by companyNumber + firstName + lastName)
        if (item.forename && item.surname) {
            if (item.Address_Line1 === "") {
                let split = item.Address.split(',');
                if (split.length > 1) {
                    item.Address_Line1 = split[0];
                }
            }

            // let emailStatus = item.EmailStatus;
            // if (emailStatus === "invalid" || emailStatus === "abuse") {
            //     item.email = "";
            // }
            // let emailV = item.email || "";
            // if (emailV === "") {
            //     emailStatus = "";
            // } else {
            //     if (emailStatus !== "valid") {
            //         emailStatus = "medium";
            //     } else {
            //         emailStatus = "high";
            //     }
            // }
            if (!item.netProfitPercentage && item.netProfit) {
                item.netProfitPercentage = ((item.netProfit / item.turnover) * 100).toFixed(2);
                if ((item.turnover + "").includes(" - ")) {
                    let split = item.turnover.split(" - ");
                    let middle = (+split[0] + +split[1]) / 2;
                    item.netProfitPercentage = ((item.netProfit / middle) * 100).toFixed(2);
                }
            }

            if (item.netProfitPercentage === null) {
                item.netProfitPercentage = "Not Available";
            }

            if (item.netProfitPercentage && parseFloat(item.netProfitPercentage) > 80) {
                item.netProfitPercentage = null;
                item.netProfit = null;
                item.netProfitEstimate = false;
            }

            // if (item.email !== "" && item.email !== undefined && item.email !== "undefined" && item.email !== "null") {
            //     let index = tableList.findIndex((x: any) => x.number === item.companyNumber);
            //     tableList[index].emailCount = tableList[index].emailCount + 1;
            //     companyList[index].emailCount = companyList[index]?.emailCount + 1;

            //     if (emailStatus !== "medium") {
            //         tableList[index].validEmailCount = tableList[index].validEmailCount + 1;
            //         companyList[index].validEmailCount = companyList[index].validEmailCount + 1;
            //     }
            // }
            let contactKey = `${item.companyNumber}-${item.forename}-${item.surname}`;
            if (!contactMap.has(contactKey)) {
                contactMap.set(contactKey, {
                    name: item.companyName,
                    number: item.companyNumber,
                    sic: item.SIC,
                    sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                    title: item.title,
                    firstName: item.forename,
                    surname: item.surname,

                    email: item.email || "",
                    emailStatus: item.email && item.emailStatus === "valid" ? "high" : !item.email ? "" : "medium",
                    linkedIn: item.linkedIn,
                    shareholderType: item.shareholderType,
                    shareholderControl: item.shareholderPercentage,
                    age: currentYear - item.yearDOB,
                });
            }

            let pscKey = `${item.companyNumber}-${item.forename}-${item.surname}`;
            if (!pscsListMap.has(pscKey)) {
                pscsListMap.set(pscKey, {
                    name: item.companyName,
                    number: item.companyNumber,
                    sic: item.SIC,
                    sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
                    title: item.title,
                    firstName: item.forename,
                    surname: item.surname,
                    addressType: "Registered",
                    addressLine1: item.registeredAddressLine1,
                    addressLine2: item.registeredAddressLine2,
                    town: item.registeredPostTown,
                    county: item.registeredCounty,
                    postCode: item.registeredPostCode,
                    country: getCountryGB(item.registeredCountry),
                    shareholderType: item.shareholderType,
                    shareholderControl: item.shareholderPercentage,
                    age: currentYear - item.yearDOB,
                    email: item.email,
                    emailStatus: item.email && item.emailStatus === "valid" ? "high" : !item.email ? "" : "medium",
                });
            }
        }

    });


    // Convert maps back to arrays
    financialsList = Array.from(financialMap.values());
    let contactList = Array.from(contactMap.values());
    PSCList = Array.from(pscsListMap.values());
    // Push only the latest financial year record into companyList
    latestFinancialMap.forEach((item: any) => {
        companyList.push({
            name: item.companyName,
            number: item.companyNumber,
            sic: item.SIC,
            sicName: getSICTitle(item.SIC, SIC_CODES)?.title,
            registeredAddress: item.registeredAddress,
            tradingAddress: item.tradingAddress,
            website: item.website,
            incorporation: item.incorporationDate,
            type: item.companyType,
            status: item.companyStatus,
            amountPSCs: item.amountPSCs,
            currency: "£",
            //revenue: getFinancialStrInThousand(item.revenue),
            debtRatio: Number((item.debtRatio / 100).toFixed(2)),
            netProfitPercentage: item.netProfitPercentage === 'Not Available' ? null : parseFloat(Number(item.netProfitPercentage).toFixed(2)),
            fanpRatio: item.ftaRevRatio,
            turnover: item.turnover && !isNaN(Number(getFinancialStrInThousand(item.turnover))) ? Number(getFinancialStrInThousand(item.turnover)) : null,
            turnoverStatus: item.estimated ? "Estimated" : "Reported",
            netProfit: item.netProfit ? Number(getFinancialStrInThousand(item.netProfit)) : item.netAssets ? Number(getFinancialStrInThousand(item.netAssets)) : null,
            netProfitEstimate: item.netProfitEstimate ? "Estimated" : "Reported",
            currentAssets: item.currentAssets ? Number(getFinancialStrInThousand(item.currentAssets)) : null,
            fixedAssets: item.fixedAssets ? Number(getFinancialStrInThousand(item.fixedAssets)) : null,
            totalAssets: item.totalAssets ? Number(getFinancialStrInThousand(item.totalAssets)) : null,
            currentLiabilities: item.currentLiabilities ? Number(getFinancialStrInThousand(item.currentLiabilities)) : null,
            longTermLiabilities: item.longTermLiabilities ? Number(getFinancialStrInThousand(item.longTermLiabilities)) : null,
            netAssets: item.netAssets ? Number(getFinancialStrInThousand(item.netAssets)) : null,
            //emailCount: 0,
            //validEmailCount: 0,
            //numEmployees: item.numEmployees,
            premium: item.premium ? "Premium" : "Standard",
        });
    });

    tableList = tableList.sort((a: any, b: any) => b.numEmployees - a.numEmployees);
    companyList = companyList.sort((a: any, b: any) => b.numEmployees - a.numEmployees);

    return {
        tableList,
        companyList,
        PSCTableList,
        PSCList,
        contactList,
        financialsList,
    };
};