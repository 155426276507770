import type React from "react";
import { useState } from "react";
import { Button } from "components/shadcn/ui/button";
import { Card, CardFooter, CardHeader } from "components/shadcn/ui/card";
import { CheckIcon } from "lucide-react";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "components/shadcn/ui/dialog";
import SvgCloseIcon from "components/popUps/CloseIcon";
import { Tabs, TabsList, TabsTrigger } from "components/shadcn/ui/tabs";
import clsx from "clsx";
import IndividualQuotaTopUp from "./individualQuotaTopUp";
import { useSelector } from "react-redux";
import {
    selectCurrentSubscription,
    selectProductPlans,
} from "./subscriptionSlice";
import {
    SUBSCRIPTION_STATUS,
    PRICING_INTERVAL,
    CurrentSubscriptionDetails,
} from "./types";
import lang from "lang";
import {
    calculateDaysRemaining,
    formatService,
    getCardWidthClass,
} from "helpers";
import { Badge } from "components/shadcn/ui/badge";

const UpgradeQuotaModal: React.FC<{
    open: boolean;
    close: () => void;
    handleEndTrial?: (subscriptionId: string) => void;
    handleCancelSubscription?: (subscriptionId: string) => void;
    handleUpgradeSubscription?: (
        currentSubscription: CurrentSubscriptionDetails,
        productPricingId: string
    ) => void;
}> = ({ open, close, handleEndTrial, handleUpgradeSubscription, handleCancelSubscription }) => {

    const { payment: paymentCopy } = lang;
    const subscriptionDetails = useSelector(selectCurrentSubscription);
    const productPlans = useSelector(selectProductPlans);

    const [activeTab, setActiveTab] = useState<"monthly" | "yearly">("monthly");

    const plans = Array.isArray(productPlans)
        ? productPlans.filter(
            (plan) =>
                plan.pricingInterval ===
                (activeTab === "monthly"
                    ? PRICING_INTERVAL.MONTH
                    : PRICING_INTERVAL.YEAR)
        )
        : [];
    const extendedPlans = plans.map((plan) => ({
        ...plan,
        isCurrentPlan:
            plan.productPricingId === subscriptionDetails.productPricing.id,
    }));


    return (
        <Dialog open={open} onOpenChange={close}>
            <DialogContent className="w-[95vw] max-w-[75%] h-auto max-h-[90vh] p-4 sm:p-6 overflow-auto">
                <DialogHeader>
                    <button className="flex flex-row justify-end" onClick={close}>
                        <SvgCloseIcon />
                    </button>
                </DialogHeader>

                <div className="flex flex-col lg:flex-row w-full gap-6">

                    {subscriptionDetails.status !== SUBSCRIPTION_STATUS.TRAILING && (
                        <div className="w-full lg:w-[35%] flex flex-col gap-4 sm:gap-6">
                            <div className="text-base sm:text-lg font-medium font-[Instrument Sans]">
                                {paymentCopy.increaseQuotaHeading}
                            </div>
                            <div className="mx-auto w-full max-w-sm">
                                <IndividualQuotaTopUp />
                            </div>
                        </div>
                    )}

                    {subscriptionDetails.status !== SUBSCRIPTION_STATUS.TRAILING && (
                        <div className="h-[1px] lg:h-full w-full lg:w-[1px] bg-[#D7D7D7] my-4 lg:my-0" />
                    )}
                    <div
                        className={`w-full ${subscriptionDetails.status !== SUBSCRIPTION_STATUS.TRAILING
                            ? "lg:w-[60%]"
                            : "lg:w-full"
                            } flex flex-col gap-4 sm:gap-5`}
                    >
                        <DialogTitle className="text-center text-lg sm:text-xl flex flex-col gap-4 sm:gap-6 items-center justify-center">
                            {subscriptionDetails.status !== SUBSCRIPTION_STATUS.TRAILING
                                ? "or change your plan:"
                                : "change your plan:"}
                        </DialogTitle>
                        <Tabs
                            value={activeTab}
                            onValueChange={(value) =>
                                setActiveTab(value as "monthly" | "yearly")
                            }
                            className="w-full flex flex-row justify-center"
                        >
                            <TabsList className="flex flex-col sm:flex-row justify-center w-full sm:w-[fit-content] space-y-2 sm:space-y-0 sm:space-x-4 p-2 bg-[#F9FAFB] rounded-lg">
                                <TabsTrigger
                                    value="monthly"
                                    className={clsx(
                                        "text-sm sm:text-base px-2 py-1.5 sm:py-2",
                                        activeTab === "monthly"
                                            ? "bg-white text-primary-700"
                                            : "text-gray-500"
                                    )}
                                >
                                    {paymentCopy.monthlyBilling}
                                </TabsTrigger>
                                <TabsTrigger
                                    value="yearly"
                                    className={clsx(
                                        "text-sm sm:text-base px-2 py-1.5 sm:py-2",
                                        activeTab === "yearly"
                                            ? "bg-white text-primary-700"
                                            : "text-gray-500"
                                    )}
                                >
                                    {paymentCopy.annualBilling}
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                        <div className="flex flex-wrap justify-center gap-3 sm:gap-4 mt-3 sm:mt-4 overflow-y-auto max-h-[60vh] sm:max-h-[70vh] md:max-h-none pb-1 sm:pb-2 -mx-2 px-2">
                            {extendedPlans.map((plan) => (
                                <Card
                                    key={plan.id}
                                    className={clsx(
                                        "rounded-xl border border-gray-300 bg-gray-50 overflow-hidden p-3 sm:p-4 flex flex-col",
                                        getCardWidthClass(plans.length),
                                        plans.length === 1 ? "mx-auto" : ""
                                    )}
                                >
                                    <CardHeader className="flex flex-col gap-2 flex-grow p-0 sm:p-2">
                                        <div className="text-base sm:text-lg font-semibold text-gray-600 flex flex-row gap-2">
                                            {plan.name}
                                            {plan.isCurrentPlan && (
                                                <Badge className="rounded-full bg-pink-50 px-3 py-1 text-sm font-medium text-primary-700">
                                                    {paymentCopy.currentPlan}
                                                </Badge>
                                            )}
                                        </div>
                                        <div className="flex items-baseline">
                                            <span className="text-2xl sm:text-3xl font-semibold text-gray-900">
                                                ${plan.price.toLocaleString()}
                                            </span>
                                            <span className="text-sm text-gray-500 ml-1">
                                                {plan.pricingInterval === PRICING_INTERVAL.MONTH
                                                    ? "/m"
                                                    : "/y"}
                                            </span>
                                        </div>
                                        <div>
                                            {plan.features
                                                .filter(
                                                    (feature: any) => feature.canHighlight === "true"
                                                )
                                                .map((feature, idx) => (
                                                    <div
                                                        key={idx}
                                                        className="flex justify-between text-sm sm:text-base"
                                                    >
                                                        <span className="text-gray-600">
                                                            {feature.name}
                                                        </span>
                                                        <span className="font-semibold text-gray-600">
                                                            {feature?.capacity?.toLocaleString()}
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </CardHeader>

                                    <div className="flex flex-col items-center justify-center mt-3 sm:mt-4 gap-4">
                                        {plan.isCurrentPlan &&
                                            subscriptionDetails.status ===
                                            SUBSCRIPTION_STATUS.TRAILING ? (
                                            <Button variant="outline">
                                                <span className="text-sm font-semibold hover:cursor-default">
                                                    {paymentCopy.trialEndsIn}{" "}
                                                    {calculateDaysRemaining(
                                                        subscriptionDetails?.trialEnd
                                                    )}{" "}
                                                    {paymentCopy.days} -
                                                </span>{" "}
                                                <span className="text-primary-700" onClick={() => handleEndTrial && handleEndTrial(subscriptionDetails.id)}>
                                                    {paymentCopy.endTrialNow}
                                                </span>
                                            </Button>
                                        ) : (
                                            plan.isCurrentPlan ?
                                                <Button variant="outline">
                                                    <span className="text-sm font-semibold">
                                                        {paymentCopy.yourCurrentPlan}
                                                    </span>
                                                </Button>
                                                :
                                                <Button
                                                    variant="custom"
                                                    className="w-full sm:w-[80%] text-white bg-gradient-to-r from-[#870940] via-[#FF008A] to-[#FF5454]"
                                                    onClick={
                                                        !plan.isCurrentPlan && handleUpgradeSubscription
                                                            ? () =>
                                                                handleUpgradeSubscription(
                                                                    subscriptionDetails,
                                                                    plan.productPricingId
                                                                )
                                                            : undefined
                                                    }
                                                >
                                                    <span className="text-white font-semibold text-sm">

                                                        {paymentCopy.upgrade}
                                                    </span>
                                                </Button>
                                        )}
                                        {plan.isCurrentPlan && (
                                            <button onClick={() => handleCancelSubscription && handleCancelSubscription(subscriptionDetails.id)}>
                                                <span className="text-md font-semibold text-primary-700">
                                                    {paymentCopy.cancelPlan}
                                                </span>
                                            </button>
                                        )}
                                    </div>

                                    <CardFooter className="p-0 sm:p-2 pt-4 sm:pt-6 flex-grow">
                                        <div className="space-y-2 sm:space-y-3 w-full">
                                            <h4 className="text-xs sm:text-sm font-semibold uppercase text-gray-500">
                                                {paymentCopy.services}
                                            </h4>
                                            <ul className="space-y-1 sm:space-y-2">
                                                {plan.features
                                                    .filter(
                                                        (feature: any) => feature.canHighlight !== "true"
                                                    )
                                                    .map((feature, idx) => (
                                                        <li key={idx} className="flex items-center ">
                                                            <CheckIcon className="h-4 w-4 text-pink-500 mr-2 mt-0.5 flex-shrink-0" />
                                                            <span className="text-sm sm:text-base">
                                                                {formatService(feature.name, feature.capacity)}
                                                            </span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </CardFooter>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UpgradeQuotaModal;
