import { SVGProps } from 'react';
const SvgWarmingUpIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2797_6621)">
            <path d="M6.66699 1.625V2.875M6.66699 9.5V11.5M3.54199 6.5H1.79199M11.292 6.5H10.542M9.89555 9.72855L9.54199 9.375M9.9991 3.20789L9.29199 3.915M3.12778 10.0392L4.54199 8.625M3.23133 3.10434L4.29199 4.165" stroke="#C11574" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_2797_6621">
                <rect width="12" height="12" fill="white" transform="translate(0.666992 0.5)" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgWarmingUpIcon;